import apiService from '../services/api-service';
import constants from '../constants/other';

const specialtiesService = {
  getSpecialtiesWithJobsAvailableNow: getSpecialtiesWithJobsAvailableNow,
  getAllSpecialties: getAllSpecialties,
  formatForSelectElement: formatForSelectElement,
  getBullhornSpecialtyNames: getBullhornSpecialtyNames,
};

export function getSpecialtiesWithJobsAvailableNow() {
  return apiService.get(`/api/specialties`, {
    hasOpenJobs: true,
  });
}

export async function getAllSpecialties(professionId) {
  let params = {};
  if (professionId) {
    params = { ...params, ...{ professionId: professionId } };
  }
  return apiService.get(`/api/specialties`, params);
}

export function formatForSelectElement(specialties, addAllOption = true) {
  let formattedSpecialties = specialties.map(specialty => {
    return {
      name: specialty.name,
      value: specialty.id,
    };
  });

  if (addAllOption) {
    addAllSpecialtiesOption(formattedSpecialties);
  }

  return formattedSpecialties;
}

export function getBullhornSpecialtyNames() {
  return apiService.get('/api/bullhorn-specialties/names');
}

function addAllSpecialtiesOption(formattedSpecialties) {
  formattedSpecialties.unshift({
    name: 'All specialties',
    value: constants.DEFAULT_SELECT_VALUE,
  });

  return formattedSpecialties;
}

export default specialtiesService;
