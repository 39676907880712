import { getProfileApiUrl, getSuggestionsApiUrl } from '../utils/url-helpers';
import { useAuthenticatedRequest } from '../hooks/api-hooks';

const SECTION_URL_SLUG = 'certifications-and-licenses';

export function useCertsAndLicensesApi() {
  const { get, post, put } = useAuthenticatedRequest();

  return {
    getCertsAndLicenses: function (profileId, isViewingSuggestions) {
      const requestUrl = isViewingSuggestions
        ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
        : getProfileApiUrl(profileId, SECTION_URL_SLUG);

      return get(requestUrl);
    },
    updateCertsAndLicenses: function (profileId, data, isSuggestion) {
      const requestFunction = isSuggestion ? post : put;

      return requestFunction(
        isSuggestion
          ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
          : getProfileApiUrl(profileId, SECTION_URL_SLUG),
        data
      );
    },
  };
}
