import React, { useEffect, useState } from 'react';

import Layout from '../../../../components/layout';
import BackgroundCheckCopyRequestCheckbox from './background-check-request/background-check-request-checkbox';
import Checkbox from '../../../common/components/forms/formik-inputs/checkbox';
import ProfileForm from '../../../common/components/profile-form';
import Textarea from '../../../common/components/forms/formik-inputs/textarea';
import { useAuth } from '../../../../providers/auth-provider';
import { useAgreementsApi } from '../../../../services/agreements-service';
import validationSchema from './validation-schema';
import styles from './index.module.scss';
import sharedProfileStyles from '../../../common/components/profile-form/index.module.scss';
import Input from '../../../common/components/forms/formik-inputs/input';
import { useGaHelper } from '../../../../services/google-analytics-service';
import { profileSectionsInfo } from '../../../../constants/profile-sections-info';
import { legalAgreementNames } from '../../../../constants/legal-agreement-names';
import Select from '../../../common/components/forms/formik-inputs/select';

/* eslint-disable react-hooks/exhaustive-deps */

const Agreements = () => {
  const { getProfileId } = useAuth();
  const { getAgreements, updateAgreementsAndBackgroundCheckCopyRequest } =
    useAgreementsApi();

  const CURRENT_PAGE_PATH = '/profile/agreements/';
  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [agreementsData, setAgreementsData] = useState({
    agreements: [],
    eeos: [],
    hasRequestedBackgroundCheckCopy: null,
  });
  const { triggerProfileProgressTrackingEvent } = useGaHelper();

  useEffect(() => {
    getAgreements(getProfileId())
      .then(({ agreements, eeos }) => {
        setAgreementsIfAny(agreements, agreementsData, eeos);
        setHasFetchedUserData(true);
      })
      .catch(() => {
        setHasFetchError(true);
      });
  }, []);

  const setAgreementsIfAny = (agreements, agreementsData, eeos) => {
    let newAgreementsData = { agreements, eeos };

    if (agreementsData?.hasRequestedBackgroundCheckCopy !== null)
      newAgreementsData.hasRequestedBackgroundCheckCopy =
        agreementsData.hasRequestedBackgroundCheckCopy;

    if (agreements.length) setAgreementsData(newAgreementsData);
  };

  const agreementsRequiringResigning = agreementsData.agreements.filter(
    agr => agr.requiresResigning
  );

  return (
    <Layout>
      <ProfileForm
        initialValues={agreementsData}
        IntroComponent={() => (
          <>
            <h1
              className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
            >
              Legal Agreements
            </h1>
            <p className={`subtitle is-6 has-text-centered-mobile`}>
              Please review each of the statements below. You must complete this
              page to move forward with your job application.
            </p>
          </>
        )}
        currentPagePath={CURRENT_PAGE_PATH}
        defaultNextSection={'/profile/done/'}
        backButtonPath="/profile/personal-info/"
        validationSchema={validationSchema}
        hasFetchError={hasFetchError}
        hasFetchedUserData={hasFetchedUserData}
        formLevelErrorMessage={errors => Object.keys(errors).length > 0}
        profileId={getProfileId()}
        shouldShowCapsLockWarning={true}
        onSubmit={values => {
          triggerProfileProgressTrackingEvent(
            profileSectionsInfo.legalAgreements.name
          );

          return updateAgreementsAndBackgroundCheckCopyRequest(
            getProfileId(),
            values
          );
        }}
      >
        {({ values }) => (
          <div className={`${styles.agreements}`}>
            <hr className={sharedProfileStyles.formDivider} />
            {agreementsRequiringResigning.length > 0 &&
              (agreementsRequiringResigning.length > 1 ? (
                <div className="notification is-danger">
                  The following agreements have been updated:
                  <ul className={styles.resignMessage}>
                    {agreementsRequiringResigning.map(agr => (
                      <li className={styles.listItem}>{agr.name}</li>
                    ))}
                  </ul>
                  Please sign the updated versions.
                </div>
              ) : (
                <div className="notification is-danger">
                  The {agreementsRequiringResigning[0]?.name} agreement has been
                  updated. Please sign the updated version.
                </div>
              ))}
            {values.agreements.map(({ name, text }, index) => (
              <div className={`${styles.agreement}`} key={index}>
                <div className={`field `}>
                  <Textarea
                    name={`agreements[${index}].text`}
                    labelText={name}
                    labelClass={`${sharedProfileStyles.sectionTitle} title is-4 has-text-info has-text-centered-mobile`}
                    readOnly
                    rows={6}
                  />
                </div>
                <div className={`field`}>
                  <Checkbox
                    name={`agreements[${index}].isSigned`}
                    labelText={`I have read and agree`}
                    labelClass={`has-text-weight-bold`}
                  />
                </div>
                <div className={`field`}>
                  {name === legalAgreementNames.DISCLOSURE_AND_RELEASE && (
                    <BackgroundCheckCopyRequestCheckbox
                      profileId={getProfileId()}
                      agreementsData={agreementsData}
                      setAgreementsIfAny={setAgreementsIfAny}
                    />
                  )}
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`agreements[${index}].signedName`}
                    labelText={`Your full name`}
                    labelClass={`is-size-7`}
                    disabled={values.agreements[index].signedTime !== ''}
                    shouldCheckForCapsLock={true}
                  />
                </div>
              </div>
            ))}
            <h2 className={`title is-4 has-text-info has-text-weight-bold`}>
              {legalAgreementNames.VOLUNTARY_DISCLOSURES}
            </h2>

            <textarea
              className={`textarea ${styles.agreement}`}
              name="eeo-text"
              rows={6}
              readOnly
              value="Trustaff is an equal opportunity employer and is strongly committed to a policy of
              non-discrimination. Additionally, we are committed to recruiting a diverse and
              inclusive talent pool. All forms of illegal discriminatory actions against applicants
              for employment and employees are prohibited. In order to comply with certain federal
              regulations, Trustaff invites applicants and employees to voluntarily respond to the
              following questionnaire. This information is confidential, will not be included in
              personnel files, or disclosed to individuals making employment decisions, and will
              only be used in accordance with the provisions of applicable laws, executive orders
              and regulations, including those that require the information to be summarized and
              reported to the federal government for civil rights enforcement purposes. When
              reported, data will not identify any specific individual. Refusal to provide this
              self-identification information will not subject you to any adverse treatment."
            ></textarea>

            {Array.isArray(values.eeos) &&
              values.eeos.map(({ name, text, options }, index) => (
                <div className={`field ${styles.selectField}`}>
                  <Select
                    style={{ width: '330px' }}
                    labelClass="is-size-7"
                    options={options}
                    placeholder="Select one"
                    onChange={e => (values.eeos[index].value = e.target.value)}
                    name={name}
                    labelText={text}
                    id={name}
                    value={values.eeos[index].value}
                  />
                </div>
              ))}
          </div>
        )}
      </ProfileForm>
    </Layout>
  );
};

export default Agreements;
