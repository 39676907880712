export const legalAgreementEeoOptions = {
  DISABILITY: [
    { value: 'Y', name: 'Disability' },
    { value: 'N', name: 'No Disability' },
    { value: 'D', name: 'I do not wish to self-identify' }
  ],
  GENDER: [
    { value: 'F', name: 'Female' },
    { value: 'M', name: 'Male' },
    { value: 'U', name: 'I choose not to self identify' }
  ],
  RACE: [
    { value: 'A', name: 'Asian' },
    { value: 'B', name: 'Black or African American' },
    { value: 'C', name: 'Caucasian' },
    { value: 'H', name: 'Hispanic or Latino' },
    { value: 'I', name: 'American Indian or Alaska Native' },
    { value: 'P', name: 'Native Hawaiian or Other Pacific Islander' },
    { value: 'W', name: 'White / Caucasian' },
    { value: 'X', name: 'Some Other Race' },
    { value: 'T', name: 'Two or More Races' },
    { value: 'D', name: 'Declined to State' }
  ],
  VETERAN: [
    { value: 'Y', name: 'Veteran' },
    { value: 'N', name: 'Non-Veteran' },
    { value: 'P', name: 'Protected Veteran' },
    { value: 'D', name: 'Declined to Answer' }
  ]
};
