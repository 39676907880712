import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { profileSectionsInfo } from '../../../../constants/profile-sections-info';
import { useSuggestionsApi } from '../../../../services/suggestions-service';
import styles from './index.module.scss';

const ProfileFormSuggestionsButtons = ({
  currentPagePath,
  profileId,
  setStatus,
}) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { acceptSuggestions, rejectSuggestions } = useSuggestionsApi();

  const getSectionIdFromPath = () => {
    const path = currentPagePath.match(/\/profile\/(.*)\//)[1];
    const currentSection = Object.values(profileSectionsInfo).find(
      section => section.path === path
    );
    return currentSection.id;
  };

  const handleResponseSuccess = () => {
    navigate('/profile');
  };

  const handleAcceptOrReject = (profileId, submitFunc) => {
    setStatus({ apiError: false });
    setIsSubmitting(true);
    submitFunc(profileId, getSectionIdFromPath())
      .then(handleResponseSuccess)
      .catch(() => setStatus({ apiError: true }))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <button
        type="button"
        className={`button is-success ${styles.navButton} ${
          styles.acceptChangesButton
        } ${isSubmitting && isAccepting && 'is-loading'}`}
        disabled={isSubmitting}
        onClick={() => {
          setIsAccepting(true);
          handleAcceptOrReject(profileId, acceptSuggestions);
        }}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <span>Accept changes</span>
      </button>
      <button
        type="button"
        className={`button is-danger ${styles.navButton} ${
          isSubmitting && !isAccepting && 'is-loading'
        }`}
        disabled={isSubmitting}
        onClick={() => {
          setIsAccepting(false);
          handleAcceptOrReject(profileId, rejectSuggestions);
        }}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <span>Reject changes</span>
      </button>
    </>
  );
};

ProfileFormSuggestionsButtons.propTypes = {
  currentPagePath: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default ProfileFormSuggestionsButtons;
