import React, { useEffect, useState } from 'react';

import CertsFieldArray from './certs-field-array';
import LicensesFieldArray from './licenses-field-array';
import ProfileForm from '../profile-form';
import { useAppContext } from '../../../../providers/app-provider';
import { useCertsAndLicensesApi } from '../../../../services/certifications-and-licenses-service';
import validationSchema from './validation-schema';
import sharedProfileStyles from '../profile-form/index.module.scss';

const LicensesAndCerts = ({
  profileId,
  isSuggestion,
  isViewingSuggestions,
}) => {
  const { professionId } = useAppContext();
  const {
    getCertsAndLicenses,
    updateCertsAndLicenses,
  } = useCertsAndLicensesApi();

  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);
  const CURRENT_PAGE_PATH = '/profile/licenses-and-certs/';

  const [licensesAndCerts, setLicensesAndCerts] = useState({
    licenses: [],
    certs: [],
  });

  useEffect(() => {
    getCertsAndLicenses(profileId, isViewingSuggestions)
      .then(({ data }) => {
        if (data.certs.length || data.licenses.length) {
          setLicensesAndCerts(data);
        }
        setHasFetchedUserData(true);
      })
      .catch(() => {
        setHasFetchError(true);
      });
  }, []);

  return (
    <ProfileForm
      initialValues={licensesAndCerts}
      IntroComponent={() => (
        <>
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            My licenses & certifications
          </h1>
          <p className={`subtitle is-6 has-text-centered-mobile`}>
            Enter any professional licenses and certifications you currently
            hold.
          </p>
        </>
      )}
      validationSchema={validationSchema(professionId)}
      onSubmit={values => {
        return updateCertsAndLicenses(profileId, values, isSuggestion);
      }}
      currentPagePath={CURRENT_PAGE_PATH}
      defaultNextSection="/profile/skills-checklists/"
      backButtonPath="/profile/references/"
      hasFetchError={hasFetchError}
      hasFetchedUserData={hasFetchedUserData}
      formLevelErrorMessage={errors => errors.licenses || errors.certs}
      isSuggestion={isSuggestion}
      isViewingSuggestions={isViewingSuggestions}
      profileId={profileId}
      shouldShowCapsLockWarning={false}
    >
      {({ values }) => (
        <>
          <LicensesFieldArray values={values.licenses} />
          <CertsFieldArray values={values.certs} />
          <hr className={sharedProfileStyles.formDivider} />
        </>
      )}
    </ProfileForm>
  );
};

export default LicensesAndCerts;
