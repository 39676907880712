import React from 'react';

import { navigate } from '@reach/router';
import styles from './index.module.scss';

import Layout from '../../../../components/layout';
import CheckMark from '../check-mark';

const Done = () => {
  const DONE_BUTTON_PATH = '/profile';

  const goToDashboard = () => {
    navigate(DONE_BUTTON_PATH);
  };

  return (
    <>
      <Layout>
        <div
          className={`columns ${styles.centeredBox} ${styles.secondaryColorBackgroundOnMobile} ${styles.formSection}`}
        >
          <div
            className={`column is-6 is-10-mobile ${styles.columnWithPadding}`}
          >
            <div className={`${styles.centeredBox} ${styles.topMargin}`}>
              <CheckMark />
            </div>

            <h1 className={`title ${styles.title}`}>
              Your application is complete!
            </h1>

            <p className={`${styles.textBox}`}>
              Your profile can now be submitted to the jobs of your choice! Your
              recruiter will be in touch shortly!
            </p>

            <div
              className={`has-text-centered field ${styles.finishUpButtonWrapper}`}
            >
              <button
                className={`button is-primary`}
                onClick={() => goToDashboard()}
              >
                <span>Back to my profile</span>
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Done;
