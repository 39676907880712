import * as Yup from 'yup';

const VALID_EMAIL_MESSAGE = 'Please enter a valid email';

const validationSchema = Yup.object({
  newEmail: Yup.string()
    .required(VALID_EMAIL_MESSAGE)
    .email(VALID_EMAIL_MESSAGE),
  pwd: Yup.string().required('Please enter a password'),
});

export default validationSchema;
