import React from 'react';
import sharedProfileStyles from '../profile-form/index.module.scss';
import Select from '../forms/formik-inputs/select';
import { FormDatePickerInput } from '../forms/formik-inputs/date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './gap-entry.module.scss';

const GapEntry = ({ index, reasonOptions, isViewingSuggestions, remove }) => {
  return (
    <>
      <div className={`card ${styles.card}`}>
        <div className={`${styles.gapHeader}`}>
          <h4
            className={`has-text-info has-text-weight-bold ${styles.gapTitle}`}
          >
            Gap of Employment
          </h4>
          {!isViewingSuggestions && (
            <button
              type="button"
              className={`button is-danger is-inverted`}
              aria-label="Remove employment gap"
              onClick={() => remove(index)}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            </button>
          )}
        </div>

        <div className={styles.formContentSection}>
          <div className="field field-body">
            <div
              className={`field ${styles.reasonInput} ${sharedProfileStyles.groupedFormField}`}
            >
              <Select
                name={`workHistoryEntries[${index}].data.gapReasonId`}
                labelText="Reason"
                options={reasonOptions}
                placeholderText="Select a reason"
                labelClass={`is-size-7`}
                selectWrapperClass={`is-fullwidth `}
              />
            </div>
            <div className={`field ${sharedProfileStyles.groupedFormField}`}>
              <FormDatePickerInput
                name={`workHistoryEntries[${index}].data.startDate`}
                labelText="Start date"
                labelClass={`is-size-7`}
              />
            </div>

            <div className={`field ${sharedProfileStyles.groupedFormField} `}>
              <FormDatePickerInput
                name={`workHistoryEntries[${index}].data.endDate`}
                labelText="End date"
                labelClass={`is-size-7`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GapEntry;
