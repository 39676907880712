import React, { useState, useEffect } from 'react';
import {
  getAllSpecialties,
  formatForSelectElement,
} from '../../../../services/specialties-service';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import Input from '../forms/formik-inputs/input';
import Select from '../forms/formik-inputs/select';
import styles from './index.module.scss';
import sharedProfileStyles from '../profile-form/index.module.scss';

const SpecialtiesFieldArray = ({ values }) => {
  const [specialtySelectOptions, setSpecialtySelectOptions] = useState([]);
  const professionId = values.profession.id;

  const getSpecialtyLabelText = index => {
    if (index === 0) {
      return 'Your primary specialty';
    }

    return `Specialty #${index + 1}`;
  };

  useEffect(() => {
    if (professionId) {
      getAllSpecialties(professionId).then(({ data }) => {
        setSpecialtySelectOptions(formatForSelectElement(data, false));
      });
    }
  }, [professionId]);

  return (
    <FieldArray name="specialties">
      {({ push, remove }) => (
        <>
          {values.specialties.map((specialty, index) => (
            <div key={index}>
              <hr className={sharedProfileStyles.formDivider} />
              <div className={`${styles.specialtyInfo}`}>
                <div className="field">
                  <Select
                    name={`specialties[${index}].id`}
                    labelText={getSpecialtyLabelText(index)}
                    options={specialtySelectOptions}
                    placeholderText="Select a specialty"
                    labelClass={`is-size-7`}
                  />
                </div>
                <p
                  className={`has-text-weight-normal is-size-7 ${styles.experienceDescription}`}
                >
                  How long have you worked in this area?
                </p>
                <div className={`field ${styles.experienceFields}`}>
                  <div className={`${styles.yearsWrapper}`}>
                    <Input
                      name={`specialties[${index}].experienceInYears`}
                      labelText="Years"
                      placeholder="# of years"
                      labelClass={`is-size-7`}
                    />
                  </div>
                  <div>
                    <Input
                      name={`specialties[${index}].experienceInMonths`}
                      labelText="Months"
                      placeholder="# of months"
                      labelClass={`is-size-7`}
                    />
                  </div>
                </div>

                <div className={`${sharedProfileStyles.removeButtonContainer}`}>
                  <button
                    type="button"
                    className="button is-small is-danger is-outlined"
                    onClick={() => remove(index)}
                  >
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faMinus} />
                    </span>
                    <span>Remove specialty</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="field">
            <button
              type="button"
              className="button is-info is-small"
              onClick={() => {
                const addAsPrimary = values.specialties.length === 0;
                push({
                  id: '',
                  isPrimary: addAsPrimary,
                  experienceInYears: '',
                  experienceInMonths: '',
                });
              }}
            >
              <span className="icon is-small">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>Add a specialty</span>
            </button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default SpecialtiesFieldArray;
