import React, { useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { isMobile } from '../../../../../utils/browser-detection-helpers';
import styles from './date-picker.module.scss';

export const FormDatePickerInput = ({ labelClass, labelText, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [showMobileInput, setShowMobileInput] = useState(false);

  useEffect(() => {
    setShowMobileInput(isMobile());
  }, []);

  return (
    <>
      <label className={`label ${labelClass}`} htmlFor={field.name}>
        {labelText}
      </label>

      <div className={`control ${styles.control}`}>
        {showMobileInput ? (
          <input
            type="date"
            className={`input is-rounded`}
            {...field}
            {...props}
            value={field.value.replace(/T.+/, '')}
          />
        ) : (
          <DatePicker
            selected={(field.value && new Date(field.value)) || ''}
            onChange={val => {
              setFieldValue(field.name, val && val.toISOString());
            }}
            adjustDateOnChange
            className={`input is-rounded`}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        )}
      </div>

      {meta.error && meta.touched && (
        <p className="help has-text-danger">{meta.error}</p>
      )}
    </>
  );
};
