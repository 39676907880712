import React, { useEffect, useState } from 'react';

import DegreesFieldArray from './degrees-field-array';
import ProfileForm from '../profile-form';
import { useEducationApi } from '../../../../services/education-service';
import validationSchema from './validation-schema';
import sharedProfileStyles from '../profile-form/index.module.scss';
import { profileSectionsInfo } from '../../../../constants/profile-sections-info';
import { useGaHelper } from '../../../../services/google-analytics-service';

const Education = ({ profileId, isSuggestion, isViewingSuggestions }) => {
  const { getEducation, updateEducation } = useEducationApi();
  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);
  const { triggerProfileProgressTrackingEvent } = useGaHelper();
  const CURRENT_PAGE_PATH = '/profile/education/';

  const [education, setEducation] = useState({
    degrees: [
      {
        id: null,
        schoolName: '',
        schoolCity: '',
        schoolStateId: '',
        typeId: '',
        areaOfStudy: '',
        graduationMonth: '',
        graduationYear: '',
      },
    ],
  });

  useEffect(() => {
    getEducation(profileId, isViewingSuggestions)
      .then(data => {
        if (data.degrees.length) {
          setEducation(data);
        }
        setHasFetchedUserData(true);
      })
      .catch(() => setHasFetchError(true));
  }, []);

  return (
    <ProfileForm
      initialValues={education}
      shouldShowCapsLockWarning={true}
      IntroComponent={() => (
        <>
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            My education
          </h1>
          <p className={`subtitle is-6 has-text-centered-mobile`}>
            Tell us about your educational background.
          </p>
        </>
      )}
      onSubmit={values => {
        triggerProfileProgressTrackingEvent(profileSectionsInfo.education.name);
        return updateEducation(profileId, values.degrees, isSuggestion);
      }}
      currentPagePath={CURRENT_PAGE_PATH}
      defaultNextSection="/profile/work-history/"
      backButtonPath="/profile/expertise/"
      hasFetchError={hasFetchError}
      hasFetchedUserData={hasFetchedUserData}
      formLevelErrorMessage={errors => {
        if (errors && typeof errors.degrees === 'string') {
          return errors.degrees;
        }
        return Object.keys(errors).length > 0;
      }}
      isSuggestion={isSuggestion}
      isViewingSuggestions={isViewingSuggestions}
      profileId={profileId}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <>
          <DegreesFieldArray values={values} />
          <hr className={sharedProfileStyles.formDivider} />
        </>
      )}
    </ProfileForm>
  );
};

export default Education;
