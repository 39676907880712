import React from 'react';
import sharedProfileStyles from '../profile-form/index.module.scss';
import Input from '../forms/formik-inputs/input';

const Referrer = ({ isSuggestion }) => {
  return (
    <div className={`field ${sharedProfileStyles.groupedFormField}`}>
      <Input
        name={`referrerName`}
        labelText="If you were referred by someone, please list their name here"
        labelClass={`is-size-7`}
        disabled={isSuggestion}
        shouldCheckForCapsLock={true}
      />
    </div>
  );
};

export default Referrer;
