export const ESIG_DOCUMENT_TYPES = {
  ACCEPTANCE_AGREEMENT: {
    id: 182,
    path: '/profile/acceptance-agreement/',
  },
  PAYROLL_DEDUCTION_AGREEMENT: {
    id: 357,
    path: '/profile/payroll-deduction/',
  },
};
