import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/layout';
import styles from './index.module.scss';
import CheckMark from '../check-mark';
import LoadingSpinner from '../../../../components/loading-spinner';
import { navigate, Link } from '@reach/router';
import { getTokenFromUrlParam } from '../../../../utils/url-helpers';
import { verifyEmailChangeSuccessful } from '../../../../services/email-service';

const VerifyEmailChange = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const token = getTokenFromUrlParam();
    if (token === null) {
      setIsLoading(false);
      setIsSuccess(false);
      return;
    }
    verifyEmailChangeSuccessful({ token })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSuccess(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Layout>
      <section className={`${styles.pageContentSection} columns`}>
        <div
          className={`${styles.contentContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <div className={`${styles.pageContent}`}>
            {isLoading ? (
              <LoadingSpinner isLoading={isLoading} classes={styles.spinner} />
            ) : isSuccess ? (
              <>
                <h1
                  className={`${styles.sectionTitle} title is-4 has-text-white has-text-centered`}
                >
                  Your email has been updated
                </h1>

                <div className={`${styles.checkmarkContainer}`}>
                  <CheckMark />
                </div>
                <div
                  className={`has-text-centered ${styles.profileButtonContainer}`}
                >
                  <button
                    className={`button is-centered is-primary`}
                    onClick={() => navigate('/profile/')}
                  >
                    Back to profile
                  </button>
                </div>
              </>
            ) : (
              <p className="notification is-danger">
                Your email failed to update. Please{' '}
                <Link to="/profile/change-email/">try again</Link> or{' '}
                <a href="/contact-us/">contact us</a> if the problem persists.
              </p>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default VerifyEmailChange;
