import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { profileCompletionStatusIds } from '../constants/profile-completion-status-ids';
import { profileSectionsInfo } from '../constants/profile-sections-info';

export function useProfileCompletionApi() {
  const { get } = useAuthenticatedRequest();

  const isSectionComplete = statusId => {
    return statusId === profileCompletionStatusIds.COMPLETE;
  };

  const sectionHasFeedback = statusId => {
    return statusId === profileCompletionStatusIds.HAS_RECRUITER_FEEDBACK;
  };

  const enableLegalAgreementsIfApplicable = sections => {
    const incompleteSections = sections.filter(
      section => !section.isComplete && !section.hasRecruiterFeedback
    );
    const legalAgreementsSection = sections.find(
      s => s.id === profileSectionsInfo.legalAgreements.id
    );

    if (
      legalAgreementsSection.isComplete ||
      (incompleteSections.length === 1 &&
        incompleteSections[0].id === legalAgreementsSection.id)
    ) {
      legalAgreementsSection.disabled = false;
    }
  };

  const formatCompletionStatuses = completionStatusIds => {
    const sections = Object.keys(profileSectionsInfo).map(sectionName => {
      return formatProfileCompletionInfo(
        profileSectionsInfo[sectionName],
        completionStatusIds[sectionName]
      );
    });

    enableLegalAgreementsIfApplicable(sections);

    return sections;
  };

  const formatProfileCompletionInfo = (sectionData, statusId) => {
    const fullPath = `/profile/${sectionData.path}/`;
    const isSectionDefaultDisabled =
      sectionData.id === profileSectionsInfo.legalAgreements.id;

    const formatted = {
      ...sectionData,
      path: fullPath,
      hasRecruiterFeedback: sectionHasFeedback(statusId),
      disabled: isSectionDefaultDisabled,
      isOptional: false,
      isComplete: isSectionComplete(statusId),
    };

    return formatted;
  };

  return {
    getProfileCompletionStatuses: function (profileId) {
      return new Promise((resolve, reject) => {
        get(`/api/profiles/${profileId}/completion-status`)
          .then(({ data }) => {
            const formatted = formatCompletionStatuses(data);
            return resolve(formatted);
          })
          .catch(error => reject(error));
      });
    },

    getFirstUnfinishedSection: function (completionStatus, currentLocation) {
      const unfinished = completionStatus.find(
        item => item.isComplete !== true && item.path !== currentLocation
      );
      return unfinished;
    },

    areAnySectionsUnfinished: function (completionStatus, currentLocation) {
      return completionStatus.some(
        item => item.isComplete !== true && item.path !== currentLocation
      );
    },

    areAllPrecedingSectionsComplete: function (
      completionStatus,
      currentLocation
    ) {
      const indexOfCurrent = completionStatus.findIndex(
        section => section.path === currentLocation
      );
      const firstIncomplete = completionStatus.find(
        section => !section.isComplete
      );
      if (firstIncomplete === undefined) return true;
      const indexOfFirstIncomplete = completionStatus.findIndex(
        section => section === firstIncomplete
      );
      return indexOfCurrent <= indexOfFirstIncomplete;
    },
  };
}
