import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../../components/loading-spinner';
import ProfileFormNextButton from './next-button';
import ProfileFormSuggestionsButtons from './suggestions-buttons';
import styles from './index.module.scss';
import { useProfileCompletionApi } from '../../../../services/profile-completion-service';
import UnsavedChangesWarning from '../../../../components/unsaved-changes-warning';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import PopupNotification from '../../../../components/popup-notification';
import { errorMessages } from '../../../../constants/error-messages';
import { httpResponses } from '../../../../constants/http-responses';
import { useAppContext } from '../../../../providers/app-provider';
import { CAPS_LOCK_WARNING } from '../../../../constants/misc-client-messges';

/*
 * ProfileForm Component
 * Wrapper component that provides common functionality used on
 * profile forms (i.e. Back/Next buttons and Loading Spinners).
 * Many of the props are passed directly into the <Formik> component
 * and accepts children that would be placed in a Formik <Form> component
 * https://formik.org/docs/api/formik
 */

const ProfileForm = ({
  IntroComponent,
  currentPagePath,
  children,
  initialValues,
  defaultNextSection,
  backButtonPath,
  hasFetchedUserData,
  hasFetchError,
  validationSchema,
  onSubmit,
  profileId,
  formLevelErrorMessage,
  nextButtonClass,
  isConfirmationRequired,
  requestConfirmation,
  isSuggestion,
  isViewingSuggestions,
  shouldShowCapsLockWarning,
  shouldShowChecklistOnlyPortal,
  shouldHideBackButton,
  nextButtonText,
  hideBrowserComfirmationModal,
}) => {
  const formEl = useRef(null);
  const {
    getProfileCompletionStatuses,
    getFirstUnfinishedSection,
    areAnySectionsUnfinished,
    areAllPrecedingSectionsComplete,
  } = useProfileCompletionApi();
  const { isCapsLockActive } = useAppContext();
  const [isNavigatingBack, setIsNavigatingBack] = useState(false);
  const DEFAULT_FORM_LEVEL_ERROR =
    'Oops! Please complete all fields before continuing.';

  const [nextSectionPath, setNextSectionPath] = useState(defaultNextSection);
  const [completionStatuses, setCompletionStatuses] = useState([
    {
      name: '',
      isComplete: false,
      path: '',
      hasRecruiterFeedback: false,
    },
  ]);

  const doesSectionHaveRecruiterFeedback = nextPath => {
    const nextSection = completionStatuses.find(
      section => section.path === nextPath
    );
    return nextSection && nextSection.hasRecruiterFeedback;
  };

  const handleBackButtonClick = dirty => {
    // on back button click only attempt to save if form was updated
    setIsNavigatingBack(true);

    if (isSuggestion) {
      navigate(-1);
      return;
    }

    if (dirty) {
      formEl.current && formEl.current.handleSubmit();
    } else {
      navigate(backButtonPath, {
        state: {
          isViewingSuggestions:
            doesSectionHaveRecruiterFeedback(backButtonPath),
        },
      });
    }
  };

  useEffect(() => {
    if (isSuggestion) {
      setNextSectionPath(-1);
    } else {
      getProfileCompletionStatuses(profileId)
        .then(status => {
          let nextSection;

          if (currentPagePath === '/profile/skills-checklists/') {
            nextSection = getNextSectionPathForSkillsChecklist(
              status,
              currentPagePath
            );
          } else if (currentPagePath === '/profile/agreements/') {
            nextSection = areAnySectionsUnfinished(status, currentPagePath)
              ? getFirstUnfinishedSection(status, currentPagePath).path
              : '/profile/done/';
          } else {
            nextSection = areAnySectionsUnfinished(status, currentPagePath)
              ? getFirstUnfinishedSection(status, currentPagePath).path
              : '/profile/';
          }
          setNextSectionPath(nextSection);
          setCompletionStatuses(status);
        })
        .catch();
    }
  }, []);

  const getNextSectionPathForSkillsChecklist = (status, currentPath) => {
    const arePrecedingSectionsDone = areAllPrecedingSectionsComplete(
      status,
      currentPath
    );

    const areAnySectionsIncomplete = areAnySectionsUnfinished(
      status,
      currentPath
    );
    let nextSection;

    if (arePrecedingSectionsDone) {
      nextSection = areAnySectionsIncomplete
        ? '/profile/finish-up/'
        : '/profile/';
    } else {
      nextSection = getFirstUnfinishedSection(status, currentPath).path;
    }
    return nextSection;
  };

  const handleFormSubmit = (values, actions) => {
    onSubmit(values)
      .then(() => {
        if (isNavigatingBack) {
          navigate(backButtonPath, {
            state: {
              isViewingSuggestions:
                doesSectionHaveRecruiterFeedback(backButtonPath),
            },
          });
        } else {
          navigate(nextSectionPath, {
            state: {
              isViewingSuggestions:
                doesSectionHaveRecruiterFeedback(nextSectionPath),
            },
          });
        }
      })
      .catch(err => {
        actions.setSubmitting(false);
        actions.setStatus({
          apiError:
            err.response &&
            err.response.status !== httpResponses.SERVER_ERROR_CODE &&
            typeof err.response.data === 'string'
              ? err.response.data
              : errorMessages.UNABLE_TO_SAVE,
        });
      });
  };

  return (
    <section className={`${styles.formSection} columns`}>
      <div
        className={`${styles.formContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
      >
        {IntroComponent !== null &&
          (typeof IntroComponent === 'object' ? (
            IntroComponent
          ) : (
            <IntroComponent />
          ))}

        <LoadingSpinner isLoading={!hasFetchedUserData && !hasFetchError} />

        {hasFetchError && (
          <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
        )}

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          innerRef={formEl}
          onSubmit={(values, actions) => {
            const isConfirmationRequiredValue =
              typeof isConfirmationRequired === 'function'
                ? isConfirmationRequired(values)
                : isConfirmationRequired;
            if (isConfirmationRequiredValue) {
              actions.setSubmitting(false);
              const confirmationCallbacks = {
                nextSectionPath: nextSectionPath,
                doesNextSectionHaveRecruiterFeedback:
                  doesSectionHaveRecruiterFeedback(nextSectionPath),
                values: values,
                formikActions: actions,
                submitHandler: handleFormSubmit,
              };
              requestConfirmation(confirmationCallbacks);
              return;
            }
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            isSubmitting,
            status,
            dirty,
            errors,
            submitCount,
            setStatus,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            handleChange,
          }) => (
            <Form>
              <div
                className={
                  hasFetchedUserData && !hasFetchError ? '' : 'is-hidden'
                }
              >
                <fieldset disabled={isViewingSuggestions}>
                  {typeof children === 'function'
                    ? children({
                        values: values,
                        errors: errors,
                        setFieldTouched: setFieldTouched,
                        setFieldValue: setFieldValue,
                        handleSubmit: handleSubmit,
                        handleChange: handleChange,
                      })
                    : children}
                </fieldset>
                {errors &&
                submitCount !== 0 &&
                formLevelErrorMessage(errors) ? (
                  <p className="notification is-danger">
                    {typeof formLevelErrorMessage(errors) === 'string'
                      ? formLevelErrorMessage(errors)
                      : DEFAULT_FORM_LEVEL_ERROR}
                  </p>
                ) : null}
                {status && status.apiError ? (
                  status.apiError === errorMessages.UNABLE_TO_SAVE ? (
                    <ApiErrorMessage errorMsg={status.apiError} />
                  ) : (
                    <p className="notification is-danger">{status.apiError}</p>
                  )
                ) : null}
                <div
                  className={`${
                    shouldShowChecklistOnlyPortal
                      ? styles.checklistOnlyPortalNavButtonContainer
                      : styles.navButtonContainer
                  }`}
                >
                  {isViewingSuggestions ? (
                    <ProfileFormSuggestionsButtons
                      currentPagePath={currentPagePath}
                      profileId={profileId}
                      setStatus={setStatus}
                    />
                  ) : (
                    <>
                      {shouldHideBackButton === false && (
                        <button
                          type="button"
                          className={`button is-primary is-outlined ${
                            styles.navButton
                          } ${styles.backButton} ${
                            isSubmitting && isNavigatingBack ? 'is-loading' : ''
                          }`}
                          disabled={isSubmitting}
                          onClick={() => {
                            handleBackButtonClick(dirty);
                          }}
                        >
                          <span className="icon ">
                            <FontAwesomeIcon icon={faChevronLeft} />
                          </span>
                          <span>Back</span>
                        </button>
                      )}
                      <ProfileFormNextButton
                        className={`${styles.navButton} ${
                          nextButtonClass !== null ? nextButtonClass : ''
                        }`}
                        completionStatuses={completionStatuses}
                        currentPagePath={currentPagePath}
                        dirty={dirty}
                        isNavigatingBack={isNavigatingBack}
                        isSubmitting={isSubmitting}
                        isSuggestion={isSuggestion}
                        setIsNavigatingBack={setIsNavigatingBack}
                        buttonText={nextButtonText}
                      />                      
                    </>
                  )}
                </div>
              </div>
              {!hideBrowserComfirmationModal && (
                <UnsavedChangesWarning dirty={dirty} />
              )}
            </Form>
          )}
        </Formik>
        {isCapsLockActive &&
          shouldShowCapsLockWarning &&
          hasFetchedUserData &&
          !hasFetchError && (
            <PopupNotification
              message={CAPS_LOCK_WARNING}
              duration="persistent"
              notificationStyleClass="is-danger"
              showCloseIcon={false}
            />
          )}
      </div>
    </section>
  );
};

ProfileForm.propTypes = {
  IntroComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.any.isRequired,
  nextButtonClass: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  hasFetchedUserData: PropTypes.bool.isRequired,
  hasFetchError: PropTypes.bool.isRequired,
  validationSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
  formLevelErrorMessage: PropTypes.func.isRequired,
  isConfirmationRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
    .isRequired,
  requestConfirmation: PropTypes.func,
  defaultNextSection: PropTypes.string,
  currentPagePath: PropTypes.string.isRequired,
  backButtonPath: PropTypes.string,
  isSuggestion: PropTypes.bool,
  isViewingSuggestions: PropTypes.bool,
  profileId: PropTypes.number.isRequired,
  shouldShowCapsLockWarning: PropTypes.bool,
  shouldShowChecklistOnlyPortal: PropTypes.bool,
  shouldHideBackButton: PropTypes.bool,
  nextButtonText: PropTypes.string,
  hideBrowserComfirmationModal: PropTypes.bool,
};

ProfileForm.defaultProps = {
  IntroComponent: null,
  nextButtonClass: null,
  isConfirmationRequired: false,
  defaultNextSection: '/profile/',
  backButtonPath: '/profile/',
  isSuggestion: false,
  isViewingSuggestions: false,
  shouldShowCapsLockWarning: false,
  shouldShowChecklistOnlyPortal: false,
  shouldHideBackButton: false,
  nextButtonText: null,
  hideBrowserComfirmationModal: false,
};

export default ProfileForm;
