import * as Yup from 'yup';
import { telephoneRegex } from '../../../../utils/validation-utils';

const MINIMUM_REFERENCES_COUNT = 2;

function getRequiredMessage(fieldName) {
  return `Please enter a ${fieldName}`;
}

const validationSchema = Yup.object({
  references: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required(getRequiredMessage('name')),
        title: Yup.string().required(getRequiredMessage('title')),
        facilityName: Yup.string().required(
          getRequiredMessage('facility name')
        ),
        phoneNumber: Yup.string()
          .required(getRequiredMessage('phone number'))
          .matches(telephoneRegex, 'Please enter a valid phone number'),
        email: Yup.string().email('Please enter a valid email'),
      })
    )
    .min(
      MINIMUM_REFERENCES_COUNT,
      ({ min }) => `Please enter at least ${min} references`
    ),
});

export default validationSchema;
