import React, { useEffect, useState } from 'react';

import { useExpertiseApi } from '../../../../services/expertise-service';
import { useChecklistExemptionApi } from '../../../../services/checklist-exemption-service';
import Modal from '../../../../components/modal';
import ProfileForm from '../profile-form';
import SpecialtiesFieldArray from './specialties-field-array';
import TravelExperience from './travel-experience';
import Referrer from '../referrer';
import ShiftPreference from './shift-preference';
import ExpertiseStateOfResidence from './expertise-state-of-residence';
import LicensedStates from './licensed-states';
import DesiredLocations from './desired-locations';
import validationSchema from './validation-schema';
import sharedProfileStyles from '../profile-form/index.module.scss';
import { urlParams } from '../../../../constants/url-params';
import { httpResponses } from '../../../../constants/http-responses';
import { errorMessages } from '../../../../constants/error-messages';
import { useGaHelper } from '../../../../services/google-analytics-service';

const Expertise = ({
  profileId,
  email,
  loggedIn,
  isSuggestion,
  isViewingSuggestions,
}) => {
  const CURRENT_PAGE_PATH = '/profile/expertise/';
  const CHECKLIST_EXEMPTION_WARNING =
    "Alert: You've added a new experience type that requires a skills checklist. Make sure you complete the matching skills checklist in order for your profile to be considered complete.";

  const { getExpertise, updateExpertise } = useExpertiseApi();
  const { willChecklistExemptionEnd } = useChecklistExemptionApi();
  const { trackReferralEntered } = useGaHelper();

  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [
    showApplicationSuccessConfirmation,
    setShowApplicationSuccessConfirmation,
  ] = useState(false);

  const [showChecklistModal, setShowChecklistModal] = useState(false);
  const [isChecklistConfirmationRequired, setIsChecklistConfirmationRequired] =
    useState(true);

  const hasApplicationSuccessUrlParam = () => {
    const url = new URL(document.location);
    const params = url.searchParams;
    const applicationSuccessParamValue = params.get(
      urlParams.APPLICATION_SUCCESS
    );

    return applicationSuccessParamValue === 'true';
  };

  const isFirstTimeVisitingPage = hasApplicationSuccessUrlParam();

  const [expertise, setExpertise] = useState({
    specialties: [
      {
        id: '',
        isPrimary: true,
        experienceInYears: '',
        experienceInMonths: '',
      },
    ],
    travelExperience: {
      hasTraveled: null,
      experienceInYears: '',
      experienceInMonths: '',
    },
    preferences: {
      shiftType: '',
    },
    profession: {
      id: '',
      name: '',
    },
    referrerName: '',
    licensedStateIds: [],
    desiredStateIds: [],
    stateOfResidenceId: '',
  });

  const checkForChecklistExemptionChangeAndProcessOrBlock = ({
    formikActions,
    values,
    submitHandler,
  }) => {
    formikActions.setSubmitting(true);

    const specialtyIds = values.specialties.map(sp => sp.id);
    willChecklistExemptionEnd(profileId, specialtyIds)
      .then(() => {
        submitHandler(values, formikActions);
      })
      .catch(err => {
        if (err?.response?.status === httpResponses.CONFLICT_ERROR_CODE) {
          setShowChecklistModal(true);
        } else {
          formikActions.setStatus({
            apiError:
              err.response &&
              err.response.status !== httpResponses.SERVER_ERROR_CODE &&
              typeof err.response.data === 'string'
                ? err.response.data
                : errorMessages.UNABLE_TO_SAVE,
          });
        }
        formikActions.setSubmitting(false);
      });
  };

  useEffect(() => {
    if (profileId) {
      setShowApplicationSuccessConfirmation(isFirstTimeVisitingPage);

      getExpertise(profileId, isViewingSuggestions)
        .then(res => {
          setExpertise(res);
          setHasFetchedUserData(true);
        })
        .catch(() => {
          setHasFetchError(true);
        });
    }
  }, [profileId]);

  return (
    <>
      <Modal
        title="Success!"
        message={
          <>
            Next, we'll need a little more info before we can find you the
            perfect job.
            <br /> <br />
            At any time, you can save your progress and sign back in to your
            profile with your email address.
          </>
        }
        isOpen={showApplicationSuccessConfirmation}
        confirmButtonText={`Let's get started`}
        confirmButtonModifierClass={`is-info`}
        onConfirm={() => setShowApplicationSuccessConfirmation(false)}
        onCancel={() => setShowApplicationSuccessConfirmation(false)}
        isCentered={true}
        messageModifierClass={`is-info`}
        showCancelButton={false}
        showCancelIconButton={false}
      />
      <ProfileForm
        initialValues={expertise}
        requestConfirmation={checkForChecklistExemptionChangeAndProcessOrBlock}
        isConfirmationRequired={
          isChecklistConfirmationRequired && !isFirstTimeVisitingPage
        }
        IntroComponent={() => (
          <>
            <h1
              className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
            >
              My expertise
            </h1>
            <p className={`subtitle is-6 has-text-centered-mobile`}>
              Tell us about your professional expertise.
            </p>
          </>
        )}
        defaultNextSection={
          loggedIn ? '/profile/education/' : '/profile/register/'
        }
        backButtonPath={loggedIn ? '/profile/' : '/jobs/'}
        currentPagePath={CURRENT_PAGE_PATH}
        hasFetchError={hasFetchError}
        hasFetchedUserData={hasFetchedUserData}
        formLevelErrorMessage={errors => {
          if (errors && typeof errors.specialties === 'string') {
            return errors.specialties;
          }
          return Object.keys(errors).length > 0;
        }}
        validationSchema={validationSchema(isSuggestion)}
        onSubmit={values => {
          if (values.referrerName && !expertise.referrerName) {
            trackReferralEntered('Expertise', email, values.referrerName);
          }
          return updateExpertise(values, profileId, isSuggestion);
        }}
        profileId={profileId}
        isSuggestion={isSuggestion}
        isViewingSuggestions={isViewingSuggestions}
        shouldShowCapsLockWarning={true}
      >
        {({ values, handleSubmit }) => (
          <>
            <SpecialtiesFieldArray values={values} />
            <hr className={sharedProfileStyles.formDivider} />
            <TravelExperience values={values} />
            <ShiftPreference />
            <hr className={sharedProfileStyles.formDivider} />
            <LicensedStates
              values={values.licensedStateIds}
              isViewingSuggestions={isViewingSuggestions}
            />
            <Referrer isSuggestion={isSuggestion} />
            <DesiredLocations
              isViewingSuggestions={isViewingSuggestions}
              values={values.desiredStateIds ?? []}
            />
            <hr className={sharedProfileStyles.formDivider} />
            <hr className={sharedProfileStyles.formDivider} />
            <ExpertiseStateOfResidence
              formValues={values}
              isSuggestion={isSuggestion}
              isViewingSuggestions={isViewingSuggestions}
            />
            <Modal
              onConfirm={() => {
                setIsChecklistConfirmationRequired(false);
                handleSubmit(values);
                setShowChecklistModal(false);
              }}
              onCancel={() => {
                setShowChecklistModal(false);
              }}
              isOpen={showChecklistModal}
              title="Review &amp; Approve"
              message={CHECKLIST_EXEMPTION_WARNING}
              confirmButtonText="Proceed"
              messageModifierClass="is-info"
              confirmButtonModifierClass="is-info"
            />
          </>
        )}
      </ProfileForm>
    </>
  );
};

export default Expertise;
