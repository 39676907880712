import { useAuthenticatedRequest } from '../hooks/api-hooks';

export function useSuggestionsApi() {
  const { post, deleteRequest } = useAuthenticatedRequest();

  return {
    publishSuggestions: function (candidateProfileId, forceAccept) {
      const pathSlug = forceAccept ? 'force-accept-all' : 'submit-all';
      let path = `/api/profile-suggestions/${candidateProfileId}/${pathSlug}`;
      return post(path);
    },

    acceptSuggestions: function (candidateProfileId, sectionId) {
      let path = `/api/profile-suggestions-review/${candidateProfileId}/accept/${sectionId}`;
      return post(path);
    },

    rejectSuggestions: function (candidateProfileId, sectionId) {
      let path = `/api/profile-suggestions-review/${candidateProfileId}/reject/${sectionId}`;
      return post(path);
    },

    cancelDraft: function (candidateProfileId, sectionId) {
      let path = `/api/profile-suggestions/${candidateProfileId}/section-drafts/${sectionId}`;
      return deleteRequest(path);
    },

    approveForcedSuggestions: function (candidateProfileId) {
      let path = `/api/profile-suggestions/${candidateProfileId}/approve-all-forced`;

      return post(path);
    },
  };
}
