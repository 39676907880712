import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const ButtonGroup = ({ name, labelText, options, buttonClass }) => {
  const [, meta, helpers] = useField(name);

  const { setValue } = helpers;

  return (
    <div className="field">
      <p className={`label is-size-7`}>{labelText}</p>
      <div className={`field has-addons`}>
        {options.map(({ buttonText, value }, index) => (
          <div className={`control`} key={index}>
            <button
              type="button"
              className={`button is-small  ${buttonClass} ${
                meta.value === value ? 'is-info' : ''
              }`}
              onClick={() => setValue(value)}
            >
              {buttonText}
            </button>
          </div>
        ))}
      </div>

      {meta.error && meta.touched && (
        <p className={`help has-text-danger`}>{meta.error}</p>
      )}
    </div>
  );
};

ButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string,
      value: PropTypes.any,
    }).isRequired
  ),
  buttonClass: PropTypes.string,
};

ButtonGroup.defaultProps = {
  buttonClass: '',
  labelText: '',
};

export default ButtonGroup;
