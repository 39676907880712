import React from 'react';

import { navigate } from '@reach/router';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Layout from '../../../../components/layout';
import CheckMark from '../check-mark';

const FinishUp = () => {

  const NEXT_BUTTON_PATH = '/profile/personal-info';
  const finishUp = () => navigate(NEXT_BUTTON_PATH);

  return (
    <>
      <Layout>
        <div
          className={`columns ${styles.centeredBox} ${styles.secondaryColorBackgroundOnMobile} ${styles.formSection}`}
        >
          <div
            className={`column is-6 is-10-mobile ${styles.columnWithPadding}`}
          >
            <div className={`${styles.centeredBox} ${styles.topMargin}`}>
              <CheckMark />
            </div>

            <h1 className={`title ${styles.title}`}>Great job!</h1>

            <p className={`${styles.textBox}`}>
              We've collected all the nitty gritty details we'll need for your
              application. Next, we'll walk you through the final steps.
            </p>

            <div
              className={`has-text-centered field ${styles.finishUpButtonWrapper}`}
            >
              <button
                className={`button is-primary`}
                onClick={() => finishUp()}
              >
                <span>Finish up</span>
                <span className="icon ">
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FinishUp;
