import formLocations, {
  formLocationEvents,
} from '../components/job-application-form/form-locations';
import { jobApplicationTypes } from '../constants/job_application_types';

const trackCustomEvent = trackingEvent => {
  typeof window !== 'undefined' &&
    window.gtag &&
    window.gtag('event', trackingEvent.event_action, trackingEvent);
};

const gaEventCategories = {
  LOG_IN: 'Log In',
  APPLICATION: 'Application',
};

const getFormNameByLocation = formLocation => {
  let locationToNameMap = {};
  for (const property in formLocations) {
    locationToNameMap[formLocations[property]] = property;
  }

  return locationToNameMap[formLocation];
};

const getApplicationType = (isQuickApply, jobId) => {
  if (isQuickApply) return jobApplicationTypes.JOB_SPECIFIC.QUICK;

  return jobId !== null
    ? jobApplicationTypes.JOB_SPECIFIC.LONG_FORM
    : jobApplicationTypes.GENERAL;
};

const trackAdditionalLocationSpecificEventForGenApplies = (
  appType,
  trackingEvent,
  formLocation
) => {
  if (appType !== jobApplicationTypes.GENERAL) return;

  trackingEvent.action += ` - ${formLocation}`;
  trackCustomEvent(trackingEvent);
};

export function useGaHelper() {
  return {
    trackApplication: (email, isQuickApply, jobId, formLocation) => {
      const appType = getApplicationType(isQuickApply, jobId);
      let trackingEvent = {
        event_category: gaEventCategories.APPLICATION,
        event_action: `${appType}`,
        event_label: `${email} applied. App type: ${appType}`,
      };

      trackCustomEvent(trackingEvent);
      trackAdditionalLocationSpecificEventForGenApplies(
        appType,
        trackingEvent,
        formLocation
      );
    },

    trackLandingPageApplication: (email, landingPageType) => {
      const actionType = `${landingPageType} Application`;
      let trackingEvent = {
        event_category: gaEventCategories.APPLICATION,
        event_action: actionType,
        event_label: `${email} applied on landing page.`,
      };

      trackCustomEvent(trackingEvent);
    },

    trackDupeApplicationAttempt: email => {
      let trackingEvent = {
        event_category: gaEventCategories.APPLICATION,
        event_action: 'Duplicate email or phone warning shown',
        event_label: `${email} application dupe warning.`,
      };

      trackCustomEvent(trackingEvent);
    },

    triggerApplicationTrackingEvent: (job = null, formLocation) => {
      let trackingEvent = {
        event_category: gaEventCategories.APPLICATION,
        event_action: 'Submit',
        event_label: formLocationEvents[getFormNameByLocation(formLocation)],
      };

      if (job !== null) trackingEvent.value = job.id;

      trackCustomEvent(trackingEvent);
    },

    trackFailedLogin: email => {
      let trackingEvent = {
        event_category: gaEventCategories.LOG_IN,
        event_action: 'Login Failed',
        event_label: `${email} attempted & failed to login`,
      };

      trackCustomEvent(trackingEvent);
    },

    trackSuccessfulLogin: (email, roleName) => {
      let trackingEvent = {
        event_category: gaEventCategories.LOG_IN,
        event_action: `Successful ${roleName} Login`,
        event_label: `${email} logged in successfully`,
      };

      trackCustomEvent(trackingEvent);
    },

    triggerProfileProgressTrackingEvent: location => {
      let trackingEvent = {
        event_category: 'Profile Conversion Tracking',
        event_action: 'Update',
        event_label: `${location} Updated`,
      };

      trackCustomEvent(trackingEvent);
    },

    trackSourceReferred: (page, email) => {
      let trackingEvent = {
        event_category: 'Source Referred',
        event_action: `${page} Source Referred`,
        event_label: `${email} selected referred by someone`,
      };

      trackCustomEvent(trackingEvent);
    },

    trackReferralEntered: (page, email, referrer) => {
      let trackingEvent = {
        event_category: 'Referral Entered',
        event_action: `${page} Referral Entered`,
        event_label: `${email} entered ${referrer}`,
      };

      trackCustomEvent(trackingEvent);
    }
  };
}
