import * as Yup from 'yup';
import { professions } from '../../../../constants/professions';

const MIN_REQUIRED_LICENSES_FOR_NURSES = 1;
const MIN_REQUIRED_CERTS_FOR_NURSES = 1;
const MIN_REQUIRED_LICENSES = 0;
const MIN_REQUIRED_CERTS = 0;

const NO_DATE_SELECTED = 'Please select a date';
const NO_SELECTION_MESSAGE = 'Please select an option';
const DATE_IN_FUTURE = 'Please select a date in past';
const DATE_IN_PAST = 'Please select a date in the future';
const getMinRequiredMessage = fieldType =>
  `Please enter at least 1 ${fieldType}.`;
const todayDateString = new Date().toISOString();

function getRequiredMessage(fieldName) {
  return `Please enter a ${fieldName}`;
}

const validationSchema = professionId =>
  Yup.object({
    licenses: Yup.array()
      .of(
        Yup.object({
          licenseNumber: Yup.string().required(
            getRequiredMessage('license number')
          ),
          issuingStateId: Yup.number().required(NO_SELECTION_MESSAGE),
          issuedDate: Yup.date()
            .typeError(NO_DATE_SELECTED)
            .required(NO_DATE_SELECTED)
            .max(todayDateString, DATE_IN_FUTURE),
          expirationDate: Yup.date()
            .required(NO_DATE_SELECTED)
            .typeError(NO_DATE_SELECTED)
            .min(todayDateString, DATE_IN_PAST),
        })
      )
      .min(
        professionId === professions.NURSING
          ? MIN_REQUIRED_LICENSES_FOR_NURSES
          : MIN_REQUIRED_LICENSES,
        getMinRequiredMessage('license')
      ),
    certs: Yup.array()
      .of(
        Yup.object({
          typeId: Yup.number().required(NO_SELECTION_MESSAGE),
          providerId: Yup.number().required(NO_SELECTION_MESSAGE),
          issuedDate: Yup.date()
            .typeError(NO_DATE_SELECTED)
            .required(NO_DATE_SELECTED)
            .max(todayDateString, DATE_IN_FUTURE),
          expirationDate: Yup.date()
            .required(NO_DATE_SELECTED)
            .typeError(NO_DATE_SELECTED)
            .min(todayDateString, DATE_IN_PAST),
        })
      )
      .min(
        professionId === professions.NURSING
          ? MIN_REQUIRED_CERTS_FOR_NURSES
          : MIN_REQUIRED_CERTS,
        getMinRequiredMessage('certification')
      ),
  });

export default validationSchema;
