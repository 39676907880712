import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';

const TOTAL_STEPS = 6;

const ProgressStepper = ({ currentStep }) => {
  const getCompletedSteps = () => {
    let completedSteps = [];
    const completedStepsCount = currentStep - 1;
    for (let i = 0; i < completedStepsCount; i++) {
      completedSteps.push(
        <li key={i} className={`${styles.stepsSegment}`}>
          <span className={styles.stepsMarker}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </li>
      );
    }

    return completedSteps;
  };

  const getNotStartedSteps = () => {
    let notStartedSteps = [];
    const notStartedStepsCount = TOTAL_STEPS - currentStep;
    for (let i = 0; i < notStartedStepsCount; i++) {
      notStartedSteps.push(
        <li key={`not-started-${i}`} className={styles.stepsSegment}>
          <span className={styles.stepsMarker}></span>
        </li>
      );
    }

    return notStartedSteps;
  };

  return (
    <section className={`columns ${styles.stepperSection}`}>
      <div className="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop">
        <ul className={`${styles.steps} ${styles.isHorizontal}`}>
          {getCompletedSteps()}

          <li
            key={'current'}
            className={`${styles.stepsSegment}  ${styles.isActive}`}
          >
            <span className={`${styles.stepsMarker} ${styles.isHollow}`}></span>
          </li>
          {getNotStartedSteps()}
        </ul>
      </div>
    </section>
  );
};

ProgressStepper.propTypes = {
  currentStep: PropTypes.number,
};

export default ProgressStepper;
