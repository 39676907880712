const formLocations = {
  JOB_DETAILS: 'jobDetails',
  HOME_PAGE: 'homePage',
  APPLY_PAGE: 'applyPage',
};

// necessary to map form location to custom tracking events
// new form locations should have a corresponding formType below
export const formLocationEvents = {
  JOB_DETAILS: 'Quick applies',
  HOME_PAGE: 'General applies',
  APPLY_PAGE: 'General applies',
};

export default formLocations;
