import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { navigate } from '@reach/router';

import Input from '../../../common/components/forms/formik-inputs/input';
import Modal from '../../../../components/modal';
import { useUserApi } from '../../../../services/user-service';
import validationSchema from './validation-schema';
import styles from './index.module.scss';
import Layout from '../../../../components/layout';
import { useAuth } from '../../../../providers/auth-provider';

const ForgotPassword = () => {
  const { requestPasswordReset } = useUserApi();
  const { getLoginPathBasedOnTalentStackActiveFlag } = useAuth();
  const resetForm = actions => {
    actions.setValues({
      email: '',
    });
    actions.setTouched({});
  };

  const handleRequestSuccess = actions => {
    actions.setSubmitting(false);
    actions.setStatus({
      apiSuccess: true,
    });
    resetForm(actions);
  };

  const handleRequestFailure = actions => {
    actions.setSubmitting(false);
    actions.setStatus({
      apiError: 'There was a problem resetting your password.',
    });
  };

  return (
    <Layout>
      <section className={`${styles.formSection} columns`}>
        <div
          className={`${styles.formContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <div className={`${styles.formContent}`}>
            <h1
              className={`${styles.sectionTitle} title is-4 has-text-white has-text-centered`}
            >
              Reset your password
            </h1>
            <p className={`subtitle is-6 has-text-centered has-text-white`}>
              Please enter your email address. We will send you an email with
              instructions to reset your password.
            </p>

            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                requestPasswordReset(values)
                  .then(() => {
                    handleRequestSuccess(actions);
                  })
                  .catch(() => {
                    handleRequestFailure(actions);
                  });
              }}
            >
              {({ isSubmitting, status, setStatus }) => (
                <Form>
                  <div className="field">
                    <Input
                      name={`email`}
                      labelText="Email address"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="email"
                    />
                  </div>

                  <Modal
                    title="Instructions Sent"
                    message={
                      <>
                        You should receive the email with a link shortly.
                        <br />
                        <br />
                        Not seeing it? Be sure to check your spam folder. If you
                        still can't find it, try again or{' '}
                        <a href="/contact-us">contact us</a>.
                      </>
                    }
                    isOpen={status && status.apiSuccess ? true : false}
                    confirmButtonText={'Back to Sign In'}
                    confirmButtonModifierClass={`is-info`}
                    onConfirm={() =>
                      navigate(getLoginPathBasedOnTalentStackActiveFlag())
                    }
                    onCancel={() => setStatus({ apiSuccess: false })}
                    messageModifierClass={`is-info`}
                  />

                  {status && status.apiError && (
                    <p className="notification is-danger">
                      {status.apiError} Please try again or
                      {` `}
                      <a href="/contact-us">contact us</a> if the problem
                      persists.
                    </p>
                  )}

                  <div className="has-text-centered">
                    <button
                      type="submit"
                      className={`button is-primary ${styles.nextButton} ${
                        isSubmitting ? 'is-loading' : ''
                      }`}
                      disabled={isSubmitting}
                    >
                      <span>Send Reset Instructions</span>
                      <span className="icon ">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ForgotPassword;
