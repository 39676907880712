import React from 'react';
import { Link, navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import jwtDecode from 'jwt-decode';

import { useAuth } from '../../../../providers/auth-provider';
import Input from '../../../common/components/forms/formik-inputs/input';
import PopupNotification from '../../../../components/popup-notification';
import { useUserApi } from '../../../../services/user-service';
import { useGaHelper } from '../../../../services/google-analytics-service';
import validationSchema from './validation-schema';
import styles from './index.module.scss';
import Layout from '../../../../components/layout';
import { authRoles } from '../../../../constants/auth-roles';
import { errorMessages } from '../../../../constants/error-messages';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import { useAppContext } from '../../../../providers/app-provider';
import { CAPS_LOCK_WARNING } from '../../../../constants/misc-client-messges';
import tokenPropertyNames from '../../../../constants/token-property-names';
import { httpResponses } from '../../../../constants/http-responses';

const Login = () => {
  const { logInUser } = useUserApi();
  const { logIn, loggedIn, getRoles, getValueFromToken, meta } = useAuth();
  const { trackSuccessfulLogin, trackFailedLogin } = useGaHelper();
  const { isCapsLockActive } = useAppContext();

  const getErrorMessageAddendum = errorText =>
    errorText === errorMessages.ACCOUNT_BLOCKED ? (
      <>
        Too many incorrect login attempts have been detected for this account.
        Please <Link to="/profile/forgot-password/">reset your password</Link>{' '}
        to restore access to this account.
      </>
    ) : undefined;

  const getRedirectPath = () => {
    const url = new URL(document.location);
    const params = url.searchParams;
    const redirectPath = params.get('redirectTo');

    return redirectPath ? `${redirectPath}` : '/profile/';
  };

  const navigateByRole = roles => {
    if (roles.includes(authRoles.ADMIN))
      navigate('/admin/recruiter-management/');
    else if (
      roles.includes(authRoles.RECRUITER) ||
      roles.includes(authRoles.ADMIN_ASSISTANT)
    )
      navigate('/recruiter/dashboard/');
    else if (
      roles.includes(authRoles.IGH_PORTAL_CANDIDATE) ||
      (roles.includes(authRoles.APPLICANT) && meta.jobApplicantRestricted)
    )
      navigate('/profile/skills-checklists/');
    else if (roles.includes(authRoles.APPLICANT)) navigate(getRedirectPath());
  };

  if (loggedIn) {
    // If already logged in redirect to dashboard
    navigateByRole(getRoles());
  }

  return (
    <Layout>
      <section className={`${styles.formSection} columns`}>
        <div
          className={`${styles.formContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <div className={`${styles.formContent}`}>
            <h1
              className={`${styles.sectionTitle} title is-4 has-text-white has-text-centered`}
            >
              Log in to your Trustaff profile.
            </h1>
            <Formik
              initialValues={{
                email: '',
                pwd: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                logInUser(values)
                  .then(res => {
                    const { access, refresh } = res.data;
                    logIn(access, refresh);
                    actions.setSubmitting(false);
                    const dataFromJwt = jwtDecode(access);
                    const userRole = getValueFromToken(
                      tokenPropertyNames.USER_ROLE,
                      dataFromJwt
                    );
                    trackSuccessfulLogin(values.email, userRole);
                    navigateByRole(userRole);
                  })
                  .catch(err => {
                    trackFailedLogin(values.email);
                    actions.setSubmitting(false);
                    actions.setStatus({
                      apiError:
                        err?.response?.status === httpResponses.UNAUTHORIZED
                          ? err.response.data
                          : errorMessages.UNABLE_TO_FETCH,
                    });
                  });
              }}
            >
              {({ values, isSubmitting, status }) => (
                <Form>
                  <div className="field">
                    <Input
                      name={`email`}
                      labelText="Email address"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="email"
                      shouldCheckForCapsLock={true}
                    />
                  </div>
                  <div className="field">
                    <Input
                      type="password"
                      name={`pwd`}
                      labelText="Password"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="current-password"
                      shouldCheckForCapsLock={true}
                    />
                  </div>

                  {status && status.apiError ? (
                    <ApiErrorMessage
                      errorMsg={status.apiError}
                      errorAddendum={getErrorMessageAddendum(status.apiError)}
                    />
                  ) : null}

                  <div className="has-text-centered">
                    <button
                      type="submit"
                      className={`button is-primary ${styles.nextButton} ${
                        isSubmitting ? 'is-loading' : ''
                      }`}
                      disabled={isSubmitting}
                    >
                      <span>Login</span>
                      <span className="icon ">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <p
              className={`is-6 has-text-centered has-text-white ${styles.pwResetPrompt}`}
            >
              Forgot password?{' '}
              <Link to="/profile/forgot-password">Reset it here.</Link>
            </p>
          </div>
        </div>
        {isCapsLockActive && (
          <PopupNotification
            showCloseIcon={false}
            message={CAPS_LOCK_WARNING}
            duration="persistent"
            notificationStyleClass="is-danger"
          />
        )}
      </section>
    </Layout>
  );
};

export default Login;
