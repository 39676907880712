import React, { useEffect, useState } from 'react';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../../constants/error-messages';
import { ESIG_DOCUMENT_TYPES } from '../../../../constants/esig-document-types';
import Layout from '../../../../components/layout';
import { Link } from '@reach/router';
import LoadingSpinner from '../../../../components/loading-spinner';
import sharedProfileStyles from '../../../common/components/profile-form/index.module.scss';
import styles from './index.module.scss';
import { useAuth } from '../../../../providers/auth-provider';
import { useAgreementsApi } from '../../../../services/agreements-service';
import { getFormattedDate } from '../../../../utils/format-helpers';

const Documents = () => {
  const { getProfileId } = useAuth();
  const { getUnsignedAgreements } = useAgreementsApi();
  const [unsignedDocuments, setUnsignedDocuments] = useState([]);
  const [isFetchingDocuments, setIsFetchingDocuments] = useState(true);
  const [hasFetchError, setHasFetchError] = useState(false);

  const getDocumentPath = ({ id, typeId }) => {
    const docType = Object.values(ESIG_DOCUMENT_TYPES).find(
      docType => docType.id === typeId
    );
    return `${docType.path}${id}`;
  };

  const isSupportedDocumentType = typeId => {
    const supportedDocumentTypeIds = Object.values(ESIG_DOCUMENT_TYPES).map(
      docTypes => docTypes.id
    );

    return supportedDocumentTypeIds.includes(typeId);
  };

  const showNoDocumentsMessage =
    !isFetchingDocuments && !hasFetchError && unsignedDocuments.length === 0;

  useEffect(() => {
    getUnsignedAgreements(getProfileId())
      .then(({ data }) => setUnsignedDocuments(data))
      .catch(() => setHasFetchError(true))
      .finally(() => setIsFetchingDocuments(false));
  }, []);

  return (
    <Layout>
      <section className={`${styles.contentSection} columns`}>
        <div
          className={`${styles.contentContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            Documents
          </h1>
          <p className={`subtitle is-6 has-text-centered-mobile`}>
            Below you can find relevant documents for signing related to your
            Trustaff profile. Please review all documents listed under{' '}
            <b>Unsigned Documents</b> in order to ensure a smooth placement
            process.
          </p>
          <hr className={sharedProfileStyles.formDivider} />
          <h2 className={`has-text-centered-mobile ${styles.sectionText}`}>
            Unsigned Documents
          </h2>

          <LoadingSpinner isLoading={isFetchingDocuments} />

          {hasFetchError ? (
            <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
          ) : null}

          {showNoDocumentsMessage ? (
            <p>You currently have no unsigned documents</p>
          ) : null}

          <ul>
            {unsignedDocuments
              .filter(doc => isSupportedDocumentType(doc.typeId))
              .map((doc, i) => (
                <li key={i}>
                  <div className={`columns ${styles.documentContainer}`}>
                    <div className={`column has-text-left-tablet`}>
                      <Link to={getDocumentPath(doc)} className={`is-size-5`}>
                        {doc.name}
                      </Link>
                    </div>
                    <div className={`column has-text-centered`}>
                      {doc.dateAssigned && (
                        <p className="is-size-5">
                          {getFormattedDate(doc.dateAssigned)}
                        </p>
                      )}
                    </div>
                    <div className={`column has-text-right-tablet`}>
                      <Link
                        className={`button is-primary  ${styles.reviewButton}`}
                        to={getDocumentPath(doc)}
                      >
                        Review document
                      </Link>
                    </div>
                  </div>
                  <hr className={styles.divider} />
                </li>
              ))}
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default Documents;
