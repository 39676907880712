import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import ProfileApp from './../apps/profile';

const Profile = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Profile - Trustaff</title>
      </Helmet>
      <ProfileApp data={data} />
    </>
  );
};

export default Profile;

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^profile/resources/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
