import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';

import WorkHistoryFieldArray from './work-history-field-array';
import ProfileForm from '../profile-form';
import { useWorkHistoryApi } from '../../../../services/work-history-service';
import validationSchema from './validation-schema';
import sharedProfileStyles from '../profile-form/index.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getDefaultHistoryEntryValues,
  historyEntryTypes,
} from '../../../../constants/default-history-entry-values';
import { useAppContext } from '../../../../providers/app-provider';
import Modal from '../../../../components/modal';

const WorkHistory = ({ profileId, isSuggestion, isViewingSuggestions }) => {
  const { professionId } = useAppContext();
  const { getWorkHistory, updateWorkHistory } = useWorkHistoryApi();
  const CURRENT_PAGE_PATH = '/profile/work-history/';
  const DEFAULT_NEXT_PATH = '/profile/references/';
  const [nextSection, setNextSection] = useState({
    path: DEFAULT_NEXT_PATH,
    hasRecruiterFeedback: false,
  });
  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [
    workTypeIdsThatRequireAgencyName,
    setWorkTypeIdsThatRequireAgencyName,
  ] = useState([]);
  const [unitLevelRequiredIds, setUnitLevelRequiredIds] = useState([]);
  const [showOpenEntriesIntially, setShowOpenEntriesInitially] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasConfirmationApiError, setHasConfirmationApiError] = useState(false);

  const EMPLOYMENT_GAP_LIMIT_DAYS = 30;
  const defaultValues = {
    workHistoryEntries: [
      getDefaultHistoryEntryValues(profileId, historyEntryTypes.WORK_HISTORY),
    ],
  };
  const [workHistory, setWorkHistory] = useState(defaultValues);
  const hasSubmittedAnEntityAsNullPreviously =
    workHistory.workHistoryEntries.some(
      e => e.data.hasSubmittedPreviouslyAsNull === true
    );

  useEffect(() => {
    getWorkHistory(profileId, isViewingSuggestions)
      .then(data => {
        if (data.workHistoryEntries.length) {
          setWorkHistory(data);
        }
        setHasFetchedUserData(true);
        setShowOpenEntriesInitially(data.workHistoryEntries.length <= 2);
      })

      .catch(() => {
        setHasFetchError(true);
      });
  }, []);

  const getDaysBetween = (startDate, endDate) => {
    const oneDayInMillis = 1000 * 60 * 60 * 24;

    const diffInTime = startDate.getTime() - endDate.getTime();
    const diffInDays = Math.round(diffInTime / oneDayInMillis);

    return diffInDays;
  };

  const hasEmploymentGaps = values => {
    let hasGaps = false;
    const sortedEntries = [...values.workHistoryEntries].sort(function (a, b) {
      const endDateA =
        a.data.endDate === '' ? new Date() : new Date(a.data.endDate);
      const endDateB =
        b.data.endDate === '' ? new Date() : new Date(b.data.endDate);

      return endDateA - endDateB;
    });

    sortedEntries.forEach((entry, idx, arr) => {
      const currEndDate = new Date(entry.data.endDate);
      const nextEntryStartDate = new Date(arr[idx + 1]?.data.startDate);

      const daysBetween = getDaysBetween(nextEntryStartDate, currEndDate);

      if (daysBetween > EMPLOYMENT_GAP_LIMIT_DAYS) hasGaps = true;
    });
    return hasGaps;
  };

  return (
    <ProfileForm
      initialValues={workHistory}
      IntroComponent={() => (
        <>
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            My work history
          </h1>
          <p className={`subtitle is-6 has-text-centered-mobile`}>
            Tell us about your past employment, leaving no gaps in your work
            history. The more detail you can provide about your past
            professional experiences, the better your profile will look to
            hiring managers!
          </p>
        </>
      )}
      onSubmit={values => {
        return updateWorkHistory(
          profileId,
          values.workHistoryEntries,
          workTypeIdsThatRequireAgencyName,
          unitLevelRequiredIds,
          isSuggestion
        );
      }}
      currentPagePath={CURRENT_PAGE_PATH}
      defaultNextSection={DEFAULT_NEXT_PATH}
      backButtonPath="/profile/education/"
      hasFetchError={hasFetchError}
      hasFetchedUserData={hasFetchedUserData}
      formLevelErrorMessage={errors => {
        if (errors && typeof errors.workHistoryEntries === 'string') {
          return errors.workHistoryEntries;
        }
        return Object.keys(errors).length > 0;
      }}
      isSuggestion={isSuggestion}
      isViewingSuggestions={isViewingSuggestions}
      isConfirmationRequired={values => hasEmploymentGaps(values)}
      requestConfirmation={(nextPath, doesSectionHaveRecruiterFeedback) => {
        setNextSection({
          path: nextPath,
          hasRecruiterFeedback: doesSectionHaveRecruiterFeedback,
        });
        setShowConfirmationModal(true);
      }}
      profileId={profileId}
      shouldShowCapsLockWarning={true}
      validationSchema={validationSchema(
        workTypeIdsThatRequireAgencyName,
        unitLevelRequiredIds,
        professionId,
        hasSubmittedAnEntityAsNullPreviously
      )}
    >
      {({ values, errors, handleChange, setFieldTouched, setFieldValue }) => (
        <>
          <WorkHistoryFieldArray
            values={values}
            profileId={profileId}
            setFieldTouched={setFieldTouched}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            workTypeIdsThatRequireAgencyName={workTypeIdsThatRequireAgencyName}
            setWorkTypeIdsThatRequireAgencyName={
              setWorkTypeIdsThatRequireAgencyName
            }
            unitLevelRequiredIds={unitLevelRequiredIds}
            setUnitLevelRequiredIds={setUnitLevelRequiredIds}
            isViewingSuggestions={isViewingSuggestions}
            showOpenEntriesIntially={showOpenEntriesIntially}
            errors={errors}
          />
          <hr className={sharedProfileStyles.formDivider} />
          <Modal
            title={`Employment Gap`}
            isOpen={showConfirmationModal}
            message={`You have gaps greater than 30 days between employment entries. Are you sure you want to continue?`}
            isCentered={true}
            isSubmitting={isSubmitting}
            hasApiError={hasConfirmationApiError}
            confirmButtonText="Continue"
            confirmButtonModifierClass={`is-info`}
            messageModifierClass={`is-info`}
            showCancelButton={true}
            cancelButtonText={`Go back`}
            showCancelIconButton={false}
            showModalCloseButton={false}
            onConfirm={() => {
              setIsSubmitting(true);

              updateWorkHistory(
                profileId,
                values.workHistoryEntries,
                workTypeIdsThatRequireAgencyName,
                unitLevelRequiredIds,
                isSuggestion
              )
                .then(() => {
                  setShowConfirmationModal(false);

                  navigate(nextSection.path.nextSectionPath, {
                    state: {
                      isViewingSuggestions: nextSection.hasRecruiterFeedback,
                    },
                  });
                })
                .catch(() => {
                  setIsSubmitting(false);
                  setHasConfirmationApiError(true);
                });
            }}
            onCancel={() => {
              setHasConfirmationApiError(false);
              setShowConfirmationModal(false);
            }}
          />
        </>
      )}
    </ProfileForm>
  );
};

export default WorkHistory;
