import React, { useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import apiService from '../../../../services/api-service';
import { useStatesApi } from '../../../../services/states-service';
import { useCountriesApi } from '../../../../services/countries-service';
import sharedProfileStyles from '../profile-form/index.module.scss';
import styles from './work-history-field-array.module.scss';
import HistoryEntry from './history-entry';
import {
  getDefaultHistoryEntryValues,
  historyEntryTypes,
} from '../../../../constants/default-history-entry-values';
import GapEntry from './gap-entry';

const WorkHistoryFieldArray = ({
  values,
  profileId,
  setFieldTouched,
  handleChange,
  setFieldValue,
  workTypeIdsThatRequireAgencyName,
  setWorkTypeIdsThatRequireAgencyName,
  unitLevelRequiredIds: unitTypeIdsRequiringUnitLevelSelection,
  setUnitLevelRequiredIds,
  isViewingSuggestions,
  showOpenEntriesIntially,
  errors,
}) => {
  const { getAllStatesAsValueNameObjects } = useStatesApi();
  const { getAllCountriesAsValueNameObjects } = useCountriesApi();
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setcountryOptions] = useState([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const [unitLevelOptions, setUnitLevelOptions] = useState([]);
  const [workTypeOptions, setWorkTypeOptions] = useState([]);
  const [gapReasonOptions, setGapReasonOptions] = useState([]);

  const doesWorkTypeRequireAgencyName = workTypeId => {
    return workTypeIdsThatRequireAgencyName.includes(parseInt(workTypeId));
  };

  const hasUnitLevel = (
    unitTypeIds,
    unitTypeIdsRequiringUnitLevelSelection
  ) => {
    return unitTypeIds.some(unitTypeId =>
      unitTypeIdsRequiringUnitLevelSelection.includes(parseInt(unitTypeId))
    );
  };

  const formatUnitTypes = unitTypes => {
    const formatted = unitTypes.map(({ id, name }) => ({
      value: id,
      name: name,
    }));

    return formatted;
  };

  useEffect(() => {
    getAllStatesAsValueNameObjects().then(states => {
      setStateOptions(states);
    });
    getAllCountriesAsValueNameObjects().then(countries => {
      setcountryOptions(countries);
    });

    apiService
      .get('/api/lookup-values-for-view/work-history')
      .then(({ data }) => {
        setUnitTypeOptions(formatUnitTypes(data.unitTypes));
        setUnitLevelOptions(
          data.unitLevels.map(({ id, name }) => ({ value: id, name: name }))
        );
        setWorkTypeOptions(
          data.workTypes.map(({ id, name }) => ({ value: id, name: name }))
        );
        setGapReasonOptions(
          data.employmentGapReasons.map(({ id, name }) => ({
            value: id,
            name: name,
          }))
        );

        const AGENCY_NAME_REQUIRED_WORK_TYPE_NAMES = ['Local Agency', 'Travel'];

        const workTypeIds = data.workTypes
          .filter(({ name }) =>
            AGENCY_NAME_REQUIRED_WORK_TYPE_NAMES.includes(name)
          )
          .map(({ id }) => id);
        setWorkTypeIdsThatRequireAgencyName(() => workTypeIds);

        const UNIT_LEVEL_REQUIRED_NAMES = [
          'ICU',
          'ICU - Burn',
          'ICU - CVICU',
          'ICU - Neuro',
          'ICU - Trauma',
          'NICU - Level 2',
          'NICU - Level 3',
          'PEDS CVICU',
          'PICU',
        ];

        const unitIds = data.unitTypes
          .filter(({ name }) => UNIT_LEVEL_REQUIRED_NAMES.includes(name))
          .map(({ id }) => id);
        setUnitLevelRequiredIds(() => unitIds);
      });
  }, []);

  return (
    <FieldArray name="workHistoryEntries">
      {({ push, remove }) => (
        <>
          {values.workHistoryEntries.map((entry, index) =>
            entry.type === historyEntryTypes.WORK_HISTORY ? (
              <HistoryEntry
                key={`workHistoryEntries-key-${entry.data.id || index}`}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                errors={
                  (errors?.workHistoryEntries &&
                    typeof errors.workHistoryEntries !== 'string' &&
                    errors.workHistoryEntries[index]) ||
                  {}
                }
                index={index}
                stateOptions={stateOptions}
                countryOptions={countryOptions}
                values={entry.data}
                unitTypeOptions={unitTypeOptions}
                isViewingSuggestions={isViewingSuggestions}
                hasUnitLevel={hasUnitLevel}
                unitTypeIdsRequiringUnitLevelSelection={
                  unitTypeIdsRequiringUnitLevelSelection
                }
                unitLevelOptions={unitLevelOptions}
                workTypeOptions={workTypeOptions}
                doesWorkTypeRequireAgencyName={doesWorkTypeRequireAgencyName}
                remove={remove}
                isEntryInitiallyOpen={showOpenEntriesIntially}
              />
            ) : (
              <GapEntry
                key={`gapEntries-key-${entry.data.id || index}`}
                index={index}
                reasonOptions={gapReasonOptions}
                isViewingSuggestions={isViewingSuggestions}
                remove={remove}
              />
            )
          )}

          <div className={`field ${sharedProfileStyles.addButton}`}>
            <button
              type="button"
              className={`button is-info is-small ${styles.addEntryButton} `}
              onClick={() => {
                push(
                  getDefaultHistoryEntryValues(
                    profileId,
                    historyEntryTypes.WORK_HISTORY
                  )
                );
              }}
            >
              <span className="icon is-small">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>Add an employer</span>
            </button>

            <button
              type="button"
              className={`button is-info is-small ${styles.addEntryButton} `}
              onClick={() => {
                push(
                  getDefaultHistoryEntryValues(profileId, historyEntryTypes.GAP)
                );
              }}
            >
              <span className="icon is-small">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>Add employment gap</span>
            </button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default WorkHistoryFieldArray;
