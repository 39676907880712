import React from 'react';

import Layout from '../../../../components/layout';
import LicensesAndCerts from '../../../common/components/licenses-and-certs';
import ProgressStepper from '../progress-stepper';
import { useAuth } from '../../../../providers/auth-provider';
import ViewingSuggestionsBanner from '../viewing-suggestions-banner';

const LicensesAndCertsCandidateWrapper = ({ location }) => {
  const { getProfileId } = useAuth();
  const isViewingSuggestions =
    location.state !== null && location.state.isViewingSuggestions === true;

  return (
    <Layout>
      <ViewingSuggestionsBanner isViewingSuggestions={isViewingSuggestions} />
      <ProgressStepper currentStep={5} />
      <LicensesAndCerts
        isViewingSuggestions={isViewingSuggestions}
        profileId={getProfileId()}
      />
    </Layout>
  );
};

export default LicensesAndCertsCandidateWrapper;
