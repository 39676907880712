import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { useAuth } from '../providers/auth-provider';
import { useProfileCompletionApi } from '../services/profile-completion-service';
import { useChecklistExemptionApi } from '../services/checklist-exemption-service';
import { profileSectionsInfo } from '../constants/profile-sections-info';
import { getPrimarySpecialtyFromExpertise } from '../utils/profile-utils';

export function useProfileDashboardApi() {
  const { getProfileCompletionStatuses } = useProfileCompletionApi();
  const { isExemptFromSkillsChecklists } = useChecklistExemptionApi();
  const { get } = useAuthenticatedRequest();
  const { getProfileId } = useAuth();

  const listChecklistSectionOptionalIfExempted = (
    profileCompletionStatuses,
    isExemptFromChecklists
  ) => {
    if (isExemptFromChecklists) {
      profileCompletionStatuses.find(
        section => section.id === profileSectionsInfo.skillsChecklists.id
      ).isOptional = true;
    }
  };

  return {
    getCompletionStatusAndPersonalInfo: function (profileId) {
      return new Promise((resolve, reject) => {
        const completionStatusPromise = getProfileCompletionStatuses(profileId);
        const personalInfoPromise = get(
          `/api/profiles/${profileId}/contact-info`
        );
        const expertisePromise = get(`/api/profiles/${profileId}/expertise`);
        const checklistSpecialtyExemptionPromise =
          isExemptFromSkillsChecklists(profileId);

        Promise.all([
          completionStatusPromise,
          personalInfoPromise,
          expertisePromise,
          checklistSpecialtyExemptionPromise,
        ])
          .then(values => {
            const [
              completionPromise,
              infoPromise,
              expertisePromise,
              checklistSpecialtyExemptionPromise,
            ] = values;
            const profileCompletion = completionPromise;
            const expertise = expertisePromise.data;
            const personalInfo = infoPromise.data;
            const primarySpecialty =
              getPrimarySpecialtyFromExpertise(expertise);
            const isExemptFromSkillsChecklists =
              checklistSpecialtyExemptionPromise.data;
            listChecklistSectionOptionalIfExempted(
              profileCompletion,
              isExemptFromSkillsChecklists
            );
            if (primarySpecialty === null) {
              resolve({
                completionStatus: profileCompletion,
                name: `${personalInfo.firstName} ${personalInfo.lastName}`,
                primarySpecialty: '',
              });
            } else {
              get(`/api/specialties/${primarySpecialty.specialtyId}`)
                .then(({ data }) => {
                  const primaryName = data.name;
                  resolve({
                    completionStatus: profileCompletion,
                    name: `${personalInfo.firstName} ${personalInfo.lastName}`,
                    primarySpecialty: primaryName,
                  });
                })
                .catch(() => {
                  reject();
                });
            }
          })
          .catch(() => {
            reject();
          });
      });
    },

    openTimesheet: function () {
      return new Promise((resolve, reject) => {
        const profileId = getProfileId();
        const newWindowRef = window.open();

        get(`/api/profiles/${profileId}/timesheets-info`)
          .then(({ data }) => {
            const { esigNurseId, token, url } = data;
            let timesheetsUrlWithParams;

            if (esigNurseId) {
              timesheetsUrlWithParams = `${url}?nurseid=${esigNurseId}&token=${token}`;
            } else {
              timesheetsUrlWithParams = `${url}?token=${token}`;
            }

            newWindowRef.location = timesheetsUrlWithParams;
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  };
}
