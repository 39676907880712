import React, { useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import apiService from '../../../../services/api-service';
import { useStatesApi } from '../../../../services/states-service';

import Input from '../forms/formik-inputs/input';
import { months } from '../../../../data/months';
import Select from '../forms/formik-inputs/select';
import sharedProfileStyles from '../profile-form/index.module.scss';
import { yearsDropdownOptions } from './years-dropdown-options';
import { US_ID } from '../../../../constants/personal-info';
import { stateOptionsByCountryId } from '../../../../utils/profile-utils';

const DegreesFieldArray = ({ values }) => {
  const { getAllStatesAsValueNameObjects } = useStatesApi();
  const [stateOptions, setStateOptions] = useState([]);
  const [degreeTypeOptions, setDegreeTypeOptions] = useState([]);

  useEffect(() => {
    getAllStatesAsValueNameObjects().then(states => {
      setStateOptions(states);
    });

    apiService.get('/api/lookup-values/degree-types').then(({ data }) => {
      setDegreeTypeOptions(
        data.map(({ id, name }) => ({ value: id, name: name }))
      );
    });
  }, []);

  return (
    <FieldArray name="degrees">
      {({ push, remove }) => (
        <>
          {values.degrees.map((degree, index) => (
            <div key={index}>
              <hr className={sharedProfileStyles.formDivider} />
              <div className="field">
                <Input
                  name={`degrees[${index}].schoolName`}
                  labelText="School name"
                  labelClass={`is-size-7`}
                  shouldCheckForCapsLock={true}
                />
              </div>
              <div className="field field-body">
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`degrees[${index}].schoolCity`}
                    labelText="City"
                    labelClass={`is-size-7`}
                    shouldCheckForCapsLock={true}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`degrees[${index}].schoolStateId`}
                    labelText="State"
                    options={stateOptionsByCountryId(stateOptions, US_ID)}
                    placeholderText="Select a state"
                    labelClass={`is-size-7`}
                    selectWrapperClass={`is-fullwidth`}
                  />
                </div>
              </div>
              <div className={`field field-body`}>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`degrees[${index}].typeId`}
                    labelText="Degree/Diploma type"
                    labelClass={`is-size-7`}
                    options={degreeTypeOptions}
                    selectWrapperClass={`is-fullwidth`}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`degrees[${index}].areaOfStudy`}
                    labelText="Major"
                    labelClass={`is-size-7`}
                    shouldCheckForCapsLock={true}
                  />
                </div>
              </div>
              <div className="field-body">
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`degrees[${index}].graduationMonth`}
                    labelText="Graduation Month"
                    labelClass={`is-size-7`}
                    selectWrapperClass="is-fullwidth"
                    options={months.map((month, index) => ({
                      value: index + 1,
                      name: month,
                    }))}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`degrees[${index}].graduationYear`}
                    labelText="Graduation Year"
                    labelClass={`is-size-7`}
                    selectWrapperClass="is-fullwidth"
                    options={yearsDropdownOptions}
                  />
                </div>
              </div>

              <div className={`${sharedProfileStyles.removeButtonContainer}`}>
                <button
                  type="button"
                  className="button is-small is-danger is-outlined"
                  onClick={() => remove(index)}
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faMinus} />
                  </span>
                  <span>Remove school</span>
                </button>
              </div>
            </div>
          ))}
          <div className={`field ${sharedProfileStyles.addButton}`}>
            <button
              type="button"
              className="button is-info is-small"
              onClick={() =>
                push({
                  id: null,
                  schoolName: '',
                  schoolCity: '',
                  schoolStateId: '',
                  typeId: '',
                  areaOfStudy: '',
                  graduationMonth: '',
                  graduationYear: '',
                })
              }
            >
              <span className="icon is-small">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>Add a school</span>
            </button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default DegreesFieldArray;
