import React, { useEffect, useState } from 'react';
import { useBackgroundCheckCopyApi } from '../../../../../services/background-check-copy-service';
import Checkbox from '../../../../common/components/forms/formik-inputs/checkbox';

const BackgroundCheckCopyRequestCheckbox = ({
  profileId,
  agreementsData,
  setAgreementsIfAny,
}) => {

  const [wasPreviouslySet, setWasPreviouslySet] = useState();
  const { getHasRequestedBackgroundCheckCopy } = useBackgroundCheckCopyApi();

  useEffect(() => {
    getHasRequestedBackgroundCheckCopy(profileId).then(({ data }) => {
      setWasPreviouslySet(data);
      agreementsData.hasRequestedBackgroundCheckCopy = data;
      setAgreementsIfAny(agreementsData.agreements, agreementsData, agreementsData.eeos);
    });
  }, []);

  return (
    <Checkbox
      name={`hasRequestedBackgroundCheckCopy`}
      labelText={`Check this box if you would like to request a copy of your consumer report 
                  pursuant to the state notices above.`}
      disabled={wasPreviouslySet}
    />
  );
};

export default BackgroundCheckCopyRequestCheckbox;
