import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { Link, navigate } from '@reach/router';

import Input from '../../../common/components/forms/formik-inputs/input';
import Modal from '../../../../components/modal';
import { useEmailApi } from '../../../../services/email-service';
import { useAuth } from '../../../../providers/auth-provider';
import validationSchema from './validation-schema';
import styles from './index.module.scss';
import Layout from '../../../../components/layout';
import PopupNotification from '../../../../components/popup-notification';
import { useAppContext } from '../../../../providers/app-provider';
import { CAPS_LOCK_WARNING } from '../../../../constants/misc-client-messges';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../../constants/error-messages';
import { httpResponses } from '../../../../constants/http-responses';

const ChangeEmail = () => {
  const { loggedIn } = useAuth();
  const { requestEmailChange } = useEmailApi();
  const { isCapsLockActive } = useAppContext();
  const { getLoginPathBasedOnTalentStackActiveFlag } = useAuth();
  const resetForm = actions => {
    actions.setValues({
      newEmail: '',
      pwd: '',
    });
    actions.setTouched({});
  };

  const getErrorMessageAddendum = errorText =>
    errorText === errorMessages.ACCOUNT_BLOCKED ? (
      <>
        Too many incorrect login attempts have been detected for this account.
        Please <Link to="/profile/forgot-password/">reset your password</Link>{' '}
        to restore access to this account.
      </>
    ) : undefined;

  const handleRequestSuccess = actions => {
    actions.setSubmitting(false);
    actions.setStatus({
      apiSuccess: true,
    });
    resetForm(actions);
  };

  const handleRequestFailure = (apiError, actions) => {
    const errorMessage =
      apiError?.response?.status === httpResponses.UNAUTHORIZED
        ? apiError.response.data
        : 'There was a problem changing your email.';
    actions.setSubmitting(false);
    actions.setStatus({
      apiError: errorMessage,
    });
  };

  useEffect(() => {
    if (loggedIn === false) {
      navigate(getLoginPathBasedOnTalentStackActiveFlag());
    }
  }, [loggedIn]);

  return (
    <Layout>
      <section className={`${styles.formSection} columns`}>
        <div
          className={`${styles.formContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <div className={`${styles.formContent}`}>
            <h1
              className={`${styles.sectionTitle} title is-4 has-text-white has-text-centered`}
            >
              Change email address
            </h1>
            <p className={`subtitle is-6 has-text-centered has-text-white`}>
              Please enter your new email address and password. We will send an
              email to your new email address to verify the change.
            </p>

            <Formik
              initialValues={{
                newEmail: '',
                pwd: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                requestEmailChange(values)
                  .then(() => {
                    handleRequestSuccess(actions);
                  })
                  .catch(err => handleRequestFailure(err, actions));
              }}
            >
              {({ isSubmitting, status, setStatus }) => (
                <Form>
                  <div className="field">
                    <Input
                      name={`newEmail`}
                      labelText="New email address"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="email"
                      shouldCheckForCapsLock={true}
                    />
                  </div>

                  <div className="field">
                    <Input
                      type="password"
                      name={`pwd`}
                      labelText="Verify password"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="current-password"
                      shouldCheckForCapsLock={true}
                    />
                  </div>

                  <Modal
                    title="Verification Email Sent"
                    message={
                      <>
                        You should receive an email at your new address shortly.
                        <br />
                        <br />
                        Not seeing it? Be sure to check your spam folder. If you
                        still can't find it, try again or{' '}
                        <a href="/contact-us">contact us</a>.
                      </>
                    }
                    isOpen={status && status.apiSuccess ? true : false}
                    confirmButtonText={'Got it'}
                    confirmButtonModifierClass={`is-info`}
                    onConfirm={() => navigate('/profile/')}
                    onCancel={() => setStatus({ apiSuccess: false })}
                    messageModifierClass={`is-info`}
                  />
                  {status && status.apiError && (
                    <ApiErrorMessage
                      errorMsg={status.apiError}
                      errorAddendum={getErrorMessageAddendum(status.apiError)}
                    />
                  )}

                  <div className="has-text-centered">
                    <button
                      type="submit"
                      className={`button is-primary ${styles.nextButton} ${
                        isSubmitting ? 'is-loading' : ''
                      }`}
                      disabled={isSubmitting}
                    >
                      <span>Send Verification Email</span>
                      <span className="icon ">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {isCapsLockActive && (
          <PopupNotification
            message={CAPS_LOCK_WARNING}
            duration="persistent"
            showCloseIcon={false}
            notificationStyleClass="is-danger"
          />
        )}
      </section>
    </Layout>
  );
};

export default ChangeEmail;
