import {
  getObjectWithNullPropertiesSetToEmptyStrings,
  getObjectWithEmptyStringPropertiesSetToNull,
} from '../utils/api-utils';
import { getProfileApiUrl, getSuggestionsApiUrl } from '../utils/url-helpers';
import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { documentTypeIds } from '../constants/document-types';
import { useDocumentsApi } from './documents-service';
import { hasMailAddress } from '../utils/profile-utils';

const FILE_UPLOADED_DEFAULT_TEXT = 'File uploaded';
const SECTION_URL_SLUG = 'contact-info';

export function usePersonalInfoApi() {
  const { get, post, put } = useAuthenticatedRequest();
  const { uploadDocument } = useDocumentsApi();

  const formatSuffixOrGetDefault = suffix => {
    if (!suffix) return 'None';
    if (suffix.match(/^[V?I{0,3}]/)) return suffix.toUpperCase();

    return suffix;
  };

  const trimOrReturnNullIfInvalid = str => {
    if (str === undefined || str === null) return null;

    return str.trim();
  };

  const reformatContactSubmissionData = contactData => {
    const formatted = getObjectWithEmptyStringPropertiesSetToNull(contactData);
    formatted.firstName = formatted.firstName.trim();
    formatted.lastName = formatted.lastName.trim();

    formatted.middleName = trimOrReturnNullIfInvalid(formatted.middleName);

    formatted.preferredName = trimOrReturnNullIfInvalid(
      formatted.preferredName
    );

    formatted.streetAddress2 = trimOrReturnNullIfInvalid(
      formatted.streetAddress2
    );

    // this is not stored in db, so if user is submitting
    // and it's checked, force values to be same
    if (formatted.mailAddressIsSame) {
      formatted.mailStreetAddress = formatted.streetAddress;
      formatted.mailStreetAddress2 = formatted.streetAddress2;
      formatted.mailCity = formatted.city;
      formatted.mailStateId = formatted.stateId;
      formatted.mailZipCode = formatted.zipCode;
    }

    return formatted;
  };

  return {
    getPersonalInfo: function (profileId, isViewingSuggestions) {
      const requestUrl = isViewingSuggestions
        ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
        : getProfileApiUrl(profileId, SECTION_URL_SLUG);

      return new Promise((resolve, reject) => {
        get(requestUrl)
          .then(({ data }) => {
            const {
              hasUploadedDriversLicense,
              middleName,
              suffixName,
              streetAddress2,
              ...restObj
            } = data;

            let reformattedItem =
              getObjectWithNullPropertiesSetToEmptyStrings(restObj);

            // If files have been uploaded return text to show in FileInput
            reformattedItem.driversLicenseImage = hasUploadedDriversLicense
              ? FILE_UPLOADED_DEFAULT_TEXT
              : '';
            reformattedItem.suffixName = formatSuffixOrGetDefault(suffixName);

            const extraDefaults = {
              ...reformattedItem,
              middleName: middleName || ' ',
              streetAddress2: streetAddress2 || ' ',
              mailAddressIsSame: !hasMailAddress(reformattedItem),
              hasCompletedLegalName: suffixName !== null,
            };

            resolve(extraDefaults);
          })
          .catch(error => reject(error));
      });
    },

    updatePersonalInfo: function (
      profileId,
      data,
      driversLicenseFile,
      isSuggestion
    ) {
      const { driversLicenseImage, ...restObj } = data;
      const reformattedContactData = reformatContactSubmissionData(restObj);

      const requestFunction = isSuggestion ? post : put;

      return new Promise((resolve, reject) => {
        requestFunction(
          isSuggestion
            ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
            : getProfileApiUrl(profileId, SECTION_URL_SLUG),
          reformattedContactData
        )
          .then(contactResponse => {
            if (driversLicenseFile !== null) {
              uploadDocument(
                profileId,
                driversLicenseFile,
                documentTypeIds.DRIVERS_LICENSE
              )
                .then(imageResponse => {
                  resolve({
                    contactResponse: contactResponse,
                    imageResponse: imageResponse,
                  });
                })
                .catch(imageErr => reject(imageErr));
            } else {
              resolve(contactResponse);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  };
}
