import React, { useState, useEffect } from 'react';
import Layout from '../../../../components/layout';
import { useAuth } from '../../../../providers/auth-provider';
import sharedProfileStyles from '../../../common/components/profile-form/index.module.scss';
import styles from './index.module.scss';
import CompletionStatusField from '../../../../components/profile-completion-status-field';
import { Link as GatsbyLink } from 'gatsby';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../../components/loading-spinner';
import { useProfileDashboardApi } from '../../../../services/profile-dashboard-service';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../../constants/error-messages';
import PopupNotification from '../../../../components/popup-notification';

const Profile = () => {
  const { getProfileId } = useAuth();
  const DEFAULT_NEXT_SECTION = {
    name: 'My Expertise',
    path: '/profile/expertise/',
    hasRecruiterFeedback: false,
    isComplete: true,
  };

  const profileId = getProfileId();
  const { getCompletionStatusAndPersonalInfo } = useProfileDashboardApi();
  const { openTimesheet } = useProfileDashboardApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isTimesheetLoading, setTimesheetLoading] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [isDisplayingAlertMessage, setIsDisplayingAlertMessage] =
    useState(false);
  const [values, setValues] = useState({
    primarySpecialty: '',
    name: '',
    profileCompletionStatuses: [
      {
        name: '',
        hasRecruiterFeedback: false,
        isComplete: false,
        path: '',
        disabled: false,
      },
    ],
  });

  useEffect(() => {
    getCompletionStatusAndPersonalInfo(profileId)
      .then(({ primarySpecialty, name, completionStatus }) => {
        setValues({
          primarySpecialty: primarySpecialty,
          name: name,
          profileCompletionStatuses: completionStatus,
        });
      })
      .catch(() => {
        setHasFetchError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getFirstUnfinishedSection = () => {
    const unfinished = values.profileCompletionStatuses.find(
      item => item.isComplete !== true
    );

    return unfinished !== undefined ? unfinished : DEFAULT_NEXT_SECTION;
  };

  const areAnySectionsUnfinished = () => {
    return values.profileCompletionStatuses.some(
      item => item.isComplete !== true
    );
  };

  const loadTimesheet = () => {
    setTimesheetLoading(true);
    openTimesheet()
      .catch(() => {
        setIsDisplayingAlertMessage(true);
      })
      .finally(() => setTimesheetLoading(false));
  };

  return (
    <Layout>
      <section className={`${styles.contentSection} columns`}>
        <div
          className={`${styles.contentContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            Profile Dashboard
          </h1>
          {hasFetchError ? (
            <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
          ) : isLoading ? (
            <LoadingSpinner isLoading={isLoading} />
          ) : (
            <>
              <p className={`subtitle is-6 has-text-centered-mobile`}>
                {values.name}
                {values.primarySpecialty === ''
                  ? ''
                  : ` - ${values.primarySpecialty}`}
              </p>
              <hr className={sharedProfileStyles.formDivider} />
              <p className="has-text-centered-mobile">
                Sections marked in
                <span className={styles.coloredText}> orange</span> are next on
                your task list. Click the name of any section to edit.
              </p>
              <br />
              <p
                className={`has-text-weight-bold is-italic has-text-centered-mobile`}
              >
                Having a complete profile allows you to apply to jobs with just
                the click of a single button!
              </p>
              <hr className={sharedProfileStyles.formDivider} />
              <div className={`${styles.fieldArrayContainer}`}>
                {values.profileCompletionStatuses.map((item, index) => (
                  <div className={styles.statusItem} key={index}>
                    <CompletionStatusField
                      name={item.name}
                      isComplete={item.isComplete}
                      hasRecruiterFeedback={item.hasRecruiterFeedback}
                      path={item.path}
                      disabled={item.disabled}
                      isOptional={item.isOptional}
                    />
                    {item.hasRecruiterFeedback && (
                      <Link
                        className="button is-primary"
                        to={item.path}
                        state={{ isViewingSuggestions: true }}
                      >
                        View suggestions
                      </Link>
                    )}
                  </div>
                ))}
                <hr className={`${sharedProfileStyles.formDivider}`} />
                <div className="has-text-centered-mobile">
                  <div className="has-text-centered-mobile">
                    <Link
                      to={getFirstUnfinishedSection().path}
                      state={{
                        isViewingSuggestions:
                          getFirstUnfinishedSection().hasRecruiterFeedback,
                      }}
                    >
                      <button className={`${styles.button} button is-primary`}>
                        <span>
                          {areAnySectionsUnfinished()
                            ? 'Finish my app'
                            : 'Edit my app'}
                        </span>
                        <span className="icon ">
                          <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                      </button>
                    </Link>
                  </div>
                  <p className={`${styles.notesText} has-text-centered-mobile`}>
                    Please note: We cannot submit you to the job(s) of your
                    choice until all required sections of your profile are
                    complete. Speak to your recruiter for assistance.
                  </p>
                  <hr className={`${sharedProfileStyles.formDivider}`} />
                  <div className="has-text-centered-mobile">
                    <GatsbyLink to="/jobs/">
                      <button className={`${styles.button} button is-info`}>
                        Search jobs
                      </button>
                    </GatsbyLink>
                    <button
                      className={`${styles.button} button is-info ${
                        isTimesheetLoading ? 'is-loading' : null
                      } `}
                      onClick={loadTimesheet}
                    >
                      Timesheets
                    </button>
                    <Link to="/profile/resources/">
                      <button className={`${styles.button} button is-info`}>
                        Resources
                      </button>
                    </Link>
                    <Link to="/profile/documents/">
                      <button className={`${styles.button} button is-info`}>
                        Documents
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {isDisplayingAlertMessage && (
          <PopupNotification
            message={errorMessages.REQUEST_FAILED}
            duration="medium"
            onClose={() => setIsDisplayingAlertMessage(false)}
            notificationStyleClass="is-danger"
          />
        )}
      </section>
    </Layout>
  );
};

export default Profile;
