import React from 'react';

import Education from '../../../common/components/education';
import Layout from '../../../../components/layout';
import ProgressStepper from '../progress-stepper';
import { useAuth } from '../../../../providers/auth-provider';
import ViewingSuggestionsBanner from '../viewing-suggestions-banner';

const EducationCandidateWrapper = ({ location }) => {
  const { getProfileId } = useAuth();
  const isViewingSuggestions =
    location.state !== null && location.state.isViewingSuggestions === true;

  return (
    <Layout>
      <ViewingSuggestionsBanner isViewingSuggestions={isViewingSuggestions} />
      <ProgressStepper currentStep={2} />
      <Education
        profileId={getProfileId()}
        isViewingSuggestions={isViewingSuggestions}
      />
    </Layout>
  );
};

export default EducationCandidateWrapper;
