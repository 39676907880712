import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import { CALIFORNIA_MEAL_PLAN_OPTIONS } from '../../../../constants/california-meal-plan-options';
import { errorMessages } from '../../../../constants/error-messages';
import Input from '../../../common/components/forms/formik-inputs/input';
import Layout from '../../../../components/layout';
import LoadingSpinner from '../../../../components/loading-spinner';
import Modal from '../../../../components/modal';
import Select from '../../../common/components/forms/formik-inputs/select';
import Textarea from '../../../common/components/forms/formik-inputs/textarea';
import TelephoneInput from '../../../common/components/forms/formik-inputs/telephone-input';
import PopupNotification from '../../../../components/popup-notification';
import { useAgreementsApi } from '../../../../services/agreements-service';
import { useAuth } from '../../../../providers/auth-provider';
import validationSchema from './validation-schema';
import sharedProfileStyles from '../../../common/components/profile-form/index.module.scss';
import styles from './index.module.scss';
import { getFormattedDate } from '../../../../utils/format-helpers';
import { CAPS_LOCK_WARNING } from '../../../../constants/misc-client-messges';
import { useAppContext } from '../../../../providers/app-provider';

const AcceptanceAgreement = ({ documentId }) => {
  const { getProfileId } = useAuth();
  const { isCapsLockActive } = useAppContext();
  const profileId = getProfileId();

  const [agreementData, setAgreementData] = useState({
    agreement: {
      emergencyContacts: [
        { name: '', phone: '' },
        { name: '', phone: '' },
      ],
      text: '',
      isCalifornia: false,
      californiaMealPlanOption: '',
      profileId: profileId,
      isSigned: false,
      signedDate: '',
    },
  });
  const [hasFetchError, setHasFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { getAcceptanceAgreement, signAcceptanceAgreement } =
    useAgreementsApi();

  useEffect(() => {
    getAcceptanceAgreement(documentId)
      .then(({ data }) => {
        setAgreementData({
          agreement: { ...agreementData.agreement, ...data },
        });
      })
      .catch(() => {
        setHasFetchError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Layout>
      <div className={`columns is-centered ${styles.section}`}>
        <section className={'column is-10-tablet is-10-desktop is-centered'}>
          <LoadingSpinner isLoading={isLoading} />

          {hasFetchError && (
            <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
          )}

          <Formik
            initialValues={agreementData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              signAcceptanceAgreement(documentId, values.agreement)
                .then(() => {
                  actions.setStatus({
                    apiSuccess: true,
                  });
                })
                .catch(() => {
                  actions.setSubmitting(false);
                  actions.setStatus({
                    apiError: true,
                  });
                });
            }}
          >
            {({ values, isSubmitting, status, errors, submitCount }) => (
              <Form>
                {values.agreement.text.length !== 0 && (
                  <>
                    <div className="field">
                      <Textarea
                        name={`agreement.text`}
                        labelText={values.agreement.name}
                        labelClass={`${styles.agreement} title is-3 has-text-info has-text-centered-mobile`}
                        readOnly
                        rows={20}
                      />
                    </div>

                    {values.agreement.isSigned ? (
                      <p className={`${styles.signedDate}`}>
                        Signed on{' '}
                        {getFormattedDate(values.agreement.signedDate)}
                      </p>
                    ) : (
                      <>
                        <p
                          className={`${styles.emergencyContacts} title is-5 has-text-centered`}
                        >
                          Emergency Contacts
                        </p>

                        {values.agreement.emergencyContacts.map(
                          (contact, index) => (
                            <div className="field field-body" key={index}>
                              <div
                                className={`field ${sharedProfileStyles.groupedFormField}`}
                              >
                                <Input
                                  name={`agreement.emergencyContacts[${index}].name`}
                                  labelText="Name"
                                  labelClass={`is-size-7`}
                                  shouldCheckForCapsLock={true}
                                />
                              </div>
                              <div
                                className={`field ${sharedProfileStyles.groupedFormField}`}
                              >
                                <TelephoneInput
                                  name={`agreement.emergencyContacts[${index}]].phone`}
                                  labelText="Phone"
                                  labelClass={`is-size-7`}
                                />
                              </div>
                            </div>
                          )
                        )}

                        {values.agreement.isCalifornia && (
                          <div
                            className={`field ${sharedProfileStyles.groupedFormField}`}
                          >
                            <Select
                              name={`agreement.californiaMealPlanOption`}
                              labelText="California Meal Plan Option (See EXHIBIT B for more information)"
                              options={[
                                {
                                  name: 'Option 1',
                                  value: CALIFORNIA_MEAL_PLAN_OPTIONS.ONE,
                                },
                                {
                                  name: 'Option 2',
                                  value: CALIFORNIA_MEAL_PLAN_OPTIONS.TWO,
                                },
                              ]}
                              labelClass={`is-size-7`}
                              selectWrapperClass={`is-fullwidth`}
                            />
                          </div>
                        )}

                        {errors &&
                        Object.keys(errors).length !== 0 &&
                        submitCount !== 0 ? (
                          <p className="notification is-danger">
                            There are errors in your submission. Please review.
                          </p>
                        ) : null}

                        {status && status.apiError ? (
                          <ApiErrorMessage
                            errorMsg={errorMessages.UNABLE_TO_SAVE}
                          />
                        ) : null}

                        <button
                          type="submit"
                          className={`button is-primary ${
                            isSubmitting ? 'is-loading' : ''
                          }`}
                          disabled={isSubmitting}
                        >
                          <span className="icon ">
                            <FontAwesomeIcon icon={faEdit} />
                          </span>
                          <span>Sign Contract</span>
                        </button>
                      </>
                    )}
                  </>
                )}

                <Modal
                  title="Acceptance Agreement Signed!"
                  message={
                    <>
                      Thank you for signing your acceptance agreement.
                      <br />
                      <br />
                      You may close this tab or return to Trustaff.com.
                    </>
                  }
                  isOpen={status && status.apiSuccess === true}
                  confirmButtonText={'Back to Trustaff.com'}
                  confirmButtonModifierClass={`is-info`}
                  onConfirm={() => navigate('/')}
                  onCancel={() => navigate('/')}
                  messageModifierClass={`is-info`}
                />
              </Form>
            )}
          </Formik>
          {isCapsLockActive && (
            <PopupNotification
              message={CAPS_LOCK_WARNING}
              showCloseIcon={false}
              duration="persistent"
              notificationStyleClass="is-danger"
            />
          )}
        </section>
      </div>
    </Layout>
  );
};

export default AcceptanceAgreement;
