/*
 *  Utilitiy functions used when interacting with Back End API
 *  For converting incoming data to format expected by Formik and
 *   outgoing data to format expected by API
 */

export function getObjectWithNullPropertiesSetToEmptyStrings(obj) {
  const reformattedObj = { ...obj };

  for (const prop in reformattedObj) {
    reformattedObj[prop] =
      reformattedObj[prop] === null ? '' : reformattedObj[prop];
  }

  return reformattedObj;
}

export function getObjectWithEmptyStringPropertiesSetToNull(obj) {
  const reformattedObj = { ...obj };

  for (const prop in reformattedObj) {
    reformattedObj[prop] =
      reformattedObj[prop] === '' ? null : reformattedObj[prop];
  }

  return reformattedObj;
}

export function getMonthsFromMonthsAndYears(years, months) {
  years = !years || years.length === 0 ? 0 : parseInt(years);
  months = !months || months.length === 0 ? 0 : parseInt(months);
  return years * 12 + months;
}
