import {
  getObjectWithNullPropertiesSetToEmptyStrings,
  getObjectWithEmptyStringPropertiesSetToNull,
} from '../utils/api-utils';
import { getProfileApiUrl, getSuggestionsApiUrl } from '../utils/url-helpers';
import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { historyEntryTypes } from '../constants/default-history-entry-values';

const SECTION_URL_SLUG = 'work-history';

export function useWorkHistoryApi(profileId) {
  const { get, post, put } = useAuthenticatedRequest();

  const findPrimaryUnitLevelId = unitTypes => {
    const unitTypeWithLevel = unitTypes?.find(u => u.unitLevelId !== null);

    return unitTypeWithLevel ? unitTypeWithLevel.unitLevelId : '';
  };

  const combineHistoryEntries = (workHistoryEntries, gapEntries) => {
    const workHistoryEntriesWithTypes = workHistoryEntries.map(entry => ({
      data: entry,
      type: historyEntryTypes.WORK_HISTORY,
    }));

    const gapEntriesWithTypes = gapEntries.map(entry => ({
      data: entry,
      type: historyEntryTypes.GAP,
    }));

    let combinedEntries = [
      ...workHistoryEntriesWithTypes,
      ...gapEntriesWithTypes,
    ];

    combinedEntries.sort(function (a, b) {
      return new Date(b.data.startDate) - new Date(a.data.startDate);
    });

    return { workHistoryEntries: combinedEntries };
  };

  const formatHistoryForUpdate = (
    profileId,
    historyEntry,
    workTypeIdsThatRequireAgencyName,
    unitLevelRequiredIds
  ) => {
    let reformattedHistoryEntry =
      getObjectWithEmptyStringPropertiesSetToNull(historyEntry);
    if (historyEntry.isCurrentlyEmployed) {
      reformattedHistoryEntry = {
        ...reformattedHistoryEntry,
        reasonLeftId: null,
      };
    }
    const unitTypes = reformattedHistoryEntry.unitTypeIds.map(unitTypeId => {
      const reformattedUnitType = {
        unitTypeId: unitTypeId,
        unitLevelId: unitLevelRequiredIds.includes(parseInt(unitTypeId))
          ? reformattedHistoryEntry.primaryUnitLevelId
          : null,
      };

      if (reformattedHistoryEntry.id) {
        reformattedUnitType.employmentHistoryEntryId =
          reformattedHistoryEntry.id;
      }
      return reformattedUnitType;
    });

    // backend uses an array for floatUnitTypes
    const { floatUnitTypes: floatUnitId } = reformattedHistoryEntry;
    const floatUnit = floatUnitId
      ? [
          {
            unitTypeId: floatUnitId,
            unitLevelId: null,
            employmentHistoryEntryId: reformattedHistoryEntry.id,
          },
        ]
      : null;

    const { unitTypeIds, primaryUnitLevelId, ...restObj } =
      reformattedHistoryEntry;

    return {
      ...restObj,
      profileId: profileId,
      unitTypes: unitTypes,
      floatUnitTypes: floatUnit,
      travelAgencyName: workTypeIdsThatRequireAgencyName.includes(
        parseInt(reformattedHistoryEntry.workTypeId, 10)
      )
        ? reformattedHistoryEntry.travelAgencyName
        : null,
    };
  };

  return {
    getWorkHistory: function (profileId, isViewingSuggestions) {
      const workHistoryUrl = isViewingSuggestions
        ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
        : getProfileApiUrl(profileId, SECTION_URL_SLUG);

      return get(workHistoryUrl).then(({ data }) => {
        const reformattedWorkHistory = {
          workHistoryEntries: data.entries.map(historyEntry => {
            const unitTypeIds = historyEntry.unitTypes.map(
              unitType => unitType.unitTypeId
            );
            // frontend uses a single value for floatUnitTypes but
            // backend returns an array, so extract the value
            const { floatUnitTypes } = historyEntry;
            const floatUnitId = floatUnitTypes.length
              ? floatUnitTypes[0].unitTypeId
              : '';
            const primaryUnitLevelId = findPrimaryUnitLevelId(
              historyEntry.unitTypes
            );

            const { unitTypes, ...restObj } = historyEntry;
            const historyEntryWithHistoricNulls = {
              ...restObj,
              ...(restObj.hoursPerWeek === null || restObj.professionId === null
                ? { hasSubmittedPreviouslyAsNull: true }
                : {}),
            };
            const reformattedHistoryEntry =
              getObjectWithNullPropertiesSetToEmptyStrings(
                historyEntryWithHistoricNulls
              );

            return {
              ...reformattedHistoryEntry,
              unitTypeIds: unitTypeIds,
              floatUnitTypes: floatUnitId,
              primaryUnitLevelId: primaryUnitLevelId,
            };
          }),
        };

        return combineHistoryEntries(
          reformattedWorkHistory.workHistoryEntries,
          data.employmentGaps
        );
      });
    },
    updateWorkHistory: function (
      profileId,
      data,
      workTypeIdsThatRequireAgencyName,
      unitLevelRequiredIds,
      isSuggestion
    ) {
      const workHistoryEntries = data.filter(
        entry => entry.type === historyEntryTypes.WORK_HISTORY
      );
      const gapEntries = data.filter(
        entry => entry.type === historyEntryTypes.GAP
      );

      const reformattedWorkHistory = workHistoryEntries.map(entry => {
        return formatHistoryForUpdate(
          profileId,
          entry.data,
          workTypeIdsThatRequireAgencyName,
          unitLevelRequiredIds
        );
      });

      const reformattedGapEntries = gapEntries.map(entry => {
        return { ...entry.data, profileId: profileId };
      });

      const requestFunction = isSuggestion ? post : put;

      return requestFunction(
        isSuggestion
          ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
          : getProfileApiUrl(profileId, SECTION_URL_SLUG),
        {
          employmentGaps: reformattedGapEntries,
          entries: reformattedWorkHistory,
        }
      );
    },
  };
}
