import React from 'react';
import Input from './input';

const SsnInput = props => {
  return (
    <Input
      autoComplete="off"
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      {...props}
    />
  );
};

export default SsnInput;
