import React, { useEffect, useState, useRef } from 'react';
import { isMobile } from '../../../../../utils/browser-detection-helpers';
import styles from './multiselect.module.scss';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, FieldArray } from 'formik';
import Select from './select';
import PropTypes from 'prop-types';
import DeletableTag from './deletable-tag';

const Multiselect = ({
  values,
  options,
  name,
  placeholderText,
  labelText,
  labelClasses,
  dropdownClasses,
  mobileSelectClasses,
  isViewingSuggestions,
}) => {
  const [showMobileInput, setShowMobileInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const ENTER_KEY_CODE = 13;
  const ESCAPE_KEY_CODE = 27;
  const MULTISELECT_ELEMENT_KEY_NAME = 'multiselect-dropdown';
  const dropdownContainerRef = useRef(null);
  const DELETE_TAG_IDENTIFIER_STRING = 'deletable-tag-identifier';

  const isClickOnDeletableTagDeleteIcon = event => {
    return event.target.id.startsWith(DELETE_TAG_IDENTIFIER_STRING);
  };

  const handleClick = event => {
    const isClickWithinDropdown = dropdownContainerRef?.current?.contains(
      event.target
    );
    if (isClickWithinDropdown && isViewingSuggestions) {
      event.preventDefault();
    } else if (
      !isClickWithinDropdown &&
      !isClickOnDeletableTagDeleteIcon(event)
    ) {
      setShowDropdown(false);
    }
  };

  const handleDropdownButtonClick = event => {
    if (isClickOnDeletableTagDeleteIcon(event) || isViewingSuggestions) {
      return;
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  const isOptionSelected = id => values.includes(id);

  const handleChange = (formikAddFunction, formikDeleteFunction, id) => {
    if (!isOptionSelected(id)) {
      formikAddFunction(id);
    } else {
      handleDelete(formikDeleteFunction, id);
    }
  };

  const handleDelete = (formikDeleteFunction, id) => {
    const idx = values.indexOf(id);
    formikDeleteFunction(idx);
  };

  const getOptionText = id => {
    return options.find(option => option.value === id)?.name;
  };

  const handleKeydown = event => {
    const isEnterOrEscapeKeydown =
      event.keyCode === ENTER_KEY_CODE || event.keyCode === ESCAPE_KEY_CODE;
    const isKeydownWithinDropdown = event?.path?.some(element => {
      return element?.id === MULTISELECT_ELEMENT_KEY_NAME;
    });

    if (isEnterOrEscapeKeydown && isKeydownWithinDropdown) {
      event.preventDefault();
      setShowDropdown(false);
    }
  };

  const getSelectionsSortedAlphabetically = values => {
    const matches = values.map(value =>
      options.find(option => option.value === parseInt(value, 10))
    );
    const sortedMatches = matches.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    const sortedIds = sortedMatches.map(match => match.value);
    return sortedIds;
  };

  useEffect(() => {
    setShowMobileInput(isMobile());
    if (!showMobileInput) {
      window.addEventListener('mousedown', handleClick);
      window.addEventListener('keydown', handleKeydown);
      return () => {
        window.removeEventListener('mousedown', handleClick);
        window.removeEventListener('keydown', handleKeydown);
      };
    }
  }, []);

  return (
    <>
      {showMobileInput ? (
        <Select
          name={name}
          labelText={labelText}
          labelClass={labelClasses}
          selectWrapperClass={mobileSelectClasses}
          options={options}
          isMultiple
        />
      ) : (
        <>
          <label className={`label ${labelClasses}`}>{labelText}</label>
          <div
            id={MULTISELECT_ELEMENT_KEY_NAME}
            ref={dropdownContainerRef}
            className={`field dropdown ${
              styles.fullDropdownComponentContainer
            } ${dropdownClasses}  ${showDropdown ? 'is-active' : ''}`}
          >
            <FieldArray name={name} className={`control`}>
              {({ push, remove }) => {
                return (
                  <>
                    <div
                      className={`dropdown-trigger ${styles.openButtonContainer}`}
                    >
                      <button
                        className={`button is-rounded  ${styles.openButton}`}
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                        onClick={event => handleDropdownButtonClick(event)}
                      >
                        <div
                          className={`has-text-left ${styles.openButtonContentContainer}`}
                        >
                          {values.length && options.length ? (
                            getSelectionsSortedAlphabetically(values).map(
                              selectedValue => (
                                <DeletableTag
                                  key={`${DELETE_TAG_IDENTIFIER_STRING}-${selectedValue}`}
                                  value={selectedValue}
                                  text={getOptionText(selectedValue)}
                                  identifierString={
                                    DELETE_TAG_IDENTIFIER_STRING
                                  }
                                  onDelete={() =>
                                    handleDelete(remove, selectedValue)
                                  }
                                  isViewingSuggestions={isViewingSuggestions}
                                />
                              )
                            )
                          ) : (
                            <span className="has-text-grey-light">
                              {placeholderText}
                            </span>
                          )}
                        </div>
                        <span className={`icon has-text-info`}>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </button>
                    </div>
                    <div
                      className={`dropdown-menu ${styles.dropdownContentContainer}`}
                      id="dropdown-menu"
                      role="menu"
                    >
                      <div
                        className={`dropdown-content ${styles.dropdownContent}`}
                      >
                        <div>
                          {options.map(option => {
                            return (
                              <div
                                className={`dropdown-item`}
                                key={option.value}
                              >
                                <label
                                  className={`is-size-6 ${styles.dropdownLineItem}`}
                                >
                                  <input
                                    type="checkbox"
                                    name={name}
                                    value={option.value}
                                    checked={isOptionSelected(option.value)}
                                    onChange={() =>
                                      handleChange(push, remove, option.value)
                                    }
                                  />
                                  <span className={styles.dropdownOptionName}>
                                    {option.name}
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            </FieldArray>
          </div>
          <ErrorMessage name={name}>
            {message => <p className="help has-text-danger">{message}</p>}
          </ErrorMessage>
        </>
      )}
    </>
  );
};

export default Multiselect;

Multiselect.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelClasses: PropTypes.string,
  dropdownClasses: PropTypes.string,
  mobileSelectClasses: PropTypes.string,
};

Multiselect.defaultProps = {
  placeholderText: 'Select all that apply',
  labelClasses: '',
  dropdownClasses: '',
  mobileSelectClasses: '',
};
