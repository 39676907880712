import * as Yup from 'yup';
import {
  telephoneRegex,
  zipCodeRegex,
  ssnRegex,
} from '../../../../utils/validation-utils';
import { US_ID } from '../../../../constants/personal-info';

const NO_SELECTION_MESSAGE = 'Please select an option';
const NO_DATE_SELECTED = 'Please select a date';
const DATE_IN_FUTURE = 'Please select a date in past';
const todayDateString = new Date().toISOString();

function getRequiredMessage(fieldName) {
  return `Please enter ${/^[aeiou]/.test(fieldName) ? 'an' : 'a'} ${fieldName}`;
}

const validationSchema = isSuggestion => {
  return Yup.object({
    suffixName: Yup.string().required(getRequiredMessage('suffix')),
    firstName: Yup.string().required(getRequiredMessage('first name')),
    middleName: Yup.string(),
    lastName: Yup.string().required(getRequiredMessage('last name')),
    preferredName: Yup.string(),
    phoneNumber: Yup.string()
      .required(getRequiredMessage('phone number'))
      .matches(telephoneRegex, 'Please enter a valid phone number'),
    otherPhoneNumber: Yup.string().matches(
      telephoneRegex,
      'Please enter a valid phone number'
    ),
    preferredInterviewTime: Yup.string(),
    email: Yup.string()
      .email('Please enter a valid email')
      .required(getRequiredMessage('email')),
    streetAddress: Yup.string().required(getRequiredMessage('street address')),
    streetAddress2: Yup.string(),
    city: Yup.string().required(getRequiredMessage('city')),
    stateId: Yup.number().required(NO_SELECTION_MESSAGE),
    zipCode: Yup.string()
      .required(getRequiredMessage('zip code'))
      .when('country', {
        is: v => v === US_ID || v === String(US_ID),
        then: Yup.string().matches(
          zipCodeRegex,
          'Please enter a valid zip code'
        ),
      }),
    dateOfBirth:
      !isSuggestion &&
      Yup.date()
        .typeError(NO_DATE_SELECTED)
        .max(todayDateString, DATE_IN_FUTURE)
        .required(NO_DATE_SELECTED),
    driversLicenseNumber:
      !isSuggestion &&
      Yup.string().required(getRequiredMessage('license number')),
    driversLicenseStateIssuedId:
      !isSuggestion && Yup.number().required(NO_SELECTION_MESSAGE),
    driversLicenseImage:
      !isSuggestion && Yup.string().required('Please upload a drivers license'),
    ssn:
      !isSuggestion &&
      Yup.string()
        .matches(ssnRegex, 'Please enter a valid social security number')
        .required('Please enter a valid social security number'),
    mailAddressIsSame: Yup.bool(),
    mailStreetAddress: Yup.string().when('mailAddressIsSame', {
      is: false,
      then: Yup.string().required(getRequiredMessage('address 1')),
    }),
    mailStreetAddress2: Yup.string().when('mailAddressIsSame', {
      is: false,
      then: Yup.string(),
    }),
    mailCity: Yup.string().when('mailAddressIsSame', {
      is: false,
      then: Yup.string().required(getRequiredMessage('city')),
    }),
    mailStateId: Yup.number().when('mailAddressIsSame', {
      is: false,
      then: Yup.number().when('mailCountry', {
        is: v => v === US_ID || v === String(US_ID),
        then: Yup.number().required(NO_SELECTION_MESSAGE),
      }),
    }),
    mailZipCode: Yup.string().when('mailAddressIsSame', {
      is: false,
      then: Yup.string()
        .required(getRequiredMessage('zip code'))
        .when('mailCountry', {
          is: v => v === US_ID || v === String(US_ID),
          then: Yup.string().matches(
            zipCodeRegex,
            'Please enter a valid zip code'
          ),
        }),
    }),
  });
};

export default validationSchema;
