import * as Yup from 'yup';

const validationSchema = isSuggestion =>
  Yup.object({
    specialties: Yup.array()
      .of(
        Yup.object({
          id: Yup.string().required('Please select a specialty'),
          isPrimary: Yup.boolean(),
          experienceInYears: Yup.number()
            .typeError('Please enter a valid number')
            .required('Please enter a valid number')
            .integer('Please enter a valid number')
            .min(0, 'Must be a number between 0 and 75')
            .max(75, 'Must be a number between 0 and 75'),
          experienceInMonths: Yup.number()
            .typeError('Please enter a valid number')
            .integer('Please enter a valid number')
            .min(0, 'Must be a number between 0 and 11')
            .max(11, 'Must be a number between 0 and 11'),
        })
      )
      .min(1, () => 'Please enter at least 1 specialty'),
    travelExperience: Yup.object({
      hasTraveled: Yup.boolean().nullable().required('Please select an option'),
      experienceInYears: Yup.number().when('hasTraveled', {
        is: true,
        then: Yup.number()
          .typeError('Please enter a valid number')
          .required('Please enter a valid number')
          .integer('Please enter a valid number')
          .min(0, 'Must be a number between 0 and 75')
          .max(75, 'Must be a number between 0 and 75'),
      }),
      experienceInMonths: Yup.number().when('hasTraveled', {
        is: true,
        then: Yup.number()
          .typeError('Please enter a valid number')
          .integer('Please enter a valid number')
          .min(0, 'Must be a number between 0 and 11')
          .max(11, 'Must be a number between 0 and 11'),
      }),
    }),
    preferences: Yup.object({
      shiftType: Yup.string(),
    }),
    referrerName: Yup.string(),
    licensedStateIds: Yup.array().of(Yup.number()),
    desiredStateIds: Yup.array().of(Yup.number()),
    stateOfResidenceId:
      !isSuggestion && Yup.number().required('Please select a state'),
  });

export default validationSchema;
