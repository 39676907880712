import * as Yup from 'yup';
/* eslint-disable no-control-regex */

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Please enter a valid email')
    .email('Please enter a valid email'),
  pwd: Yup.string().required('Please enter a password'),
});

export default validationSchema;
