import React from 'react';

import ButtonGroup from '../forms/formik-inputs/button-group';
import styles from './index.module.scss';

const ShiftPreference = () => (
  <div className={`field ${styles.shiftPreference}`}>
    <p className={`label is-size-7`}></p>
    <ButtonGroup
      name="preferences.shiftType"
      buttonClass={styles.toggleButton}
      labelText={
        <>
          Shift preference <i>(optional)</i>
        </>
      }
      options={[
        {
          buttonText: 'Any',
          value: 'Any',
        },
        {
          buttonText: 'Days',
          value: 'Days',
        },
        {
          buttonText: 'Nights',
          value: 'Nights',
        },
      ]}
    />
  </div>
);

export default ShiftPreference;
