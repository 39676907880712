import {
  getObjectWithNullPropertiesSetToEmptyStrings,
  getObjectWithEmptyStringPropertiesSetToNull,
} from '../utils/api-utils';
import { getProfileApiUrl, getSuggestionsApiUrl } from '../utils/url-helpers';
import { useAuthenticatedRequest } from '../hooks/api-hooks';

const SECTION_URL_SLUG = 'education';

export function useEducationApi() {
  const { get, post, put } = useAuthenticatedRequest();
  return {
    getEducation: function (profileId, isViewingSuggestions) {
      const requestUrl = isViewingSuggestions
        ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
        : getProfileApiUrl(profileId, SECTION_URL_SLUG);

      return new Promise((resolve, reject) => {
        get(requestUrl)
          .then(({ data }) => {
            let reformattedEducation = {
              degrees: data.map(degree => {
                return getObjectWithNullPropertiesSetToEmptyStrings(degree);
              }),
            };

            resolve(reformattedEducation);
          })
          .catch(err => reject(err));
      });
    },
    updateEducation: function (profileId, data, isSuggestion) {
      const requestFunction = isSuggestion ? post : put;

      return requestFunction(
        isSuggestion
          ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
          : getProfileApiUrl(profileId, SECTION_URL_SLUG),
        data.map(degree => getObjectWithEmptyStringPropertiesSetToNull(degree))
      );
    },
  };
}
