import { useAuthenticatedRequest } from '../hooks/api-hooks';

export function useBackgroundCheckCopyApi() {
  const { get, post } = useAuthenticatedRequest();

  return {
    getHasRequestedBackgroundCheckCopy: profileId => {
      const url = `/api/legal-agreements/was-copy-of-background-check-requested-for-profile/${profileId}`;
      return get(url);
    },

    storeRequestForBackgroundCheckCopy: profileId => {
      const url = `/api/legal-agreements/request-copy-of-background-check-for-profile/${profileId}`;
      return post(url);
    }
  }
}
