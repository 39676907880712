import React, { useState, useEffect } from 'react';
import { useStatesApi } from '../../../../services/states-service';
import Multiselect from '../forms/formik-inputs/multiselect';
import styles from './licensed.states.module.scss';
import { US_ID } from '../../../../constants/personal-info';
import { stateOptionsByCountryId } from '../../../../utils/profile-utils';

const DesiredLocations = ({ values, isViewingSuggestions }) => {
  const { getAllStatesAsValueNameObjects } = useStatesApi();
  const [stateOptions, setStateOptions] = useState([]);
  useEffect(() => {
    getAllStatesAsValueNameObjects().then(states => {
      setStateOptions(states);
    });
  }, []);
  return (
    <div className="field">
      <Multiselect
        isViewingSuggestions={isViewingSuggestions}
        values={values}
        options={stateOptionsByCountryId(stateOptions, US_ID)}
        name="desiredStateIds"
        placeholderText="Select all that apply"
        labelText={
          <>
            Desired locations <i>(optional)</i>
          </>
        }
        labelClasses="is-size-7"
        mobileSelectClasses="is-halfwidth"
        dropdownClasses={`${styles.multiselectContainer}`}
      />
    </div>
  );
};
export default DesiredLocations;
