import { useAuthenticatedRequest } from '../hooks/api-hooks';
import {
  getObjectWithEmptyStringPropertiesSetToNull,
  getObjectWithNullPropertiesSetToEmptyStrings,
} from '../utils/api-utils';
import { legalAgreementEeoOptions } from '../constants/legal-agreement-eeo-options';

import { useBackgroundCheckCopyApi } from './background-check-copy-service';

const initalEEOS = [
  {
    name: 'gender',
    text: 'Gender',
    value: '',
    options: legalAgreementEeoOptions.GENDER,
  },
  {
    name: 'ethnicity',
    text: 'What race or ethnicity do you most closely identify with?',
    value: '',
    options: legalAgreementEeoOptions.RACE,
  },
  {
    name: 'veteran',
    text: 'Please select your Veteran Status',
    value: '',
    options: legalAgreementEeoOptions.VETERAN,
  },
  {
    name: 'disability',
    text: 'Disability',
    value: '',
    options: legalAgreementEeoOptions.DISABILITY,
  },
];

const frontEndToBackendEEOS = eeos => {
  const eeoObj = {};
  const keys = eeos.map(eeo => eeo.name);

  for (const key of keys) {
    eeoObj[key] = eeos.find(e => e.name === key).value;
  }
  return eeoObj;
};

export function useAgreementsApi() {
  const { get, post, put } = useAuthenticatedRequest();
  const { storeRequestForBackgroundCheckCopy } = useBackgroundCheckCopyApi();

  const updateAgreements = (profileId, data) => {
    const putAgreementsEndpoint = `/api/profiles/${profileId}/legal-agreements`;
    const reformattedAgreements = data.agreements.map(agreement => {
      return getObjectWithEmptyStringPropertiesSetToNull(agreement);
    });
    return put(putAgreementsEndpoint, {
      agreements: reformattedAgreements,
      eeos: frontEndToBackendEEOS(data.eeos),
    });
  };

  return {
    getAgreements: function (profileId) {
      const getAgreementsEndpoint = `/api/profiles/${profileId}/legal-agreements`;
      return new Promise((resolve, reject) => {
        get(getAgreementsEndpoint)
          .then(({ data }) => {
            const hasEEOSAlready = data.eeos && Object.keys(data.eeos).length;
            let eeos = hasEEOSAlready ? data.eeos : initalEEOS;
            if (hasEEOSAlready) {
              eeos = initalEEOS.map(eeo => ({
                ...eeo,
                value: data.eeos[eeo.name] ?? '',
              }));
            }

            const reformattedAgreements = {
              agreements: data.agreements.map(agreement => {
                return getObjectWithNullPropertiesSetToEmptyStrings(agreement);
              }),
              eeos,
            };

            resolve(reformattedAgreements);
          })
          .catch(err => reject(err));
      });
    },

    getUnsignedAgreements: function (profileId) {
      const url = '/api/legal-agreements';

      return get(url, { profileId: profileId, isSigned: false });
    },

    getAcceptanceAgreement: function (documentId) {
      const endpoint = `/api/acceptance-agreements/${documentId}`;
      return get(endpoint);
    },

    getPayrollDeductionAgreement: function (documentId) {
      const endpoint = `/api/payroll-deduction/${documentId}`;
      return get(endpoint);
    },

    acceptPayrollDeduction: function (acctNumLastFour, documentId, profileId) {
      const signedAgreement = {
        profileId: profileId,
        AcctNumberLastFour: acctNumLastFour,
        documentId: documentId,
      };
      return post(
        `/api/payroll-deduction/${documentId}/accept`,
        signedAgreement
      );
    },

    signAcceptanceAgreement: function (documentId, agreement) {
      const endpoint = `/api/acceptance-agreements/${documentId}/accept`;

      const { text, ...agreementWithoutText } = agreement;

      const formattedAgreement =
        getObjectWithEmptyStringPropertiesSetToNull(agreementWithoutText);

      return post(endpoint, formattedAgreement);
    },

    updateAgreementsAndBackgroundCheckCopyRequest: (profileId, formValues) => {
      return new Promise((resolve, reject) => {
        if (formValues.hasRequestedBackgroundCheckCopy === true)
          return storeRequestForBackgroundCheckCopy(profileId)
            .then(() => {
              updateAgreements(profileId, formValues)
                .then(() => resolve())
                .catch(err => reject(err));
            })
            .catch(err => reject(err));
        else
          return updateAgreements(profileId, formValues)
            .then(() => resolve())
            .catch(err => reject(err));
      });
    },
  };
}
