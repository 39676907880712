export const historyEntryTypes = {
  WORK_HISTORY: 1,
  GAP: 2,
};

export const getDefaultHistoryEntryValues = (profileId, entryType) => {
  return entryType === historyEntryTypes.WORK_HISTORY
    ? {
        type: historyEntryTypes.WORK_HISTORY,
        data: {
          profileId: profileId,
          facility: {
            name: '',
            city: '',
            stateId: '',
            countryId: '',
          },
          unitTypeIds: [],
          primaryUnitLevelId: '',
          bedsInPrimaryUnit: '',
          patientToNurseRatio: '',
          chartingSystem: '',
          workTypeId: '',
          travelAgencyName: '',
          startDate: '',
          endDate: '',
          additionalInfo: '',
          isCurrentlyEmployed: false,
          chargeNursePosition: false,
          professionId: '',
          hoursPerWeek: '',
          floatUnitTypes: '',
          numberOfFloatShiftsPerMonth: '',
        },
      }
    : {
        type: historyEntryTypes.GAP,
        data: {
          profileId: profileId,
          gapReasonId: '',
          startDate: '',
          endDate: '',
        },
      };
};
