import * as Yup from 'yup';
import { telephoneRegex } from '../../../../utils/validation-utils';

const NO_SELECTION_MESSAGE = 'Please select an option';

function getRequiredMessage(fieldName) {
  return `Please enter a ${fieldName}`;
}

const validationSchema = Yup.object({
  agreement: Yup.object({
    emergencyContacts: Yup.array().of(
      Yup.object({
        name: Yup.string().required(getRequiredMessage('name')),
        phone: Yup.string()
          .required(getRequiredMessage('phone number'))
          .matches(telephoneRegex, 'Please enter a valid phone number'),
      })
    ),
    isCalfornia: Yup.boolean(),
    californiaMealPlanOption: Yup.number().when('isCalifornia', {
      is: true,
      then: Yup.number().required(NO_SELECTION_MESSAGE),
    }),
  }),
});

export default validationSchema;
