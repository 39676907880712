import apiService from './api-service';
import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { getMonthsFromMonthsAndYears } from '../utils/api-utils';
import { convertMonthsToMonthsAndYears } from '../utils/format-helpers';
import { getProfileApiUrl, getSuggestionsApiUrl } from '../utils/url-helpers';

const SECTION_URL_SLUG = 'expertise';

export function useExpertiseApi() {
  const { get, post } = useAuthenticatedRequest();

  return {
    getExpertise: function (profileId, isViewingSuggestions) {
      const requestFunction = isViewingSuggestions ? get : apiService.get;
      const requestUrl = isViewingSuggestions
        ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
        : getProfileApiUrl(profileId, SECTION_URL_SLUG);

      return new Promise((resolve, reject) => {
        requestFunction(requestUrl)
          .then(({ data }) => {
            let reformattedExpertise = {};
            reformattedExpertise.specialties = formatSpecialtiesForDisplay(
              data.specialties
            );

            reformattedExpertise.travelExperience =
              getReformattedTravelExperience(data.travelExperience);

            reformattedExpertise.preferences = {
              shiftType:
                data.preferences === null || data.shiftType === null
                  ? ''
                  : data.preferences.shiftType,
            };

            reformattedExpertise.profession = data.profession;
            reformattedExpertise.referrerName = data.referrerName ?? '';
            reformattedExpertise.licensedStateIds = data.licensedStateIds;
            reformattedExpertise.desiredStateIds = data.desiredStateIds;
            reformattedExpertise.stateOfResidenceId =
              data.stateOfResidenceId || '';

            resolve(reformattedExpertise);
          })
          .catch(err => reject(err));
      });
    },
    updateExpertise: (data, profileId, isSuggestion) => {
      const requestFunction = isSuggestion ? post : apiService.put;

      return requestFunction(
        isSuggestion
          ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
          : getProfileApiUrl(profileId, SECTION_URL_SLUG),
        buildRequestBody(data)
      );
    },
  };
}

function getExperienceAsYearsAndMonths(totalMonthsExperience) {
  const monthsAndYears = convertMonthsToMonthsAndYears(totalMonthsExperience);
  return {
    experienceInYears: monthsAndYears.years,
    experienceInMonths: monthsAndYears.months,
  };
}

const getReformattedTravelExperience = travelExperience => {
  if (travelExperience == null) {
    return {
      hasTraveled: null,
      experienceInYears: '',
      experienceInMonths: '',
    };
  }
  return {
    hasTraveled: travelExperience.hasTraveled,
    ...getExperienceAsYearsAndMonths(travelExperience.monthsOfExperience),
  };
};

const formatSpecialtiesForDisplay = specialties => {
  const formatted = specialties.map(specialty => {
    return {
      id: specialty.specialtyId.toString(),
      isPrimary: specialty.isPrimary,
      ...getExperienceAsYearsAndMonths(specialty.monthsOfExperience),
    };
  });

  if (formatted.length) {
    putPrimarySpecialtyFirstInList(formatted);
  } else {
    formatted.push({
      id: '',
      isPrimary: true,
      experienceInYears: '',
      experienceInMonths: '',
    });
  }
  return formatted;
};

const putPrimarySpecialtyFirstInList = specialties => {
  const primary = specialties.find(sp => sp.isPrimary);
  if (primary) {
    specialties.splice(specialties.indexOf(primary), 1);
    specialties.unshift(primary);
  } else {
    specialties[0].isPrimary = true;
  }
};

const formatSpecialtiesForUpdate = specialties => {
  const formatted = specialties.map(specialty => {
    return {
      specialtyId: specialty.id,
      isPrimary: specialty.isPrimary,
      monthsOfExperience: getMonthsFromMonthsAndYears(
        specialty.experienceInYears,
        specialty.experienceInMonths
      ),
    };
  });

  if (!formatted.some(specialty => specialty.isPrimary)) {
    formatted[0].isPrimary = true;
  }

  return formatted;
};

function buildRequestBody(data) {
  return {
    specialties: formatSpecialtiesForUpdate(data.specialties),
    travelExperience: {
      hasTraveled: data.travelExperience.hasTraveled,
      monthsOfExperience: data.travelExperience.hasTraveled
        ? getMonthsFromMonthsAndYears(
            data.travelExperience.experienceInYears,
            data.travelExperience.experienceInMonths
          )
        : 0,
    },
    preferences: data.preferences.shiftType === '' ? null : data.preferences,
    referrerName: data.referrerName,
    licensedStateIds: data.licensedStateIds,
    desiredStateIds: data.desiredStateIds,
    stateOfResidenceId: data.stateOfResidenceId,
  };
}
