import React from 'react';

import ButtonGroup from '../forms/formik-inputs/button-group';
import Input from '../forms/formik-inputs/input';
import styles from './index.module.scss';

const TravelExperience = ({ values }) => {
  return (
    <>
      <ButtonGroup
        name="travelExperience.hasTraveled"
        labelText="Have you traveled before?"
        buttonClass={styles.toggleButton}
        options={[
          {
            buttonText: 'Yes',
            value: true,
          },
          {
            buttonText: 'No',
            value: false,
          },
        ]}
      />

      {values.travelExperience.hasTraveled ? (
        <>
          <p
            className={`has-text-weight-normal is-size-7 ${styles.experienceDescription}`}
          >
            How much travel experience do you have?
          </p>
          <div className={`field ${styles.experienceFields}`}>
            <div className={`${styles.yearsWrapper}`}>
              <Input
                name={`travelExperience.experienceInYears`}
                labelText="Years"
                placeholder="# of years"
                labelClass={`is-size-7`}
              />
            </div>
            <div>
              <Input
                name={`travelExperience.experienceInMonths`}
                labelText="Months"
                placeholder="# of months"
                labelClass={`is-size-7`}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default TravelExperience;
