import {
  getObjectWithNullPropertiesSetToEmptyStrings,
  getObjectWithEmptyStringPropertiesSetToNull,
} from '../utils/api-utils';
import { getProfileApiUrl, getSuggestionsApiUrl } from '../utils/url-helpers';
import { useAuthenticatedRequest } from '../hooks/api-hooks';

const SECTION_URL_SLUG = 'references';

export function useReferencesApi() {
  const { get, post, put } = useAuthenticatedRequest();

  return {
    getReferences: function (profileId, isViewingSuggestions) {
      const requestUrl = isViewingSuggestions
        ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
        : getProfileApiUrl(profileId, SECTION_URL_SLUG);

      return new Promise((resolve, reject) => {
        get(requestUrl)
          .then(({ data }) => {
            let reformattedReferences = data.map(reference => {
              return getObjectWithNullPropertiesSetToEmptyStrings(reference);
            });

            resolve(reformattedReferences);
          })
          .catch(error => reject(error));
      });
    },
    updateReferences: function (profileId, data, isSuggestion) {
      let reformattedReferences = data.map(reference => {
        return getObjectWithEmptyStringPropertiesSetToNull(reference);
      });

      const requestFunction = isSuggestion ? post : put;

      return requestFunction(
        isSuggestion
          ? getSuggestionsApiUrl(profileId, SECTION_URL_SLUG)
          : getProfileApiUrl(profileId, SECTION_URL_SLUG),
        reformattedReferences
      );
    },
  };
}
