import React from 'react';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import Input from '../forms/formik-inputs/input';
import TelephoneInput from '../forms/formik-inputs/telephone-input';
import sharedProfileStyles from '../profile-form/index.module.scss';

const ReferencesFieldArray = ({ values }) => {
  return (
    <FieldArray name="references">
      {({ push, remove }) => (
        <>
          {values.references.map((degree, index) => (
            <div key={index}>
              <hr className={sharedProfileStyles.formDivider} />
              <div className="field">
                <Input
                  name={`references[${index}].name`}
                  labelText="Reference name"
                  labelClass={`is-size-7`}
                  shouldCheckForCapsLock={true}
                />
              </div>
              <div className="field field-body">
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`references[${index}].title`}
                    labelText="Title"
                    labelClass={`is-size-7`}
                    shouldCheckForCapsLock={true}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`references[${index}].facilityName`}
                    labelText="Facility"
                    labelClass={`is-size-7`}
                    shouldCheckForCapsLock={true}
                  />
                </div>
              </div>
              <div className="field field-body">
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <TelephoneInput
                    name={`references[${index}].phoneNumber`}
                    labelText="Phone"
                    labelClass={`is-size-7`}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`references[${index}].email`}
                    labelText={
                      <>
                        Email <em>(optional but preferred)</em>
                      </>
                    }
                    labelClass={`is-size-7`}
                    shouldCheckForCapsLock={true}
                  />
                </div>
              </div>

              {index !== 0 ? (
                <div className={`${sharedProfileStyles.removeButtonContainer}`}>
                  <button
                    type="button"
                    className="button is-small is-danger is-outlined"
                    onClick={() => remove(index)}
                  >
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faMinus} />
                    </span>
                    <span>Remove reference</span>
                  </button>
                </div>
              ) : null}
            </div>
          ))}
          <div className={`field ${sharedProfileStyles.addButton}`}>
            <button
              type="button"
              className="button is-info is-small"
              onClick={() =>
                push({
                  name: '',
                  title: '',
                  facilityName: '',
                  phoneNumber: '',
                  email: '',
                })
              }
            >
              <span className="icon is-small">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>Add another reference</span>
            </button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default ReferencesFieldArray;
