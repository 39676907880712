import apiService from './api-service';

export function useStatesApi() {
  const getAllStates = () => apiService.get('/api/states'),
    mapToValueNamePairsForForms = states =>
      states.map(({ id, name }) => ({ value: id, name: name })),
    mapToValueNameAndCountryIdPairsForForms = states =>
      states.map(({ id, name, countryId }) => ({
        value: id,
        name: name,
        countryId: countryId,
      })),
    sortByNameAscending = states =>
      states.sort((a, b) => {
        if (a.name === 'Other') return 1;
        if (b.name === 'Other') return -1;

        return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
      });

  return {
    getAllStatesAsValueNameObjects: () => {
      return new Promise((resolve, reject) => {
        getAllStates()
          .then(({ data }) => {
            const statesSortedByName = sortByNameAscending(data);

            const statesFormattedForFormFields =
              mapToValueNameAndCountryIdPairsForForms(statesSortedByName);

            resolve(statesFormattedForFormFields);
          })
          .catch(error => reject(error));
      });
    },
  };
}
