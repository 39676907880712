export const agreementText =
  `I certify that all statements made in this ` +
  `application are true to the best of my knowledge. I understand that any ` +
  `falsification or misleading information given in my application may result ` +
  `in the termination of my employment with Trustaff. Furthermore, I understand ` +
  `that my professional conduct and clinical performance is directly related to ` +
  `my ability to be placed on assignments for Trustaff and that I will adhere to ` +
  `all expectations set forth in the employee handbook. I authorize Trustaff to ` +
  `verify the information I have provided, to contact references, and to conduct ` +
  `a criminal background check concerning my ability, character, and past ` +
  `employment record.

I understand that nothing contained in this application is intended to create ` +
  `an employment contract, either verbal or written, with Trustaff or their ` +
  `clients. Furthermore, I understand that my employment is "at will” and that ` +
  `Trustaff or I may terminate my employment at any time with or without notice ` +
  `and with or without cause.`;
