import * as Yup from 'yup';

const validationSchema = Yup.object({
  agreements: Yup.array().of(
    Yup.object({
      isSigned: Yup.bool().oneOf([true], 'Please check the box if you agree'),
      signedName: Yup.string().required('Please enter your name'),
    })
  ),
  hasRequestedBackgroundCheckCopy: Yup.bool()
});

export default validationSchema;
