import * as Yup from 'yup';

const validationSchema = Yup.object({
  degrees: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().nullable(),
        schoolName: Yup.string().required('Please enter a valid school name'),
        schoolCity: Yup.string().required('Please enter a valid city'),
        schoolStateId: Yup.number().required('Please select an option'),
        typeId: Yup.number().required('Please select an option'),
        areaOfStudy: Yup.string().required('Please enter a major'),
        graduationMonth: Yup.number().required('Please select an option'),
        graduationYear: Yup.number().required('Please select an option'),
      })
    )
    .min(1, 'Please enter at least one school'),
});

export default validationSchema;
