import * as Yup from 'yup';
import { asciiRegex } from '../../../../utils/validation-utils';

const MINIMUM_PWD_LENGTH = 8;

const validationSchema = Yup.object({
  pwd: Yup.string()
    .required('Please enter a password')
    .matches(asciiRegex, 'Password can only contain valid ASCII characters')
    .min(
      MINIMUM_PWD_LENGTH,
      ({ min }) => `Password must be at least ${min} characters`
    )
    .max(128, 'Password is too long'),
  pwdConfirmation: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('pwd')], 'Passwords do not match'),
});

export default validationSchema;
