import React, { useEffect, useState } from 'react';

import ReferencesFieldArray from './references-field-array';
import ProfileForm from '../profile-form';
import { useReferencesApi } from '../../../../services/references-service';
import validationSchema from './validation-schema';
import sharedProfileStyles from '../profile-form/index.module.scss';

/* eslint-disable react-hooks/exhaustive-deps */

const References = ({ profileId, isSuggestion, isViewingSuggestions }) => {
  const { getReferences, updateReferences } = useReferencesApi();
  const CURRENT_PAGE_PATH = '/profile/references/';
  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);

  const [referenceList, setReferenceList] = useState({
    references: [
      {
        name: '',
        title: '',
        facilityName: '',
        phoneNumber: '',
        email: '',
      },
    ],
  });

  useEffect(() => {
    getReferences(profileId, isViewingSuggestions)
      .then(data => {
        if (data.length) {
          setReferenceList({ references: data });
        }
        setHasFetchedUserData(true);
      })
      .catch(() => {
        setHasFetchError(true);
      });
  }, []);

  return (
    <ProfileForm
      initialValues={referenceList}
      currentPagePath={CURRENT_PAGE_PATH}
      defaultNextSection="/profile/licenses-and-certs/"
      backButtonPath="/profile/work-history/"
      IntroComponent={() => (
        <>
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            My references
          </h1>
          <p className={`subtitle is-6 has-text-centered-mobile`}>
            Please provide two clinical references from within the past year at
            a charge, supervisor, or manager level.
          </p>
        </>
      )}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        return updateReferences(profileId, values.references, isSuggestion);
      }}
      hasFetchError={hasFetchError}
      hasFetchedUserData={hasFetchedUserData}
      formLevelErrorMessage={errors => {
        if (errors && typeof errors.references === 'string') {
          return errors.references;
        }

        return Object.keys(errors).length > 0;
      }}
      isSuggestion={isSuggestion}
      isViewingSuggestions={isViewingSuggestions}
      profileId={profileId}
      shouldShowCapsLockWarning={true}
    >
      {({ values }) => (
        <>
          <ReferencesFieldArray values={values} />
          <hr className={sharedProfileStyles.formDivider} />
        </>
      )}
    </ProfileForm>
  );
};

export default References;
