import * as Yup from 'yup';
import { historyEntryTypes } from '../../../../constants/default-history-entry-values';
import { professions } from '../../../../constants/professions';

const INVALID_NUMBER_MESSAGE = 'Please enter a valid number';
const NO_SELECTION_MESSAGE = 'Please select an option';
const NO_DATE_SELECTED = 'Please select a date';
const END_DATE_EARLIER_THAN_START_DATE = `End date can't be before start date`;
const DATE_OVERLAP = 'This date overlaps with another entry';

function getRequiredMessage(fieldName) {
  return `Please enter a ${fieldName}`;
}

function doesGapStartOverlap(jobEntry, gapEntry) {
  const jobEndDate = new Date(jobEntry.data.endDate);
  let hasOverlap =
    (jobEndDate === undefined &&
      gapEntry.startDate > new Date(jobEntry.data.startDate)) ||
    (gapEntry.startDate < jobEndDate && gapEntry.endDate > jobEndDate);

  return hasOverlap;
}

function doesGapEndOverlap(jobEntry, gapEntry) {
  return (
    gapEntry.startDate < new Date(jobEntry.data.startDate) &&
    gapEntry.endDate > new Date(jobEntry.data.startDate)
  );
}

function testStartDate(workHistory, gapEntry) {
  let isValid = true;
  const jobEntries = workHistory.filter(
    entry => entry.type === historyEntryTypes.WORK_HISTORY
  );

  jobEntries.forEach(jobEntry => {
    if (doesGapStartOverlap(jobEntry, gapEntry)) isValid = false;
  });
  return isValid;
}

function testEndDate(workHistory, gapEntry) {
  let isValid = true;
  const jobEntries = workHistory.filter(
    entry => entry.type === historyEntryTypes.WORK_HISTORY
  );

  jobEntries.forEach(jobEntry => {
    if (doesGapEndOverlap(jobEntry, gapEntry)) isValid = false;
  });
  return isValid;
}

Yup.addMethod(Yup.array, 'minEmployer', function (message) {
  return this.test('minEmployer', message, function (list) {
    const jobList = list.filter(
      entry => entry.type === historyEntryTypes.WORK_HISTORY
    );
    return jobList.length > 0;
  });
});

Yup.addMethod(Yup.array, 'noConsecutiveGaps', function (message) {
  return this.test('noConsecutiveGaps', message, function (list) {
    let isValid = true;
    const sortedList = [...list].sort(function (a, b) {
      return new Date(b.data.startDate) - new Date(a.data.startDate);
    });

    sortedList.forEach((entry, index, arr) => {
      if (
        entry.type === historyEntryTypes.GAP &&
        arr[index + 1]?.type === historyEntryTypes.GAP
      )
        isValid = false;
    });
    return isValid;
  });
});

const validationSchema = (
  workTypeIdsThatRequireAgencyName,
  unitLevelRequiredIds,
  professionId,
  hasSubmittedAnEntityAsNullPreviously
) => {
  function hasUnitLevel(unitTypeIds) {
    return unitTypeIds.some(unitTypeId =>
      unitLevelRequiredIds.includes(unitTypeId)
    );
  }

  function workTypeRequiresAgencyName(workTypeId) {
    return workTypeIdsThatRequireAgencyName.includes(workTypeId);
  }

  return Yup.object({
    workHistoryEntries: Yup.array()
      .of(
        Yup.object({
          type: Yup.number(),
          data: Yup.object().when('type', {
            is: historyEntryTypes.WORK_HISTORY,
            then: Yup.object({
              facility: Yup.object({
                id: Yup.string().nullable(),
                name: Yup.string().required(
                  getRequiredMessage('facility name')
                ),
                city: Yup.string().required(getRequiredMessage('city')),
                stateId: Yup.number()
                  .typeError(NO_SELECTION_MESSAGE)
                  .required(NO_SELECTION_MESSAGE),
                countryId: Yup.number()
                  .typeError(NO_SELECTION_MESSAGE)
                  .required(NO_SELECTION_MESSAGE),
              }),
              professionId: hasSubmittedAnEntityAsNullPreviously
                ? Yup.number().nullable()
                : Yup.number().required(NO_SELECTION_MESSAGE),
              unitTypeIds: Yup.array()
                .of(Yup.number())
                .required(NO_SELECTION_MESSAGE),
              primaryUnitLevelId: Yup.number()
                .when('unitTypeIds', {
                  is: unitTypeIds => hasUnitLevel(unitTypeIds),
                  then: Yup.number().required(NO_SELECTION_MESSAGE),
                })
                .nullable(),
              floatUnitTypes: Yup.number().nullable(),
              numberOfFloatShiftsPerMonth: Yup.number()
                .typeError(INVALID_NUMBER_MESSAGE)
                .integer(INVALID_NUMBER_MESSAGE)
                .positive(INVALID_NUMBER_MESSAGE)
                .nullable(),
              bedsInPrimaryUnit: Yup.number()
                .typeError(INVALID_NUMBER_MESSAGE)
                .integer(INVALID_NUMBER_MESSAGE)
                .positive(INVALID_NUMBER_MESSAGE),
              hoursPerWeek: hasSubmittedAnEntityAsNullPreviously
                ? Yup.number()
                    .typeError(INVALID_NUMBER_MESSAGE)
                    .integer(INVALID_NUMBER_MESSAGE)
                    .positive(INVALID_NUMBER_MESSAGE)
                    .nullable()
                : Yup.number()
                    .typeError(INVALID_NUMBER_MESSAGE)
                    .integer(INVALID_NUMBER_MESSAGE)
                    .positive(INVALID_NUMBER_MESSAGE)
                    .required(INVALID_NUMBER_MESSAGE),
              patientToNurseRatio:
                professionId === professions.NURSING
                  ? Yup.number()
                      .typeError(INVALID_NUMBER_MESSAGE)
                      .integer(INVALID_NUMBER_MESSAGE)
                      .positive(INVALID_NUMBER_MESSAGE)
                      .required(INVALID_NUMBER_MESSAGE)
                  : Yup.number()
                      .typeError(INVALID_NUMBER_MESSAGE)
                      .integer(INVALID_NUMBER_MESSAGE)
                      .positive(INVALID_NUMBER_MESSAGE),
              chartingSystem:
                professionId === professions.NURSING &&
                Yup.string().required(getRequiredMessage('charting system')),
              workTypeId: Yup.number().required(NO_SELECTION_MESSAGE),
              isCurrentlyEmployed: Yup.bool(),
              chargeNursePosition: Yup.bool(),
              travelAgencyName: Yup.string().when('workTypeId', {
                is: workTypeId => workTypeRequiresAgencyName(workTypeId),
                then: Yup.string().required('Please enter an agency'),
              }),
              startDate: Yup.date()
                .typeError(NO_DATE_SELECTED)
                .required(NO_DATE_SELECTED),
              endDate: Yup.date()
                .when('isCurrentlyEmployed', {
                  is: false,
                  then: Yup.date()
                    .typeError(NO_DATE_SELECTED)
                    .required(NO_DATE_SELECTED)
                    .min(
                      Yup.ref('startDate'),
                      END_DATE_EARLIER_THAN_START_DATE
                    ),
                })
                .nullable(),
              additionalInfo: Yup.string(),
            }),

            otherwise: Yup.object({
              gapReasonId: Yup.number().required(NO_SELECTION_MESSAGE),
              startDate: Yup.date()
                .typeError(NO_DATE_SELECTED)
                .required(NO_DATE_SELECTED)
                .test('startDateOverlap', DATE_OVERLAP, function () {
                  // Workaround to access all entries since 'values' cannot be passed in
                  const workHistoryEntries =
                    this.from[2].value.workHistoryEntries;

                  return testStartDate(workHistoryEntries, this.parent);
                }),
              endDate: Yup.date()
                .typeError(NO_DATE_SELECTED)
                .required(NO_DATE_SELECTED)
                .min(Yup.ref('startDate'), END_DATE_EARLIER_THAN_START_DATE)
                .test('endDateOverlap', DATE_OVERLAP, function () {
                  const workHistoryEntries =
                    this.from[2].value.workHistoryEntries;

                  return testEndDate(workHistoryEntries, this.parent);
                }),
            }),
          }),
        })
      )
      .minEmployer('Please enter at least 1 employer')
      .noConsecutiveGaps(
        'Cannot have more than one gap between positions. Please choose the most accurate explanation.'
      ),
  });
};

export default validationSchema;
