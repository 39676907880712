import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import FileInput from '../../../common/components/forms/formik-inputs/file-input';
import { FormDatePickerInput } from '../../../common/components/forms/formik-inputs/date-picker';
import Input from '../../../common/components/forms/formik-inputs/input';
import ProfileForm from '../../../common/components/profile-form';
import Select from '../../../common/components/forms/formik-inputs/select';
import SsnInput from '../../../common/components/forms/formik-inputs/ssn-input';
import TelephoneInput from '../../../common/components/forms/formik-inputs/telephone-input';
import { usePersonalInfoApi } from '../../../../services/personal-info-service';
import validationSchema from './validation-schema';
import sharedProfileStyles from '../../../common/components/profile-form/index.module.scss';
import styles from './index.module.scss';
import { useStatesApi } from '../../../../services/states-service';
import { SUFFIX_OPTIONS } from '../../../../constants/personal-info';
import StateAndMailAddress from './state-and-mail';
import { authRoles } from '../../../../constants/auth-roles';
import { useAuth } from '../../../../providers/auth-provider';

const PersonalInfo = ({ profileId, isSuggestion, isViewingSuggestions }) => {
  const { getAllStatesAsValueNameObjects } = useStatesApi();
  const { getPersonalInfo, updatePersonalInfo } = usePersonalInfoApi();
  const { hasRole, meta } = useAuth();

  const [stateOptions, setStateOptions] = useState([]);
  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [driversLicenseImageFile, setDriversLicenseImageFile] = useState(null);
  const [hasCompletedLegalName, setHasCompletedLegalName] = useState(false);
  const [hasCompletedStreetAddress, setHasCompletedStreetAddress] =
    useState(false);
  const [isUsingBullhorn] = useState(meta.shouldUseBullhorn);
  // Chrome does not respect autocomplete="off" so must use random string to disable
  const RANDOM_STRING_TO_DISABLE_AUTOCOMPLETE = 'disable';
  const CURRENT_PAGE_PATH = '/profile/personal-info/';

  const [personalInfo, setPersonalInfo] = useState({
    suffixName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    phoneNumber: '',
    preferredInterviewTime: '',
    email: '',
    streetAddress: '',
    streetAddress2: '',
    city: '',
    stateId: '',
    zipCode: '',
    country: '',
    dateOfBirth: '',
    driversLicenseNumber: '',
    driversLicenseStateIssuedId: '',
    driversLicenseImage: '',
    ssn: '',
    mailAddressIsSame: false,
    mailStateId: '',
    mailStreetAddress: '',
    mailStreetAddress2: '',
    mailCity: '',
    mailZipCode: '',
    mailCountry: '',
  });

  const getDisabledMessage = sectionName => {
    return (
      <p className={styles.disabledInfo}>
        <FontAwesomeIcon icon={faInfoCircle} />
        <span className={styles.disabledInfoText}>
          To update your {sectionName}, reach out to your recruiter.
        </span>
      </p>
    );
  };

  const shouldDisableLegalName = () => {
    return (
      isUsingBullhorn &&
      !hasRole(authRoles.ADMIN_ASSISTANT) &&
      hasCompletedLegalName
    );
  };

  const shouldDisableAddress = () => {
    return (
      isUsingBullhorn &&
      !hasRole(authRoles.ADMIN_ASSISTANT) &&
      hasCompletedStreetAddress
    );
  };

  useEffect(() => {
    getAllStatesAsValueNameObjects().then(states => {
      setStateOptions(states);
    });

    getPersonalInfo(profileId, isViewingSuggestions)
      .then(({ hasCompletedLegalName, ...data }) => {
        if (Object.keys(data).length) {
          setHasCompletedLegalName(hasCompletedLegalName);
          setHasCompletedStreetAddress(data.streetAddress !== '');
        }
        setPersonalInfo(data);
        setHasFetchedUserData(true);
      })
      .catch(() => {
        setHasFetchError(true);
      });
  }, []);

  return (
    <ProfileForm
      IntroComponent={() => (
        <>
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            Verify your information
          </h1>
          <p className={`subtitle is-6 has-text-centered-mobile`}>
            Before we finish your profile, please confirm your personal
            information. This will appear on future job applications, so check
            it carefully!
          </p>
        </>
      )}
      currentPagePath={CURRENT_PAGE_PATH}
      defaultNextSection="/profile/agreements/"
      backButtonPath="/profile/skills-checklists/"
      initialValues={personalInfo}
      validationSchema={validationSchema(isSuggestion)}
      hasFetchError={hasFetchError}
      hasFetchedUserData={hasFetchedUserData}
      formLevelErrorMessage={errors => Object.keys(errors).length > 0}
      isSuggestion={isSuggestion}
      isViewingSuggestions={isViewingSuggestions}
      profileId={profileId}
      shouldShowCapsLockWarning={true}
      onSubmit={values => {
        return updatePersonalInfo(
          profileId,
          values,
          driversLicenseImageFile,
          isSuggestion
        );
      }}
    >
      <hr className={sharedProfileStyles.formDivider} />
      {!isSuggestion &&
        shouldDisableLegalName() &&
        getDisabledMessage('legal name')}
      <div className="field field-body">
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Input
            name={`firstName`}
            labelText="Legal first name"
            labelClass="is-size-7"
            disabled={shouldDisableLegalName()}
            autoComplete={RANDOM_STRING_TO_DISABLE_AUTOCOMPLETE}
            shouldCheckForCapsLock={true}
          />
        </div>
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Input
            name={`middleName`}
            labelText={
              <>
                Middle name <i>(optional)</i>
              </>
            }
            labelClass="is-size-7"
            disabled={shouldDisableLegalName()}
            autoComplete={RANDOM_STRING_TO_DISABLE_AUTOCOMPLETE}
            shouldCheckForCapsLock={true}
          />
        </div>
      </div>
      <div className="field field-body">
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Input
            name={`lastName`}
            labelText="Legal last name"
            labelClass="is-size-7"
            disabled={shouldDisableLegalName()}
            autoComplete={RANDOM_STRING_TO_DISABLE_AUTOCOMPLETE}
            shouldCheckForCapsLock={true}
          />
        </div>
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Select
            name={`suffixName`}
            labelText="Suffix"
            options={SUFFIX_OPTIONS}
            disabled={shouldDisableLegalName()}
            labelClass="is-size-7"
            selectWrapperClass="is-fullwidth"
          />
        </div>
      </div>

      <div className={`field ${sharedProfileStyles.groupedFormField}`}>
        <Input
          name={`preferredName`}
          labelText={
            <>
              Please provide your preferred name <i>(optional)</i>
            </>
          }
          labelClass="is-size-7"
          shouldCheckForCapsLock={true}
        />
      </div>
      <div className="field field-body">
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <TelephoneInput
            name={`phoneNumber`}
            labelText="Mobile Phone"
            labelClass="is-size-7"
            autoComplete={RANDOM_STRING_TO_DISABLE_AUTOCOMPLETE}
          />
        </div>
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <TelephoneInput
            name="otherPhoneNumber"
            labelText={
              <>
                Other Phone <i>(optional)</i>
              </>
            }
            labelClass="is-size-7"
            autoComplete={RANDOM_STRING_TO_DISABLE_AUTOCOMPLETE}
          />
        </div>
      </div>
      <div className="field">
        <Input
          name={`preferredInterviewTime`}
          labelText={
            <>
              Best time of day to reach you for a phone interview?{' '}
              <em>(optional)</em>
            </>
          }
          labelClass="is-size-7"
          autoComplete={RANDOM_STRING_TO_DISABLE_AUTOCOMPLETE}
          shouldCheckForCapsLock={true}
        />
      </div>
      <div className={`field ${sharedProfileStyles.groupedFormField}`}>
        <Input
          name={`email`}
          labelText="Email"
          labelClass="is-size-7"
          disabled
        />
        {!isSuggestion && !isViewingSuggestions && (
          <Link
            to="/profile/change-email/"
            className={`has-text-info is-size-7 ${styles.changeEmailLink}`}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
            <span className={styles.changeLinkText}>Change email</span>
          </Link>
        )}
      </div>
      {!isSuggestion && shouldDisableAddress() && getDisabledMessage('address')}
      <div className="field">
        <Input
          name={`streetAddress`}
          labelText="Address 1"
          labelClass="is-size-7"
          shouldCheckForCapsLock={true}
          disabled={shouldDisableAddress()}
        />
      </div>
      <div className="field">
        <Input
          name={`streetAddress2`}
          labelText="Address 2"
          labelClass="is-size-7"
          shouldCheckForCapsLock={true}
          disabled={shouldDisableAddress()}
        />
      </div>
      <div className="field field-body">
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Input
            name={`city`}
            labelText="City"
            labelClass="is-size-7"
            shouldCheckForCapsLock={true}
            disabled={shouldDisableAddress()}
          />
        </div>
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Input
            name={`zipCode`}
            labelText="Zip Code"
            labelClass="is-size-7"
            disabled={shouldDisableAddress()}
          />
        </div>
      </div>

      <StateAndMailAddress
        stateOptions={stateOptions}
        shouldDisableAddress={shouldDisableAddress()}
      />
      <hr className={sharedProfileStyles.formDivider} />
      {!isSuggestion && !isViewingSuggestions && (
        <>
          <p className={`has-text-centered-mobile content`}>
            Next, we'll collect some information to help confirm your identity.
            These details are used to submit you to the jobs of your choice.
            <span className="is-hidden-desktop">
              <br /> <br />
            </span>{' '}
            Your information is encrypted and stored securely. If you have
            questions about this step, please call your recruiter.
          </p>

          <div className={`field ${sharedProfileStyles.groupedFormField}`}>
            <FormDatePickerInput
              name={`dateOfBirth`}
              labelText="Date of birth"
              labelClass="is-size-7"
            />
          </div>

          <div className={`field ${sharedProfileStyles.groupedFormField}`}>
            <SsnInput
              name={`ssn`}
              labelText="Social security number"
              labelClass="is-size-7"
            />
          </div>

          <div className="field field-body">
            <div className={`field ${sharedProfileStyles.groupedFormField}`}>
              <Input
                name={`driversLicenseNumber`}
                labelText="Drivers license number"
                labelClass="is-size-7"
                preventCapsLockCheck={true}
              />
            </div>
            <div className={`field ${sharedProfileStyles.groupedFormField}`}>
              <Select
                name={`driversLicenseStateIssuedId`}
                labelText="State issued"
                options={stateOptions}
                placeholderText="Select a state"
                labelClass="is-size-7"
                selectWrapperClass="is-fullwidth"
              />
            </div>
          </div>

          <div className="field">
            <FileInput
              name="driversLicenseImage"
              labelText={`Upload drivers license`}
              onChange={e => setDriversLicenseImageFile(e.target.files[0])}
              changeFileText={`Change drivers license`}
            />
          </div>

          <hr className={sharedProfileStyles.formDivider} />
        </>
      )}
      {isSuggestion && (
        <div className={`${styles.disclaimerText}`}>
          <p
            className={`has-text-centered-mobile has-text-weight-bold has-text-danger`}
          >
            **Only travelers can view/update sensitive personal information like
            their date of birth and social security number. If changes need to
            be made to these sections, please ask the traveler to log in and
            complete them.**
          </p>
        </div>
      )}
    </ProfileForm>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  profileId: PropTypes.number,
  isSuggestion: PropTypes.bool,
  isViewingSuggestions: PropTypes.bool,
};

PersonalInfo.defaultProps = {
  isSuggestion: false,
  isViewingSuggestions: false,
};
