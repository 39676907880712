import * as Yup from 'yup';
import { asciiRegex } from '../../../../utils/validation-utils';

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Please enter a valid email')
    .email('Please enter a valid email'),
  pwd: Yup.string()
    .required('Please enter a password')
    .matches(asciiRegex, 'Password can only contain valid ASCII characters')
    .min(8, 'Password must be at least 8 characters')
    .max(128, 'Your cat is on the keyboard'),
  pwdConfirmation: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('pwd')], 'Passwords do not match'),
});

export default validationSchema;
