import { useAuthenticatedRequest } from '../hooks/api-hooks';
import apiService from './api-service';

const USERS_API_BASE_PATH = '/api/users';

export function useEmailApi() {
  const { post } = useAuthenticatedRequest();

  return {
    requestEmailChange: function (payload) {
      const path = `${USERS_API_BASE_PATH}/request-email-change`;
      return post(path, payload);
    },
  };
}

export function verifyEmailChangeSuccessful(payload) {
  const path = `${USERS_API_BASE_PATH}/change-email`;
  return apiService.post(path, payload);
}
