import React, { useEffect } from 'react';
import { navigate } from '@reach/router';

import Expertise from '../../../common/components/expertise';
import Layout from '../../../../components/layout';
import { useAppContext } from '../../../../providers/app-provider';
import { useAuth } from '../../../../providers/auth-provider';
import ProgressStepper from '../../../profile/components/progress-stepper';
import ViewingSuggestionsBanner from '../viewing-suggestions-banner';
/* eslint-disable react-hooks/exhaustive-deps */

const ExpertiseCandidateWrapper = ({ location }) => {
  const { applicantInfo } = useAppContext();
  const { loggedIn, getProfileId, getLoginPathBasedOnTalentStackActiveFlag } =
    useAuth();

  const profileId = loggedIn ? getProfileId() : applicantInfo.profileId;
  const isViewingSuggestions =
    location.state !== null && location.state.isViewingSuggestions === true;

  useEffect(() => {
    if (loggedIn === false && !applicantInfo.profileId) {
      navigate(getLoginPathBasedOnTalentStackActiveFlag());
    }
  }, [loggedIn]);

  return (
    <Layout>
      {(loggedIn || applicantInfo.profileId) && (
        <>
          <ViewingSuggestionsBanner
            isViewingSuggestions={isViewingSuggestions}
          />
          <ProgressStepper currentStep={1} />
          <Expertise
            profileId={profileId}
            email={applicantInfo.email}
            loggedIn={loggedIn}
            isViewingSuggestions={isViewingSuggestions}
          />
        </>
      )}
    </Layout>
  );
};

export default ExpertiseCandidateWrapper;
