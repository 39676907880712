import React from 'react';
import { navigate } from '@reach/router';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { useAppContext } from '../../../../providers/app-provider';
import { useAuth } from '../../../../providers/auth-provider';
import Input from '../../../common/components/forms/formik-inputs/input';
import { useUserApi } from '../../../../services/user-service';
import validationSchema from './validation-schema';
import styles from './index.module.scss';
import Layout from '../../../../components/layout';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../../constants/error-messages';

const Register = () => {
  const { registerUser } = useUserApi();
  const { applicantInfo } = useAppContext();
  const { logIn } = useAuth();

  return (
    <Layout>
      <section className={`${styles.formSection} columns`}>
        <div
          className={`${styles.formContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <div className={`${styles.formContent}`}>
            <h1
              className={`${styles.sectionTitle} title is-4 has-text-white has-text-centered`}
            >
              Before we continue,
              <br /> please create a password.
            </h1>
            <p className={`subtitle is-6 has-text-centered has-text-white`}>
              This will save your progress and help keep your personal
              information secure. If you take a break, you can return and pick
              right back up where you left off.
            </p>

            <Formik
              enableReinitialize
              initialValues={{
                email: applicantInfo.email || '',
                pwd: '',
                pwdConfirmation: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                registerUser(values, applicantInfo.profileId)
                  .then(res => {
                    const { access, refresh } = res.data;
                    logIn(access, refresh);
                    actions.setSubmitting(false);
                    navigate('/profile/education');
                  })
                  .catch(() => {
                    actions.setSubmitting(false);
                    actions.setStatus({ apiError: true });
                  });
              }}
            >
              {({ values, isSubmitting, status }) => (
                <Form>
                  <div className="field">
                    <Input
                      name={`email`}
                      labelText="Email address"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="email"
                      disabled={!!applicantInfo.email}
                    />
                  </div>
                  <div className="field">
                    <Input
                      type="password"
                      name={`pwd`}
                      labelText="Password"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="field">
                    <Input
                      type="password"
                      name={`pwdConfirmation`}
                      labelText="Retype Password"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="new-password"
                    />
                  </div>

                  {status && status.apiError ? (
                    <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_SAVE} />
                  ) : null}

                  <div className="has-text-centered">
                    <button
                      type="submit"
                      className={`button is-primary ${styles.nextButton} ${
                        isSubmitting ? 'is-loading' : ''
                      }`}
                      disabled={isSubmitting}
                    >
                      <span>Next</span>
                      <span className="icon ">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Register;
