import React from 'react';
import Layout from '../../../../components/layout';
import sharedProfileStyles from '../../../common/components/profile-form/index.module.scss';
import styles from './index.module.scss';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const Resources = ({ data }) => {
  const getResourcesImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };

  return (
    <Layout>
      <section className={`${styles.contentSection} columns`}>
        <div
          className={`${styles.contentContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <h1
            className={`${sharedProfileStyles.sectionTitle} title is-3 has-text-info has-text-centered-mobile`}
          >
            Traveler Resources
          </h1>
          <p className={`subtitle is-6 has-text-centered-mobile`}>
            Welcome to your home base for helpful traveler info, like how to
            enroll in health insurance or how to view your paystubs.{' '}
            <strong>Questions?</strong> Contact your Recruiter or Advocate for
            immediate support.
          </p>

          <hr className={sharedProfileStyles.formDivider} />
          <h2 className={`has-text-centered-mobile ${styles.sectiontext}`}>
            IMPORTANT FILES
          </h2>

          <div className={`columns is-centered is-mobile is-multiline`}>
            <div
              className={`column is-centered has-text-centered is-half-mobile`}
            >
              <a href="/docs/NurseHandbook.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('handbook').node.childImageSharp.fluid
                  }
                  className={`${styles.image}`}
                />
              </a>
              <a href="/docs/NurseHandbook.pdf" target="_blank">
                <p className={`${styles.linktext}`}>2022 Employee Handbook</p>
              </a>
            </div>
            <div
              className={`column is-centered has-text-centered is-half-mobile`}
            >
              <a href="/docs/trustaff_Benefits_Packet.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('benefits').node.childImageSharp.fluid
                  }
                  className={`${styles.image}`}
                />
              </a>
              <a href="/docs/trustaff_Benefits_Packet.pdf" target="_blank">
                <p className={`${styles.linktext}`}>2022 Employee Benefits</p>
              </a>
            </div>
            <div
              className={`column is-centered has-text-centered is-half-mobile`}
            >
              <a href="/docs/trustaff_How_to_Enroll.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('benefitsinstructions').node
                      .childImageSharp.fluid
                  }
                  className={`${styles.image}`}
                />
              </a>
              <a href="/docs/trustaff_How_to_Enroll.pdf" target="_blank">
                <p className={`${styles.linktext}`}>
                  Benefits Signup Instructions
                </p>
              </a>
            </div>
            <div className={`column is-centered has-text-centered`}>
              <a href="/docs/truPerks_discount_codes.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('discounts').node.childImageSharp
                      .fluid
                  }
                  className={`${styles.image}`}
                />
              </a>
              <a href="/docs/truPerks_discount_codes.pdf" target="_blank">
                <p className={`${styles.linktext}`}>
                  TruPerks Employee Discounts
                </p>
              </a>
            </div>
          </div>

          <div className={`columns`}>
            <div className={`column is-centered has-text-centered`}>
              <a
                href="/docs/trustaff_Clinical_Incident_Form.docx"
                target="_blank"
              >
                <p className={`${styles.linktext}`}>Clinical Incident Form</p>
              </a>
            </div>
            <div className={`column is-centered has-text-centered`}>
              <a
                href="/docs/trustaff_Conflict_of_Interest.docx"
                target="_blank"
              >
                <p className={`${styles.linktext}`}>
                  Conflict of Interest Statement
                </p>
              </a>
            </div>
          </div>

          <hr className={sharedProfileStyles.formDivider} />
          <h2 className={`has-text-centered-mobile ${styles.sectiontext}`}>
            VIDEO TUTORIALS
          </h2>

          <div className={`columns`}>
            <div className={`column is-centered`}>
              <p className={`${styles.linktext}`}>
                How to submit your timesheet
              </p>
              <div className={`${styles.embedContainer}`}>
                <iframe
                  title="vimeoVideo"
                  src="https://player.vimeo.com/video/257505214"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
              <p
                className={`content has-text-centered-mobile ${styles.smalltext}`}
              >
                Each facility uses different methods of timekeeping. If you are
                using a Trustaff timesheet, uploading your weekly hours is easy!
              </p>
            </div>
            <div className={`column is-centered`}>
              <p className={`${styles.linktext}`}>How to view your paystubs</p>
              <div className={`${styles.embedContainer}`}>
                <iframe
                  title="vimeoVideo"
                  src="https://player.vimeo.com/video/281865587"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
              <p
                className={`content has-text-centered-mobile ${styles.smalltext}`}
              >
                Step-by-step instructions on how to log in to ADP and view your
                paystubs and view your W-2s at the end of the year
              </p>
            </div>
          </div>

          <hr className={sharedProfileStyles.formDivider} />
          <h2 className={`has-text-centered-mobile ${styles.sectiontext}`}>
            HELPFUL PDF GUIDES
          </h2>
          <div className={`columns is-centered is-mobile is-multiline`}>
            <div
              className={`column is-centered has-text-centered is-half-mobile`}
            >
              <a href="/docs/traveler_timeline.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('timeline').node.childImageSharp.fluid
                  }
                />
              </a>
              <a href="/docs/traveler_timeline.pdf" target="_blank">
                <p className={`${styles.linktext}`}>
                  How the travel process works
                </p>
              </a>
            </div>
            <div
              className={`column is-centered has-text-centered is-half-mobile`}
            >
              <a href="/docs/Interview_guide.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('interview').node.childImageSharp
                      .fluid
                  }
                />
              </a>
              <a href="/docs/Interview_guide.pdf" target="_blank">
                <p className={`${styles.linktext}`}>
                  Interview tips for travelers
                </p>
              </a>
            </div>
            <div
              className={`column is-centered has-text-centered is-half-mobile`}
            >
              <a href="/docs/VA_interview_guide.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('voice_advantage').node
                      .childImageSharp.fluid
                  }
                />
              </a>
              <a href="/docs/VA_interview_guide.pdf" target="_blank">
                <p className={`${styles.linktext}`}>
                  Voice Advantage (VA) interview
                </p>
              </a>
            </div>
            <div
              className={`column is-centered has-text-centered is-half-mobile`}
            >
              <a href="/docs/PBDS_guide.pdf" target="_blank">
                <Img
                  fluid={
                    getResourcesImageEdge('pbds').node.childImageSharp.fluid
                  }
                />
              </a>
              <a href="/docs/PBDS_guide.pdf" target="_blank">
                <p className={`${styles.linktext}`}>
                  Preparing for the PBDS test
                </p>
              </a>
            </div>
          </div>

          <hr className={sharedProfileStyles.formDivider} />
          <h2 className={`has-text-centered-mobile ${styles.sectiontext}`}>
            TRUSTAFF TRAVELER ESSENTIALS
          </h2>
          <div className={`columns is-centered is-mobile is-multiline`}>
            <div className={`column is-full-mobile is-half-tablet`}>
              <p className={`${styles.linktext}`}>
                #1. Check your weekly newsletter
              </p>
              <p className={`${styles.smalltext}`}>
                While you're on assignment, you'll receive weekly email
                newsletters from home base full of important employee notices.
                <br />
                <br />
                Be on the lookout for helpful tips, high fives, behind the
                scenes info, and birthday giveaways. It's a great way to keep up
                with everything that's going on!
              </p>
              <Img
                fluid={
                  getResourcesImageEdge('newsletter').node.childImageSharp.fluid
                }
                className={`is-centered`}
              />
            </div>
            <div className={`column is-full-mobile is-half-tablet`}>
              <p className={`${styles.linktext}`}>
                #2. Enter the photo contest
              </p>
              <p className={`${styles.smalltext}`}>
                Our quarterly photo contest is one of our longest-standing
                Trustaff traditions! Each winner receives a $100 giftcard and a
                spot on the wall of fame!
                <br />
                <br />
                Tag your photos on Instagram with #TrustaffTraveler or send
                photos to your recruiter to enter, and search for fellow
                travelers using the #TrustaffTraveler tag!
              </p>
              <Img
                fluid={
                  getResourcesImageEdge('photocontest').node.childImageSharp
                    .fluid
                }
                className={`is-centered`}
              />
            </div>
            <div className={`column is-full-mobile is-half-tablet`}>
              <p className={`${styles.linktext}`}>
                #3. Earn extra referral bonuses
              </p>
              <p className={`${styles.smalltext}`}>
                When you refer someone who takes an assignment with Trustaff,
                we'll return the favor by paying you a referral bonus!
                <br />
                <br />
                There's no limit to how many referral bonuses you can earn. Fill
                out our <Link to="/referrals/">referral form</Link> or ask your
                recruiter for more details.
              </p>
              <Img
                fluid={
                  getResourcesImageEdge('trustaffwebsite').node.childImageSharp
                    .fluid
                }
                className={`is-centered`}
              />
            </div>
            <div className={`column is-full-mobile is-half-tablet`}>
              <p className={`${styles.linktext}`}>
                #4. Claim your TruPerks discounts
              </p>
              <p className={`${styles.smalltext}`}>
                Trustaff travelers enjoy a wide range of employee discounts just
                for being a part of the team!
                <br />
                <br />
                TruPerks include things like fitness programs, meal delivery,
                discount movie tickets, event tickets, hotel and travel costs,
                and even things like scrubs, shoes, and stethoscopes.
              </p>
              <Img
                fluid={
                  getResourcesImageEdge('truperks').node.childImageSharp.fluid
                }
                className={`is-centered`}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Resources;
