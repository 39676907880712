/*eslint-disable no-var */

function getYearsDropdownOptions() {
  const d = new Date();
  const minYear = d.getFullYear() - 75;
  const maxYear = d.getFullYear() + 5;
  let years = [];

  for (var year = maxYear; year >= minYear; year--) {
    years.push(year);
  }
  return years.map(year => ({ value: year, name: year.toString() }));
}

export const yearsDropdownOptions = getYearsDropdownOptions();
