import React, { useEffect, useRef, useState } from 'react';
import sharedProfileStyles from '../profile-form/index.module.scss';
import Input from '../forms/formik-inputs/input';
import Select from '../forms/formik-inputs/select';
import Checkbox from '../forms/formik-inputs/checkbox';
import Textarea from '../forms/formik-inputs/textarea';
import Multiselect from '../forms/formik-inputs/multiselect';
import { professions } from '../../../../constants/professions';
import { useAppContext } from '../../../../providers/app-provider';
import { FormDatePickerInput } from '../forms/formik-inputs/date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import styles from './history-entry.module.scss';
import { getFormattedDate } from '../../../../utils/format-helpers';
import apiService from '../../../../services/api-service';
import { US_ID } from '../../../../constants/personal-info';
import { stateOptionsByCountryId } from '../../../../utils/profile-utils';

const HistoryEntry = ({
  index,
  setFieldTouched,
  handleChange,
  setFieldValue,
  stateOptions,
  countryOptions,
  values,
  unitTypeOptions,
  isViewingSuggestions,
  hasUnitLevel,
  unitTypeIdsRequiringUnitLevelSelection,
  unitLevelOptions,
  workTypeOptions,
  doesWorkTypeRequireAgencyName,
  remove,
  isEntryInitiallyOpen,
  errors,
}) => {
  const { professionId } = useAppContext();
  const [professionOptions, setprofessionOptions] = useState([]);
  const [isEntryOpen, setIsEntryOpen] = useState(true);
  const ref = useRef(null);
  const hasErrors = Object.keys(errors).length > 0;
  const OTHER = stateOptions.find(s => s.name === 'Other');
  const UNITED_STATES = countryOptions.find(c => c.name === 'United States');

  const getFormattedStartAndEndDates = () => {
    const startDate = getFormattedDate(values.startDate);
    const endDate = values.endDate
      ? getFormattedDate(values.endDate)
      : 'Present';

    return `${startDate} - ${endDate}`;
  };

  const handleCloseButtonClick = () => {
    if (!values.facility.name || hasErrors) {
      setAllFieldsTouchedToTriggerErrorMessageDisplay();
      return;
    }
    setIsEntryOpen(false);
  };

  const setAllFieldsTouchedToTriggerErrorMessageDisplay = () => {
    const names = [];
    Object.keys(errors?.data).forEach(key => {
      if (typeof errors.data[key] === 'object') {
        const nestedObject = errors.data[key];
        Object.keys(nestedObject).forEach(nestedKey => {
          names.push(`${key}.${nestedKey}`);
        });
      } else {
        names.push(key);
      }
    });

    names.forEach(name =>
      setFieldTouched(`workHistoryEntries[${index}].data.${name}`)
    );
  };

  const onCountryIdChange = event => {
    handleChange(event);
    if (parseInt(event.target.value, 10) !== UNITED_STATES.value) {
      setFieldValue(
        `workHistoryEntries[${index}].data.facility.stateId`,
        OTHER.value,
        false
      );
    }
  };

  const isNewlyAddedEntry = values.id === undefined;

  useEffect(() => {
    if (!isNewlyAddedEntry && !isViewingSuggestions) {
      setIsEntryOpen(isEntryInitiallyOpen);
    }
  }, [isEntryInitiallyOpen]);

  useEffect(() => {
    if (isNewlyAddedEntry && ref.current && isEntryOpen) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isEntryOpen]);

  useEffect(() => {
    apiService.get('/api/professions').then(({ data }) => {
      const options = data
        .map(({ id, name }) => ({ name, value: id }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setprofessionOptions(options);
    });
  }, []);

  return (
    <>
      {isEntryOpen === false ? (
        <button
          className={`button is-fullwidth is-size-6 is-outlined ${
            styles.entryButton
          } ${
            hasErrors
              ? `is-danger ${styles.mobileEntryButtonError}`
              : `is-info `
          }`}
          type="button"
          onClick={() => setIsEntryOpen(true)}
        >
          <h3
            className={`has-text-weight-bold has-text-centered-mobile has-text-left ${
              hasErrors ? styles.mobileErrorColor : styles.mobileInfoColor
            } ${styles.entryButtonTextField}`}
          >
            {values.facility.name}
          </h3>
          <h4
            className={`has-text-left has-text-centered-mobile ${styles.entryButtonTextField} ${styles.entryButtonDates}`}
          >
            {getFormattedStartAndEndDates()}
          </h4>

          <span
            className={`${
              hasErrors ? styles.mobileErrorColor : styles.mobileInfoColor
            } icon has-text-right`}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </button>
      ) : (
        <>
          <div ref={ref} className={`card ${styles.card}`}>
            <div className={`${styles.openEntryHeadingContainer}`}>
              <h4
                className={`is-size-5 has-text-info has-text-weight-bold ${styles.openEntryTitle}`}
              >
                Add employment details
              </h4>
              {!isViewingSuggestions && (
                <div className={`has-text-right ${styles.closeIconButton}`}>
                  <button
                    className={`button is-info is-outlined `}
                    type="button"
                    onClick={handleCloseButtonClick}
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={faAngleUp} />
                    </span>
                  </button>
                </div>
              )}
            </div>

            <div className={styles.formContentSection}>
              <div className="field">
                <Input
                  name={`workHistoryEntries[${index}].data.facility.name`}
                  labelText="Facility name"
                  labelClass={`is-size-7`}
                  shouldCheckForCapsLock={true}
                />
              </div>
              <div className="field">
                <Select
                  name={`workHistoryEntries[${index}].data.facility.countryId`}
                  labelText="Country"
                  options={countryOptions}
                  placeholderText="Select a country"
                  labelClass={`is-size-7`}
                  selectWrapperClass={`is-fullwidth`}
                  allowDeselect={true}
                  onChange={onCountryIdChange}
                />
              </div>
              <div className="field field-body">
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`workHistoryEntries[${index}].data.facility.city`}
                    labelText="City"
                    labelClass={`is-size-7`}
                    shouldCheckForCapsLock={true}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`workHistoryEntries[${index}].data.facility.stateId`}
                    labelText="State"
                    options={stateOptionsByCountryId(stateOptions, US_ID)}
                    placeholderText="Select a state"
                    labelClass={`is-size-7`}
                    selectWrapperClass={`is-fullwidth`}
                    allowDeselect={true}
                    disabled={
                      !UNITED_STATES ||
                      parseInt(values.facility.countryId, 10) !==
                        UNITED_STATES.value
                    }
                  />
                </div>
              </div>

              <div className="field">
                <Select
                  name={`workHistoryEntries[${index}].data.professionId`}
                  labelText="Profession"
                  options={professionOptions}
                  placeholderText="Select a profession"
                  labelClass={`is-size-7`}
                  selectWrapperClass={`is-fullwidth`}
                />
              </div>
              <div className={`field `}>
                <Multiselect
                  values={values.unitTypeIds}
                  options={unitTypeOptions}
                  name={`workHistoryEntries[${index}].data.unitTypeIds`}
                  placeholderText="Select units worked"
                  labelText="Primary unit worked"
                  labelClasses="is-size-7"
                  mobileSelectClasses={`is-fullwidth`}
                  dropdownClasses={styles.unitLevelsSelectContainer}
                  isViewingSuggestions={isViewingSuggestions}
                />
              </div>

              <div className={`field field-body`}>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`workHistoryEntries[${index}].data.bedsInPrimaryUnit`}
                    labelText={
                      <>
                        Number of beds in primary unit <em>(optional)</em>
                      </>
                    }
                    labelClass={`is-size-7`}
                    controlClass={
                      hasUnitLevel(
                        values.unitTypeIds,
                        unitTypeIdsRequiringUnitLevelSelection
                      )
                        ? ''
                        : styles.inputHalfWidth
                    }
                  />
                </div>
                {hasUnitLevel(
                  values.unitTypeIds,
                  unitTypeIdsRequiringUnitLevelSelection
                ) && (
                  <div
                    className={`field ${sharedProfileStyles.groupedFormField}`}
                  >
                    <Select
                      name={`workHistoryEntries[${index}].data.primaryUnitLevelId`}
                      labelText="Primary unit level"
                      labelClass={`is-size-7`}
                      selectWrapperClass={`is-fullwidth`}
                      options={unitLevelOptions}
                    />
                  </div>
                )}
              </div>

              <div className={`field field-body`}>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`workHistoryEntries[${index}].data.floatUnitTypes`}
                    labelText={
                      <>
                        Float unit/specialty <em>(optional)</em>
                      </>
                    }
                    options={unitTypeOptions}
                    placeholderText="Select a float unit/specialty"
                    labelClass={`is-size-7`}
                    selectWrapperClass={`is-fullwidth`}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  {values.floatUnitTypes && (
                    <Input
                      name={`workHistoryEntries[${index}].data.numberOfFloatShiftsPerMonth`}
                      labelText={
                        <>
                          # of float shifts per month <em>(optional)</em>
                        </>
                      }
                      labelClass={`is-size-7`}
                    />
                  )}
                </div>
              </div>

              <div className="field field-body">
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`workHistoryEntries[${index}].data.patientToNurseRatio`}
                    labelText={
                      <>
                        Nurse to patient ratio{' '}
                        {professionId === professions.ALLIED && (
                          <em>(optional)</em>
                        )}
                      </>
                    }
                    adjacentElement={
                      <p className={`is-size-6 ${styles.ratioText}`}>1 to</p>
                    }
                    labelClass={`is-size-7`}
                    controlClass={styles.ratioGroup}
                  />
                  <p className={`${styles.helper}`}>
                    If unsure or not applicable, please put 1
                  </p>
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`workHistoryEntries[${index}].data.hoursPerWeek`}
                    labelText="Hours per week"
                    labelClass={`is-size-7`}
                  />
                </div>
              </div>

              <div className="field">
                <Input
                  name={`workHistoryEntries[${index}].data.chartingSystem`}
                  labelText={
                    <>
                      Charting system{' '}
                      {professionId === professions.ALLIED && (
                        <em>(optional)</em>
                      )}
                    </>
                  }
                  labelClass={`is-size-7`}
                  shouldCheckForCapsLock={true}
                />
                <p className={`${styles.helper}`}>
                  If unsure or not applicable, please put N/A
                </p>
              </div>

              <div className={`field field-body`}>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`workHistoryEntries[${index}].data.workTypeId`}
                    labelText="Work type"
                    labelClass={`is-size-7`}
                    selectWrapperClass={`is-fullwidth`}
                    options={workTypeOptions}
                  />
                </div>

                <div
                  className={`field ${sharedProfileStyles.groupedFormField} ${
                    doesWorkTypeRequireAgencyName(values.workTypeId)
                      ? ''
                      : 'is-invisible-tablet is-hidden-mobile'
                  }`}
                >
                  <Input
                    name={`workHistoryEntries[${index}].data.travelAgencyName`}
                    labelText="Agency name"
                    labelClass={`is-size-7`}
                    shouldCheckForCapsLock={true}
                  />
                </div>
              </div>

              <div className={`field field-body`}>
                <Checkbox
                  name={`workHistoryEntries[${index}].data.chargeNursePosition`}
                  labelText="Charge nurse position"
                  labelClass={`label`}
                />
              </div>
              <div className={`field field-body`}>
                <Checkbox
                  name={`workHistoryEntries[${index}].data.isCurrentlyEmployed`}
                  labelText="Currently employed at this position"
                  labelClass={`label`}
                />
              </div>
              <div className={`field field-body`}>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <FormDatePickerInput
                    name={`workHistoryEntries[${index}].data.startDate`}
                    labelText="Start date"
                    labelClass={`is-size-7`}
                  />
                </div>

                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <FormDatePickerInput
                    name={`workHistoryEntries[${index}].data.endDate`}
                    labelText={
                      values.isCurrentlyEmployed ? (
                        <>
                          End date <em>(optional)</em>
                        </>
                      ) : (
                        'End date'
                      )
                    }
                    labelClass={`is-size-7`}
                  />
                </div>
              </div>

              <div className={`field `}>
                <Textarea
                  name={`workHistoryEntries[${index}].data.additionalInfo`}
                  labelText={
                    <>
                      Please add any important notes about the unit where you
                      worked, such as level/acuity, is this a teaching facility,
                      awards/honors, etc.
                    </>
                  }
                  labelClass={`is-size-7`}
                  shouldCheckForCapsLock={true}
                />
              </div>

              {!isViewingSuggestions && (
                <div className={styles.entryActionButtonsContainer}>
                  <button
                    type="button"
                    className={`button is-info is-small ${styles.entryActionButton}`}
                    onClick={handleCloseButtonClick}
                  >
                    Save &amp; Continue
                  </button>

                  <button
                    type="button"
                    className={`button is-small is-danger is-outlined ${styles.entryActionButton}`}
                    onClick={() => remove(index)}
                  >
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faMinus} />
                    </span>
                    <span>Remove employer</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HistoryEntry;
