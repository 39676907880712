import React from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';
import styles from './file-input.module.scss';

const FileInput = ({ name, labelText, changeFileText, onChange, accept }) => {
  const [, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const hasName = meta.value && meta.value.length > 0;

  return (
    <div className={`control`}>
      <div
        className={`file is-info ${styles.file} ${
          hasName ? `has-name ${styles.hasName}` : ''
        }`}
      >
        <label className={`file-label ${styles.fileLabel}`}>
          <input
            className="file-input"
            accept={accept}
            type="file"
            name={name}
            onChange={e => {
              const file = e.target.files[0];
              if (file) {
                const fileName = file.name;
                setValue(fileName);
                onChange(e);
              }
            }}
          />
          <span className={`file-cta ${styles.fileCta}`}>
            <span className="file-icon">
              <span className="icon ">
                <FontAwesomeIcon icon={faUpload} />
              </span>
            </span>
            <span className={`file-label`}>
              {hasName ? changeFileText : labelText}
            </span>
          </span>
          {hasName && (
            <span className={`file-name ${styles.fileName}`}>{meta.value}</span>
          )}
        </label>
      </div>

      <ErrorMessage name={name}>
        {message => <p className="help has-text-danger">{message}</p>}
      </ErrorMessage>
    </div>
  );
};

FileInput.propTypes = {
  accept: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  changeFileText: PropTypes.string,
};

FileInput.defaultProps = {
  accept: 'image/*',
  labelText: 'Choose a file',
  changeFileText: 'Change uploaded file',
};

export default FileInput;
