import React, { useEffect, useState } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { Link, navigate } from '@reach/router';
import { useAppContext } from '../../../../providers/app-provider';

import { getTokenFromUrlParam } from '../../../../utils/url-helpers';
import { useUserApi } from '../../../../services/user-service';
import Input from '../../../common/components/forms/formik-inputs/input';
import Modal from '../../../../components/modal';
import PopupNotification from '../../../../components/popup-notification';
import validationSchema from './validation-schema';
import styles from './index.module.scss';
import Layout from '../../../../components/layout';
import { CAPS_LOCK_WARNING } from '../../../../constants/misc-client-messges';
import { useAuth } from '../../../../providers/auth-provider';

const ChangePassword = () => {
  const { changePassword } = useUserApi();
  const { isCapsLockActive } = useAppContext();
  const { getLoginPathBasedOnTalentStackActiveFlag } = useAuth();
  const [resetToken, setResetToken] = useState(null);

  const handleRequestSuccess = actions => {
    actions.setSubmitting(false);
    actions.setStatus({
      apiSuccess: true,
    });
  };

  const handleRequestFailure = actions => {
    actions.setSubmitting(false);

    actions.setStatus({
      apiError: 'There was a problem changing your password.',
    });
  };

  const handleModalExit = () => {
    navigate(getLoginPathBasedOnTalentStackActiveFlag());
  };

  useEffect(() => {
    const token = getTokenFromUrlParam();

    if (token) {
      setResetToken(token);
    }
  }, []);

  return (
    <Layout>
      <section className={`${styles.formSection} columns`}>
        <div
          className={`${styles.formContainer} section column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop`}
        >
          <div className={`${styles.formContent}`}>
            <h1
              className={`${styles.sectionTitle} title is-4 has-text-white has-text-centered`}
            >
              Change Password
            </h1>
            <p className={`subtitle is-6 has-text-centered has-text-white`}>
              Please enter a new password.
            </p>

            <Formik
              initialValues={{
                pwd: '',
                pwdConfirmation: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                changePassword(values.pwd, resetToken)
                  .then(() => {
                    handleRequestSuccess(actions);
                  })
                  .catch(() => {
                    handleRequestFailure(actions);
                  });
              }}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <div className="field">
                    <Input
                      type="password"
                      name={`pwd`}
                      labelText="Password"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="new-password"
                      shouldCheckForCapsLock={true}
                    />
                  </div>
                  <div className="field">
                    <Input
                      type="password"
                      name={`pwdConfirmation`}
                      labelText="Retype Password"
                      labelClass="has-text-white"
                      errorClass="has-text-white"
                      autoComplete="new-password"
                      shouldCheckForCapsLock={true}
                    />
                  </div>

                  <Modal
                    title="Password Changed"
                    message={
                      <>
                        Your password has been updated.
                        <br />
                        <br />
                        You can now sign in with your new password.
                      </>
                    }
                    isOpen={status && status.apiSuccess ? true : false}
                    confirmButtonText={'Sign In'}
                    confirmButtonModifierClass={`is-info`}
                    onConfirm={handleModalExit}
                    onCancel={handleModalExit}
                    messageModifierClass={`is-info`}
                    showCancelButton={false}
                  />

                  {status && status.apiError && (
                    <p className="notification is-danger">
                      {status.apiError} Please{' '}
                      <Link to="/profile/forgot-password/">
                        reset your password
                      </Link>{' '}
                      again or
                      {` `}
                      <a href="/contact-us">contact us</a> if the problem
                      persists.
                    </p>
                  )}

                  <div className="has-text-centered">
                    <button
                      type="submit"
                      className={`button is-primary ${styles.nextButton} ${
                        isSubmitting ? 'is-loading' : ''
                      }`}
                      disabled={isSubmitting}
                    >
                      <span>Update Password</span>
                      <span className="icon ">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {isCapsLockActive && (
          <PopupNotification
            message={CAPS_LOCK_WARNING}
            duration="persistent"
            showCloseIcon={false}
            notificationStyleClass="is-danger"
          />
        )}
      </section>
    </Layout>
  );
};

export default ChangePassword;
