import React, { useEffect, useState } from 'react';

import sharedProfileStyles from '../profile-form/index.module.scss';
import Select from '../forms/formik-inputs/select';
import { useStatesApi } from '../../../../services/states-service';

const ExpertiseStateOfResidence = ({
  formValues,
  isSuggestion,
  isViewingSuggestions,
}) => {
  const { getAllStatesAsValueNameObjects } = useStatesApi();
  const [stateOptions, setStateOptions] = useState([]);
  const [
    userSelectedValueInCurrentSession,
    setUserSelectedValueInCurrentSession,
  ] = useState(false);

  useEffect(() => {
    getAllStatesAsValueNameObjects().then(states => {
      setStateOptions(states);
    });
  }, []);

  const expertiseStateOfResidenceFieldName = 'stateOfResidenceId';
  const stateIdHasValue = !!formValues[expertiseStateOfResidenceFieldName];
  isSuggestion = isSuggestion === true;

  const handleUserStateSelection = () => {
    setUserSelectedValueInCurrentSession(true);
  };

  const shouldFieldBeHidden = () => {
    return (
      isViewingSuggestions ||
      (!userSelectedValueInCurrentSession && !isSuggestion && stateIdHasValue)
    );
  };

  const doHideField = shouldFieldBeHidden();

  return (
    <>
      <div
        className={`field ${sharedProfileStyles.groupedFormField} ${
          doHideField ? 'is-hidden' : ''
        }`}
      >
        <Select
          name={expertiseStateOfResidenceFieldName}
          labelText="State of Residence"
          options={stateOptions}
          placeholderText="Select a state"
          labelClass={`is-size-7`}
          selectWrapperClass={`is-fullwidth`}
          disabled={isSuggestion}
          onChange={() => handleUserStateSelection()}
        />
      </div>
      <hr
        className={`${sharedProfileStyles.formDivider} ${
          doHideField ? 'is-hidden' : ''
        }`}
      />
    </>
  );
};

export default ExpertiseStateOfResidence;
