import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const ViewingSuggestionsBanner = ({ isViewingSuggestions }) =>
  isViewingSuggestions ? (
    <div className={`notification is-info ${styles.viewingSuggestionsBanner}`}>
      <p className={styles.bannerCopy}>
        You are currently viewing changes suggested by the Trustaff team. Click
        "Accept changes" if you approve of all changes on this form. Click
        "Reject changes" if there are any changes that you do not approve.
      </p>
    </div>
  ) : null;

ViewingSuggestionsBanner.propTypes = {
  isViewingSuggestions: PropTypes.bool.isRequired,
};

export default ViewingSuggestionsBanner;
