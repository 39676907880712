import { useEffect } from 'react';

const UnsavedChangesWarning = ({ dirty }) => {
  const onUnload = event => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (dirty) {
      window.addEventListener('beforeunload', onUnload, false);
      return () => {
        window.removeEventListener('beforeunload', onUnload, false);
      };
    }
  }, [dirty]);

  return null;
};

export default UnsavedChangesWarning;
