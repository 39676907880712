import React, { useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import { useStatesApi } from '../../../../services/states-service';
import { FormDatePickerInput } from '../forms/formik-inputs/date-picker';
import Input from '../forms/formik-inputs/input';
import Select from '../forms/formik-inputs/select';
import styles from './index.module.scss';
import sharedProfileStyles from '../profile-form/index.module.scss';

const LicensesFieldArray = ({ values }) => {
  const { getAllStatesAsValueNameObjects } = useStatesApi();
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    getAllStatesAsValueNameObjects().then(states => {
      setStateOptions(states);
    });
  }, []);

  return (
    <FieldArray name="licenses">
      {({ push, remove }) => (
        <>
          {values.map((licenses, index) => (
            <div key={index}>
              <hr className={sharedProfileStyles.formDivider} />

              <div className="field field-body">
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Input
                    name={`licenses[${index}].licenseNumber`}
                    labelText="License number"
                    labelClass={`is-size-7`}
                    preventCapsLockCheck={true}
                  />
                </div>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <Select
                    name={`licenses[${index}].issuingStateId`}
                    labelText="State issued"
                    options={stateOptions}
                    placeholderText="Select a state"
                    labelClass={`is-size-7`}
                    selectWrapperClass={`is-fullwidth`}
                  />
                </div>
              </div>

              <div className={`field field-body`}>
                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <FormDatePickerInput
                    name={`licenses[${index}].issuedDate`}
                    labelText="Date issued"
                    labelClass={`is-size-7`}
                  />
                </div>

                <div
                  className={`field ${sharedProfileStyles.groupedFormField}`}
                >
                  <FormDatePickerInput
                    name={`licenses[${index}].expirationDate`}
                    labelText="Expiration date"
                    labelClass={`is-size-7`}
                  />
                </div>
              </div>

              <div className={`${sharedProfileStyles.removeButtonContainer}`}>
                <button
                  type="button"
                  className="button is-small is-danger is-outlined"
                  onClick={() => remove(index)}
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faMinus} />
                  </span>
                  <span>Remove license</span>
                </button>
              </div>
            </div>
          ))}
          <div className={`field ${styles.addButton}`}>
            <button
              type="button"
              className="button is-info is-small"
              onClick={() =>
                push({
                  licenseNumber: '',
                  issuingStateId: '',
                  issuedDate: '',
                  expirationDate: '',
                })
              }
            >
              <span className="icon is-small">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span>Add license</span>
            </button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default LicensesFieldArray;
