import React from 'react';
import PropTypes from 'prop-types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useProfileCompletionApi } from '../../../../services/profile-completion-service';

const ProfileFormNextButton = ({
  dirty,
  isSubmitting,
  isNavigatingBack,
  setIsNavigatingBack,
  className,
  isSuggestion,
  completionStatuses,
  currentPagePath,
  buttonText,
}) => {
  const { areAnySectionsUnfinished } = useProfileCompletionApi();

  const getNextButtonText = (
    isSuggestion,
    completionStatuses,
    currentPagePath
  ) => {
    if (buttonText) return buttonText;
    if (isSuggestion) return 'Suggest changes';

    return areAnySectionsUnfinished(completionStatuses, currentPagePath) ? (
      <>Save &amp; Continue</>
    ) : (
      'Finish'
    );
  };

  const isDisabled = isSubmitting || (isSuggestion && !dirty);

  return (
    <button
      onClick={() => setIsNavigatingBack(false)}
      type="submit"
      className={`button is-primary ${className}
      } ${isSubmitting && !isNavigatingBack ? 'is-loading' : ''}`}
      disabled={isDisabled}
    >
      <span>
        {getNextButtonText(isSuggestion, completionStatuses, currentPagePath)}
      </span>
      <span className="icon ">
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </button>
  );
};

ProfileFormNextButton.propTypes = {
  className: PropTypes.string.isRequired,
  completionStatuses: PropTypes.array.isRequired,
  currentPagePath: PropTypes.string.isRequired,
  dirty: PropTypes.bool.isRequired,
  isNavigatingBack: PropTypes.bool.isRequired,
  isSuggestion: PropTypes.bool.isRequired,
  setIsNavigatingBack: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

export default ProfileFormNextButton;
