import React from 'react';
import PropTypes from 'prop-types';

import Input from './input';

const TelephoneInput = props => (
  <Input
    type="tel"
    mask={[
      '(',
      /[2-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    {...props}
  />
);

export default TelephoneInput;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  labelClass: PropTypes.string,
  errorClass: PropTypes.string,
  mask: PropTypes.array,
};

Input.defaultProps = {
  labelText: '',
  placeholder: '',
  type: 'text',
  labelClass: '',
  errorClass: 'has-text-danger',
  mask: null,
};
