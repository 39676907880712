import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useAgreementsApi } from '../../../../services/agreements-service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errorMessages } from '../../../../constants/error-messages';
import { useAuth } from '../../../../providers/auth-provider';
import LoadingSpinner from '../../../../components/loading-spinner';
import Layout from '../../../../components/layout';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import Modal from '../../../../components/modal';
import { getFormattedDate } from '../../../../utils/format-helpers';

const PayrollDeductionForm = ({ documentId }) => {
  const { getPayrollDeductionAgreement, acceptPayrollDeduction } =
    useAgreementsApi();
  const { getProfileId } = useAuth();
  const profileId = getProfileId();

  const [agreementText, setAgreementText] = useState('');
  const [signedInfo, setSignedInfo] = useState({
    isSigned: false,
    signedDate: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [acctNumLastFour, setAcctNumLastFour] = useState('');
  const [formState, setFormState] = useState({
    isSubmitting: false,
    isSubmitted: false,
    isInvalid: false,
    hasSubmissionFailure: false,
  });

  const fourDigitsMatchingRegex = /^[0-9]{4}$/;

  const handleSubmit = () => {
    const isValid = checkIsValid(acctNumLastFour);
    if (!isValid) {
      setFormState({ ...formState, isInvalid: true });
      return;
    }

    setFormState({
      ...formState,
      isSubmitting: true,
      isInvalid: false,
    });
    acceptPayrollDeduction(acctNumLastFour, documentId, profileId)
      .then(() => {
        setFormState({
          isSubmitted: true,
          isSubmitting: false,
          isInvalid: false,
          hasSubmissionFailure: false,
        });
      })
      .catch(() => {
        setFormState({
          isSubmitting: false,
          isSubmitted: false,
          isInvalid: false,
          hasSubmissionFailure: true,
        });
      });
  };

  useEffect(() => {
    getPayrollDeductionAgreement(documentId)
      .then(({ data }) => {
        setAgreementText(data.text);
        setSignedInfo({ isSigned: data.isSigned, signedDate: data.signedTime });
      })
      .catch(() => setHasFetchError(true))
      .finally(() => setIsLoading(false));
  }, []);

  const checkIsValid = value => {
    return fourDigitsMatchingRegex.test(value);
  };

  const InvalidInputMessage = () => {
    return (
      <div>
        <p className="help is-danger">
          Please enter only the last 4 digits of the account you wish to make
          deductions from.
        </p>
      </div>
    );
  };
  return (
    <Layout>
      <div className={`columns is-centered ${styles.section}`}>
        <section className={'column is-10-tablet is-10-desktop is-centered'}>
          <LoadingSpinner isLoading={isLoading} />

          {hasFetchError && (
            <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
          )}

          {agreementText.length > 0 && (
            <>
              <label
                className={`title is-3 has-text-info`}
                htmlFor="payroll-deduction-agreement-text"
              >
                Payroll Deduction Agreement
              </label>
              <textarea
                className={`textarea  ${styles.agreement}`}
                name="payroll-deduction-agreement-text"
                rows={20}
                readOnly
                value={agreementText}
              ></textarea>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {signedInfo.isSigned ? (
                  <span className={`${styles.signedDate}`}>
                    Signed on {getFormattedDate(signedInfo.signedDate)}
                  </span>
                ) : (
                  <div className={`control ${styles.inputForm}`}>
                    <label className="label" htmlFor="account-number-last-four">
                      Last 4 Digits Of Account Number
                    </label>
                    <input
                      name="account-number-last-four"
                      className="input"
                      type="text"
                      value={acctNumLastFour}
                      onChange={e => setAcctNumLastFour(e.target.value)}
                    />
                    {formState.isInvalid && <InvalidInputMessage />}

                    <button
                      className={`button is-primary ${styles.acceptButton} ${
                        formState.isSubmitting ? 'is-loading' : ''
                      }`}
                      type="submit"
                      disabled={formState.isSubmitting}
                    >
                      <span className="icon ">
                        <FontAwesomeIcon icon={faEdit} />
                      </span>
                      <span>Sign Contract</span>
                    </button>
                  </div>
                )}

                <Modal
                  title="Payroll Deduction Agreement Signed!"
                  message={
                    <>
                      Thank you for signing your payroll deduction agreement.
                      <br />
                      <br />
                      You may close this tab or return to Trustaff.com.
                    </>
                  }
                  isOpen={formState.isSubmitted}
                  confirmButtonText={'Back to Trustaff.com'}
                  confirmButtonModifierClass={`is-info`}
                  onConfirm={() => navigate('/')}
                  onCancel={() => navigate('/')}
                  messageModifierClass={`is-info`}
                />
                {formState.hasSubmissionFailure && (
                  <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_SAVE} />
                )}
              </form>
            </>
          )}
        </section>
      </div>
    </Layout>
  );
};

export default PayrollDeductionForm;
