import React from 'react';
import { useFormikContext } from 'formik';
import Input from '../../../common/components/forms/formik-inputs/input';
import Checkbox from '../../../common/components/forms/formik-inputs/checkbox';
import Select from '../../../common/components/forms/formik-inputs/select';
import sharedProfileStyles from '../../../common/components/profile-form/index.module.scss';
import { COUNTRY_OPTIONS } from '../../../../constants/personal-info';
import {
  getCountryIdByStateItem,
  stateOptionsByCountryId,
} from '../../../../utils/profile-utils';

const StateAndMailAddress = ({ stateOptions, shouldDisableAddress }) => {
  const {
    values: {
      country,
      mailCountry,
      mailAddressIsSame,
      mailStateId,
      mailStreetAddress,
      mailStreetAddress2,
      mailCity,
      mailZipCode,
      stateId,
      zipCode,
      city,
      streetAddress,
      streetAddress2,
    },

    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    if (
      mailStateId === stateId &&
      mailStreetAddress === streetAddress &&
      mailStreetAddress2 === streetAddress2 &&
      mailCity === city &&
      mailZipCode === zipCode &&
      mailCountry === country
    ) {
      setFieldValue('mailAddressIsSame', true);
    }
  }, [
    mailStateId,
    mailStreetAddress,
    mailStreetAddress2,
    mailCity,
    mailZipCode,
    stateId,
    zipCode,
    city,
    streetAddress,
    streetAddress2,
  ]);

  React.useEffect(() => {
    if (stateId && !country) {
      setFieldValue('country', getCountryIdByStateItem(stateId, stateOptions));
    }

    if (mailStateId && !mailCountry) {
      setFieldValue(
        'mailCountry',
        getCountryIdByStateItem(mailStateId, stateOptions)
      );
    }
  }, [stateId, mailStateId, stateOptions, country, mailCountry]);

  return (
    <>
      <div className="field field-body">
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Select
            name="country"
            labelText="Country"
            options={COUNTRY_OPTIONS}
            allowDeselect
            placeholderText="Select a country"
            labelClass="is-size-7"
            selectWrapperClass="is-fullwidth"
            disabled={shouldDisableAddress}
          />
        </div>
        <div className={`field ${sharedProfileStyles.groupedFormField}`}>
          <Select
            name="stateId"
            labelText="State"
            options={stateOptionsByCountryId(stateOptions, country)}
            disabled={!country || shouldDisableAddress}
            allowDeselect
            placeholderText="Select a state"
            labelClass="is-size-7"
            selectWrapperClass="is-fullwidth"
          />
        </div>
      </div>

      <div className={`field ${sharedProfileStyles.groupedFormField}`}>
        <Checkbox
          name="mailAddressIsSame"
          labelText="Use this address as my mailing address"
          labelClass="is-size-7"
        />
      </div>

      {!mailAddressIsSame && (
        <>
          <hr className={sharedProfileStyles.formDivider} />
          <div className="field">
            <Input
              name="mailStreetAddress"
              labelText="Mail Address 1"
              labelClass="is-size-7"
              shouldCheckForCapsLock={true}
            />
          </div>
          <div className="field">
            <Input
              name={`mailStreetAddress2`}
              labelText="Mail Address 2"
              labelClass="is-size-7"
              shouldCheckForCapsLock={true}
            />
          </div>
          <div className="field field-body">
            <div className={`field ${sharedProfileStyles.groupedFormField}`}>
              <Input
                name="mailCity"
                labelText="Mail City"
                labelClass="is-size-7"
                shouldCheckForCapsLock={true}
              />
            </div>
            <div className={`field ${sharedProfileStyles.groupedFormField}`}>
              <Input
                name="mailZipCode"
                labelText="Mail Zip Code"
                labelClass="is-size-7"
              />
            </div>
          </div>
          <div className="field field-body">
            <div className={`field ${sharedProfileStyles.groupedFormField}`}>
              <Select
                name="mailCountry"
                labelText="Mail Country"
                options={COUNTRY_OPTIONS}
                allowDeselect
                placeholderText="Select a country"
                labelClass="is-size-7"
                selectWrapperClass="is-fullwidth"
              />
            </div>
            <div className={`field ${sharedProfileStyles.groupedFormField}`}>
              <Select
                name="mailStateId"
                labelText="Mail State"
                options={stateOptionsByCountryId(stateOptions, mailCountry)}
                allowDeselect
                placeholderText="Select a state"
                labelClass="is-size-7"
                selectWrapperClass="is-fullwidth"
                disabled={!mailCountry}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StateAndMailAddress;
