import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './deletable-tag.module.scss';
import PropTypes from 'prop-types';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const DeletableTag = ({
  value,
  onDelete,
  text,
  identifierString,
  isViewingSuggestions,
}) => {
  const generateRandomStringForUniqueId = () => {
    return Math.random().toString(10);
  };

  const handleClick = () => {
    if (isViewingSuggestions) return;
    else {
      onDelete(value);
    }
  };

  return (
    <span
      className={`tag is-small has-text-grey-dark is-rounded is-info is-light ${
        isViewingSuggestions ? styles.disabled : styles.arrowCursor
      }
        `}
    >
      {text}
      <span
        onClick={() => handleClick()}
        className={`icon ${styles.deleteIconContainer} ${
          isViewingSuggestions ? styles.disabled : styles.pointer
        }`}
        id={`${identifierString}-${generateRandomStringForUniqueId()}`}
      >
        <FontAwesomeIcon
          icon={faTimes}
          className={styles.deleteIcon}
          size="sm"
        />
      </span>
    </span>
  );
};

export default DeletableTag;

DeletableTag.propTypes = {
  value: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  identifierString: PropTypes.string.isRequired,
  isViewingSuggestions: PropTypes.bool.isRequired,
};
