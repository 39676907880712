import React, { useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
import { agreementText } from './agreement-text';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../../constants/error-messages';
import Layout from '../../../../components/layout';
import Modal from '../../../../components/modal';
import { useAuth } from '../../../../providers/auth-provider';
import { useSuggestionsApi } from '../../../../services/suggestions-service';
import styles from './index.module.scss';

const ChangesApprovalConsent = () => {
  const [accepted, setAccepted] = useState(false);
  const { getProfileId } = useAuth();
  const { approveForcedSuggestions } = useSuggestionsApi();

  const [formState, setFormState] = useState({
    isSubmitting: false,
    isSubmitted: false,
    isInvalid: false,
    hasSubmissionFailure: false,
  });

  const handleSubmit = () => {
    const isValid = accepted;
    if (!isValid) {
      setFormState({ ...formState, isInvalid: true });
      return;
    }

    setFormState({
      ...formState,
      isSubmitting: true,
      isInvalid: false,
      hasSubmissionFailure: false,
    });

    approveForcedSuggestions(getProfileId())
      .then(() => {
        setFormState({
          ...formState,
          isSubmitted: true,
          isSubmitting: false,
        });
      })
      .catch(() => {
        setFormState({
          ...formState,
          isSubmitting: false,
          isSubmitted: false,
          hasSubmissionFailure: true,
        });
      });
  };

  const InvalidInputMessage = () => {
    return <p className="help is-danger">Please accept all changes</p>;
  };
  return (
    <Layout>
      <div className={`columns is-centered ${styles.section}`}>
        <section className={'column is-10-tablet is-10-desktop is-centered'}>
          <>
            <label
              className={`title is-3 has-text-info`}
              htmlFor="changes-approval-text"
            >
              Changes Approval Consent
            </label>
            <textarea
              className={`textarea  ${styles.agreement}`}
              name="changes-approval-text"
              rows={10}
              readOnly
              value={agreementText}
            ></textarea>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className={`field`}>
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={accepted}
                      onChange={e => {
                        setFormState({
                          ...formState,
                          isInvalid: false,
                        });
                        setAccepted(!accepted);
                      }}
                    />
                    <span className={`${styles.checkboxLabel}`}>
                      Approve (ALL) Changes
                    </span>
                  </label>
                </div>
                {formState.isInvalid && <InvalidInputMessage />}
              </div>

              {formState.hasSubmissionFailure && (
                <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_SAVE} />
              )}

              <button
                className={`button is-primary ${styles.acceptButton} ${
                  formState.isSubmitting ? 'is-loading' : ''
                }`}
                type="submit"
                disabled={formState.isSubmitting}
              >
                <span className="icon ">
                  <FontAwesomeIcon icon={faEdit} />
                </span>
                <span>Sign Document</span>
              </button>

              <Modal
                title="Changes Approved!"
                message={
                  <>
                    Thank you for approving all changes.
                    <br />
                    <br />
                    You may close this tab or return to Trustaff.com.
                  </>
                }
                isOpen={formState.isSubmitted}
                confirmButtonText={'Back to Trustaff.com'}
                confirmButtonModifierClass={`is-info`}
                onConfirm={() => navigate('/')}
                onCancel={() => navigate('/')}
                messageModifierClass={`is-info`}
                isCentered
                showCancelButton={false}
                showCancelIconButton={false}
              />
            </form>
          </>
        </section>
      </div>
    </Layout>
  );
};

export default ChangesApprovalConsent;
