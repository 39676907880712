import apiService from './api-service';

export function useCountriesApi() {
  const getAllCountries = () => apiService.get('/api/countries'),
    mapToValueNamePairsForForms = countries =>
      countries.map(({ id, name }) => ({ value: id, name })),
    reorderCountries = (order, countries) => {
      const reordered = [...countries];

      order.forEach((c, i) => {
        const foundIdx = reordered.findIndex(o => o.name === c);
        const foundItem = reordered.find(o => o.name === c);
        reordered.splice(foundIdx, 1);
        if (foundItem) reordered.splice(i, 0, foundItem);
      });
      return reordered;
    },
    sortByNameAscending = countries =>
      countries.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );

  return {
    getAllCountriesAsValueNameObjects: () => {
      return new Promise((resolve, reject) => {
        getAllCountries()
          .then(({ data }) => {
            const countriesSortedByName = sortByNameAscending(data);
            const countriesFormattedForFormFields = mapToValueNamePairsForForms(
              countriesSortedByName
            );
            const reOrdered = reorderCountries(
              ['United States', 'Canada'],
              countriesFormattedForFormFields
            );
            resolve(reOrdered);
          })
          .catch(error => reject(error));
      });
    },
  };
}
