import React from 'react';
import Layout from '../../../../components/layout';
import PersonalInfo from '../../../common/components/personal-info';
import { useAuth } from '../../../../providers/auth-provider';
import ViewingSuggestionsBanner from '../viewing-suggestions-banner';

const PersonalInfoCandidateWrapper = ({ location }) => {
  const { getProfileId } = useAuth();
  const isViewingSuggestions =
    location.state !== null && location.state.isViewingSuggestions === true;

  return (
    <Layout>
      <ViewingSuggestionsBanner isViewingSuggestions={isViewingSuggestions} />
      <PersonalInfo
        profileId={getProfileId()}
        isViewingSuggestions={isViewingSuggestions}
      />
    </Layout>
  );
};

export default PersonalInfoCandidateWrapper;
