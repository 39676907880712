import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Router, Redirect, useLocation } from '@reach/router';
import AcceptanceAgreement from './components/acceptance-agreement';
import Agreements from './components/agreements';
import ChangesApprovalConsent from './components/changes-approval-consent';
import ChangeEmail from './components/change-email';
import ChangePassword from './components/change-password';
import Dashboard from './components/dashboard';
import Documents from './components/documents';
import Resources from './components/resources';
import Done from './components/done';
import ForgotPassword from './components/forgot-password';
import Education from './components/education';
import Expertise from './components/expertise';
import FinishUp from './components/finish-up';
import LicensesAndCerts from './components/licenses-and-certs';
import LoadingSpinner from '../../components/loading-spinner';
import Login from './components/login';
import PersonalInfo from './components/personal-info';
import References from './components/references';
import Register from './components/register';
import WorkHistory from './components/work-history';
import VerifyEmailChange from './components/verify-email-change';
import PayrollDeductionForm from './components/payroll-deduction-form';
import ProtectedRoute from '../../components/protected-route';
import { authRoles } from '../../constants/auth-roles';
import { useAuth } from '../../providers/auth-provider';
import { externalLinks } from '../../constants/external-links';

const ProfileApp = ({ data }) => {
  const { hasMetaLoaded } = useAuth();
  return hasMetaLoaded ? (
    <Router>
      <ProfileRoutes default data={data} />
    </Router>
  ) : (
    <LoadingSpinner isLoading={true} />
  );
};

const ProfileRoutes = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const {
    talentStackActiveAndAttemptingToViewLegacyLoginRoute,
    getLoginPathBasedOnTalentStackActiveFlag,
  } = useAuth();

  useEffect(() => {
    if (
      talentStackActiveAndAttemptingToViewLegacyLoginRoute(location.pathname)
    ) {
      setIsLoading(true);
      navigate(externalLinks.TALENT_STACK_HOME);
    } else {
      setIsLoading(false);
    }
  }, [location.pathname]);

  const loginPath = getLoginPathBasedOnTalentStackActiveFlag();

  return isLoading ? (
    <LoadingSpinner isLoading={isLoading} />
  ) : (
    <Router>
      <Expertise path="/profile/expertise" />
      <Register path="/profile/register" />
      <Login path={loginPath} />
      <ForgotPassword path="/profile/forgot-password" />
      <ChangePassword path="/profile/change-password" />
      <VerifyEmailChange path="/profile/verify-email-change" />

      <ProtectedRoute
        path="/profile"
        acceptedRoles={[authRoles.APPLICANT]}
        component={Dashboard}
      />
      <ProtectedRoute
        component={ChangeEmail}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/change-email"
      />
      <ProtectedRoute
        component={Education}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/education"
      />
      <ProtectedRoute
        component={WorkHistory}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/work-history"
      />
      <ProtectedRoute
        component={References}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/references"
      />
      <ProtectedRoute
        component={LicensesAndCerts}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/licenses-and-certs"
      />
      <ProtectedRoute
        component={FinishUp}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/finish-up"
      />
      <ProtectedRoute
        component={PersonalInfo}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/personal-info"
      />
      <ProtectedRoute
        component={Agreements}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/agreements"
      />
      <ProtectedRoute
        component={Documents}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/documents"
      />
      <ProtectedRoute
        component={AcceptanceAgreement}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/acceptance-agreement/:documentId"
      />
      <ProtectedRoute
        component={PayrollDeductionForm}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/payroll-deduction/:documentId"
      />
      <ProtectedRoute
        component={ChangesApprovalConsent}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/changes-approval-consent"
      />
      <ProtectedRoute
        component={Done}
        acceptedRoles={[authRoles.APPLICANT]}
        path="/profile/done"
      />
      <ProtectedRoute
        component={Resources}
        path="/profile/resources"
        acceptedRoles={[authRoles.APPLICANT]}
        data={data}
      />
      <Redirect from="/" to="/404" default noThrow />
    </Router>
  );
};

export default ProfileApp;
